
/* 
-----------------------------------
 CSS Index
-----------------------------------
1. Theme default css
2. header
3. slider
4. about
5. features
6. services
7. video
8. choose
9. brand
10. work-process
11. team
12. counter
13. cta
14. testimonial
15. blog
16. pagination
17. f-cta
18. pricing
19. contact
20. footer

*/
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,500;1,700&display=swap');

/* 1. Theme default css */
 body {
       font-family: 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: normal;
	color: #777;
	font-style: normal;
     line-height: 26px;
}
.text-left{
    text-align: left !important;
}
.mean-container a.meanmenu-reveal span {
    background: no-repeat;
    display: block;
    height: 3px;
    margin-top: 3px;
    border-top: 3px solid #fff;
}
 .mean-container .mean-nav ul li a {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    color: #333;
    display: block;
    float: left;
    margin: 0;
    padding: 10px 5%;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
    font-size: 15px;
    font-weight: 600;
}
.mean-container .mean-nav {
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 3px 4px 15px #0000001A;
        position: absolute;
}
.mean-container .mean-nav ul {
    overflow: hidden;
}

  .mean-container .mean-nav ul li a.mean-expand {
    text-align: right;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: 9px 12px;
}
  .mean-container .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, 0);
}

.srb-line {
width: 100%;
text-align: center;
position: absolute;
bottom: -90px;
}
.line5 {
    display: block;
}
.mr-15{
    margin-right: 15px;
}
.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
.button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	text-decoration: none;
}
.btn.focus, .btn:focus {
	outline: 0;
	box-shadow: none;
}
a,
button {
	color: #141b22;
	outline: medium none;
}
a:hover {
    color: #ff7350;
}
.box20 .title a{
    color: #fff;
}
.box20 .title a:hover{
     color: #444;
}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: none;box-shadow: none;}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Jost', sans-serif;
	color: #141b22;
	margin-top: 0px;
	font-style: normal;
	font-weight: 600;
	text-transform: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
h1 {
	font-size: 40px;
	font-weight: 600;
}
h2 {
	font-size: 35px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {

	
	margin-bottom: 15px;
}
hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
.theme-overlay {
	position: relative
}
.theme-overlay::before {
	background: #1696e7 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}
.separator {
	border-top: 1px solid #f2f2f2
}


/* button style */

.btn {
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 1;
	margin-bottom: 0;
	padding: 20px 30px;
	text-align: center;
	text-transform: unset;
	touch-action: manipulation;
	transition: all 0.3s ease 0s;
	vertical-align: middle;
	white-space: nowrap;
	position: relative;
    z-index:1;
    text-transform: capitalize;
   background: #ff7350;
    border: 1px solid #ff7350;
    overflow: hidden;
}

.btn i{
    margin-left: 10px;
    font-size: 18px;
}
.btn::before {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  content: "";
  background: #125875;
  top: -60PX;
  left: 10px;
  z-index: -1;    
    transition: all 0.3s ease 0s;
}
.btn:hover::before {
width: 100%;
height: 100%;
position: absolute;
border-radius: 4px;
content: "";
background: #125875;
top: 0;
left: 0;
z-index: -1;
}
.btn:hover{
    color: #fff;
    border-color: #125875;
}


.slider-btn:hover .btn-after{
    margin-top: -50px;
margin-left: 0px;
}
/* scrollUp */
#scrollUp {
	background: #ff7350;
	height: 45px;
	width: 45px;
	right: 50px;
	bottom: 77px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	border-radius: 50%;
	font-size: 22px;
	line-height: 45px;
	transition: .3s;
}
#scrollUp:hover {
	background: #ff7350;
}
/* 2. header */
.header{
    margin-bottom: -177px;
}
.header .second-header{
    background: none;
}
.header .second-header::before{
    display: none;
}
.header .second-menu{
    background: #fff;
    padding: 15px;
}
.header-top {
	padding-top: 40px;
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 30px;
}
.innder-ht {
	padding: 10px 0;
}
.wellcome-text p {
	margin-bottom: 0;
	color: #8a8a8a;
}
.header-cta ul li {
   display: inline-block;
padding-right: 15px;
margin-right: 15px;
border-right: 1px solid #e1e1e140;
}
.header-cta ul li:first-child{margin-left: 0;}
.header-cta ul li:last-child{padding-right: 0; border-right: none;margin-right: 0;}
.header-cta ul li i {
display: inline-block;
margin-right: 5px;
position: relative;
top: 1px;
color: #ff7350;
}
.header-top-cta ul li {
	display: inline-block;
	width: 200px;
	margin-right: 80px;
}
.header-top-cta ul li:last-child {margin-right: 0;}
.header-cta .call-box {
  display: flex;
  text-align: left;
}
.header-cta .call-box .icon img{
    width: 35px;
}
.h-cta-icon {
	float: left;
	display: block;
	margin-right: 20px;
}
.h-cta-content {
	overflow: hidden;
}
.h-cta-content h5 {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom: 0;
}
.h-cta-content p {
	margin-bottom: 0;
	line-height: 1.5;
}
.menu-area{
    position: relative;
}
.menu .sub-menu{
    display: none;
    position: absolute;
    background-color: #ffff;
    min-width: 250px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
    border-top: 4px solid #faa292;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.menu .sub-menu, .menu .children{
    display: none;
    position: absolute;
    background-color: #ffff;
    min-width: 250px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
    border-top: 4px solid #ff7350;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.main-menu {
  position: relative;
  z-index: 9;
    margin-right: -70px;
}
.menu .sub-menu li {
margin-left: 0 !important;
float: left;
border-bottom: 1px solid #ddd;
width: 100%;
text-align: left;
}
.main-menu .sub-menu li a {
    padding: 15px;
}
.menu .sub-menu li, .menu .children li  {
margin-left: 0 !important;
float: left;
border-bottom: 1px solid #ddd;
width: 100%;
text-align: left;
}

.menu .menu-item-has-children:hover > ul {
    display: block;
    margin-top: 0px;
}


.main-menu .menu-item-has-children > ul > .menu-item-has-children > ul {
    display: none;
    position: absolute;
    background-color: #ffff;
    width: 250px;
    z-index: 1;
    padding: 0;
    margin-left: -255px;
    margin-top: 0;
    top: -3px;
}
.menu .menu-item-has-children:hover > ul,.menu .page_item_has_children:hover > ul {
    display: block;
    margin-top: 0px;
}
.menu .page_item_has_children > ul > .page_item_has_children:hover > ul
{
	margin-left: -265px;
margin-top: 0;
top: -3px;
}
.main-menu .menu-item-has-children > ul >  .menu-item-has-children:hover > ul,.menu .page_item_has_children > ul >  .page_item_has_children:hover > ul{display: block;}
.main-menu .menu-item-has-children > ul >  .menu-item-has-children:hover > ul{display: block;}
.main-menu .menu-item-has-children > ul > .menu-item-has-children > ul > .menu-item-has-children > ul {
   display: none;
  position: absolute;
background-color: #ffff;
width: 250px;
z-index: 1;
padding: 0;
margin-left: 255px;
margin-top: 0;
top: -3px;
}

.main-menu ul li {
	display: inline-block;
	margin-left: 25px;
	position: relative;
}
.main-menu ul li.active a {
	color: #ff7350;
}
.main-menu ul li a {
	display: block;
	color: #141b22;
	padding: 20px 0;
    font-weight:600;
	position: relative;
    font-size: 16px;
    font-family: 'Jost', sans-serif;
}
.main-menu ul li a::after {
  content: "+";
  margin-left: 10px;
  color: #ff7350;
    display: none;
}
.main-menu ul li:last-child a::after,.main-menu .has-sub ul li a::after {
  display: none;
}
.main-menu ul li:first-child {
	margin-left: 0;
}
.menu-area .menu-tigger {
	cursor: pointer;
	display: inline-block;
}
.menu-area .menu-tigger span {
	height: 2px;
	width: 30px;
	background: #2935bb;
	display: block;
	margin: 7px 0;
	transition: .3s;
}

.main-menu ul li:hover > a{color: #ff7350;}
.main-menu ul li:hover > a::before{width: 100%;}
.display-ib{display: inline-block;}
.header-social a {
	font-size: 14px;
display: inline-block;
margin-left: 15px;
text-align: center;
color: #191D3B;
}

.search-top {
   display: inline-block;
    position: absolute;
    top: 16px;
    border: 2px solid #141b22;
    border-radius: 30px;
    left: 20%;
}
.search-top ul {
    background: #4CC3C1 0% 0% no-repeat padding-box;
    border-radius: 23px;
    margin: 5px;
    padding: 10px 5px 5px;
}
.search-top li {
    display: inline-block;
    padding: 0px 15px;
    border-right: 1px solid #ffffff38;
}
.search-top li a {
    color: #fff;
    font-size: 18px;
    float: left;
}
.search-top li:last-child {
    border: none;
}
.header-social a:hover{color: #fff;}
.sticky-menu {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
		z-index: 10 !important;
		background: #fff;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.responsive {display: none;}
#mobile-menu {
	display: block;
}

.right-menu li {
    float: right;
    padding: 0;
}
.right-menu .icon {
    float: left;
    margin-right: 20px;
}
.right-menu .icon img {
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.right-menu .text{
    float: left;
    padding-top: 5px;
}
.right-menu .text span {
    display: block;
    color: #517496;
}
.right-menu .text strong {
 color: #125875;
font-size: 25px;
font-weight: 900;
font-family: 'Jost', sans-serif;
}
.menu-search{
    padding: 10px 0;
}
.menu-search a {
font-size: 16px;
display: inline-block;
margin-top: 5px;
background: #ffffff4a;
text-align: center;
line-height: 33px;
border-radius: 50%;
color: #b8bfc3;
}
.top-btn {
    background: #ff7350;
    padding: 16px 30px;
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
}
.top-btn:hover {
    background: transparent linear-gradient(90deg, #ff73500%, #ff7350100%) 0% 0% no-repeat padding-box;
     color: #fff;
}
.sticky-menu .logo{
    margin-top: 0;
    line-height: 61px;
    height: auto;
}
.slider-content {
    position: relative;
    z-index: 2;
   
}
.slider-bg .banner-img {
  overflow: hidden;
  height: 738px;
  margin-top: 115px;
  margin-right: -62px;
}

.slider-content.s-slider-content.text2 {
    margin-top: 0;
}
.slider-content.s-slider-content.text3 {
    margin-top:168px;
}
.slider-price{
    position: absolute;
    right: 0;
    bottom: 0;
}
.slider-price h3{
	color:#fff;
}
.slider-price h2 {
    color:#ff7350;
    font-size: 60px;
    font-weight: 500;
}
.slider-btn{
    display: inline-block;
    position: relative;
}

.slider-active .slick-dots {
    position: absolute;
    top: 40%;
    left: 22%;
}
.slider-active .slick-dots li button {
    text-indent: -99999px;
    border: none;
    padding: 0;
    height: 1px;
    margin-left: 10px;
    background: #ff7350;
    border-radius: 50px;
    z-index: 1;
    cursor: pointer;
    transition: .3s;
    
}
 .slider-active .slick-dots li.slick-active button {
    width: 50px;
   background: #ff7350;
}
.slider-active .slick-dots li.slick-active button::before {
  
    opacity: 1;
}
.slider-active .slick-dots li button::before {
    content: "";
    width: 19px;
    height: 19px;
    float: left;
    position: relative;
    margin-top: -9px;
    left: -19px;
    border-radius: 50%;
    opacity: .7;
    background-repeat: no-repeat;
    background-position: center;
}
.second-header {
border: none;
padding-bottom: 15px;
background: #141b22;
padding-top: 15px;
color: #fff;
position: relative;
z-index: 1;
}
.second-header::before {
content: "";
height: 100%;
position: absolute;
width: 32%;
background: #ff7350;
top: 0;
z-index: -1;
left: -25px;
transform: skew(30deg);
}
.second-header a,.second-header span{
    color: #fff;
}
.second-header a:hover{
    color:#ffffffc2;
}
.second-menu {
	position: relative;
	z-index: 9;
}
.sticky-menu .second-menu {
	margin: 0;
	box-shadow: none;
	padding: 0;
}
.sticky-menu .second-menu::before{content: none;}
.second-menu .main-menu ul li{margin-left: 40px;}
.second-menu .main-menu ul li:first-child{margin-left: 0;}
.second-menu .main-menu ul li a::before{content: none;}
.second-header-btn .btn {
background: #ff7350;
font-size: 16px;
color: #fff;
padding: 25px 30px;
position: relative;
border-radius: 0;
}
.second-header-btn .btn:hover {
color: #fff;
background: #125875;
}

.img-main {
    position: relative;
z-index: 2;
}

.offcanvas-menu {
	position: fixed;
	right: 0;
	height: 100%;
	width: 300px;
	z-index: 999;
	background: #00081b;
	top: 0;
	padding: 30px;
	transition: .5s;
	transform: translateX(100%);
}
.offcanvas-menu.active{
	transform: translateX(0)
}
.menu-close i {
	font-size: 18px;
	color: #fff;
	transition: .3s;
	cursor: pointer;
}
.menu-close:hover i{
	color: #ff7350;
}
.offcanvas-menu ul {
	margin-top: 30px;
}
.offcanvas-menu ul li {
	border-bottom: 1px solid #101c38;
}
.offcanvas-menu ul li a {
	color: #fff;
	font-size: 18px;
	text-transform: capitalize;
	padding: 6px 0;
	display: block;
}
.offcanvas-menu ul li:hover a{
	color: #ff7350;
}
.side-social a {
	color: #fff;
	margin-right: 10px;
}
.side-social {
	margin-top: 30px;
}
.side-social a:hover{
	color: #ff7350;
}
.offcanvas-menu form {
	position: relative;
	margin-top: 30px;
}
.offcanvas-menu form input {
	width: 100%;
	background: none;
	border: 1px solid #2d3547;
	padding: 7px 10px;
	color: #fff;
}
.offcanvas-menu form button {
    position: absolute;
    border: none;
    right: 0;
    background: #ff7350;
    padding: 8px 14px;
	top: 0;
	cursor: pointer;
}
.offcanvas-menu form button i {
	color: #fff;
}
.offcanvas-overly {
	position: fixed;
	background: #000;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 11;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
}
.offcanvas-overly.active{
	opacity: .5;
	visibility: visible;
}
.off-logo {display: none;}

.header-two .menu-area{
  padding: 15px 0;
border-top: 1px solid #ffffff70;
border-bottom: 1px solid #ffffff70;
z-index: 2;
}
.header-two .menu-area.sticky-menu{
    background: #032e3f;
    border: none;
}
.header-two .top-callus {
    background: #ff7350;
    color: #fff;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 0 0px 10px 10px;
    width: 100%;
}

.header-two {
  margin-bottom: -178px;
}
.header-two .second-header{
    background: none;
}
.header-two .second-header::before{
    display: none;
}
.header-two .main-menu ul li a
{
    color: #fff;
}
.header-two .login li a {
  color: #fff;
}
.header-three{
    
}
.header-three .second-header {
  background: #125875;
}
.header-three .menu-area::before{
    display: none;
}
.header-three .menu-area {
border-top: 0;
    position: relative;
z-index: 9;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.header-three .sticky-menu {
  margin-top: 0;
    background: #fff;
}

.header-three .main-menu ul li a {
        font-family: 'Jost', sans-serif;
    color:#162542;
}
.header-three .main-menu .sub-menu li a,.menu .children li a {
    color: #4f4f4f !important;
}
.header-three .main-menu .sub-menu li a:hover,.menu .children li a:hover{
    color: #ff7350 !important;
}
.header-three .btn.ss-btn {
    float: right;
}
.login li {
    display: inline-block;
}
.login li a {
color: #000;
    text-transform: uppercase;
}
.login li i{
    margin-right: 25px;
}
.slider-three .slider-img {
margin-left: -25px;
margin-top: 50px;
margin-right: -227px;
}
.slider-bg-three::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #040f2d91;
    z-index: 1;
}
.slider-four .slider-bg h5 {
  border: none;
  color: #ffffff1c;
  font-size: 170px;
}
.slider-four .slider-img {
margin-left: 0;
margin-top: 136px;
margin-right: -235px;
float: right;
}
.slider-four .slider-img img{
    position: relative;
    z-index: 1;
}

.slider-four .slider-bg .text2 h5 {
    color: #ff7350;
    font-size: 18px;
           font-family: 'Jost', sans-serif;
    font-weight: 500;
}

.slider-four .slider-bg .video-i i{
    border: none;
    color: #ff7350;
    margin-right: 10px;
}
.slider-four .slider-bg .text2 p{
    color:#777;
}
.single-slider .subricbe{
 border-radius: 4px;
background-color: rgb(255, 255, 255);
box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.08);
position: relative;
padding: 15px;
margin-top: 40px;
width: 92%;
}
.header-input {
padding: 0 1rem;
width: 100%;
height: 4.4rem;
border-radius: 5px;
outline: none !important;
margin-bottom: 0;
border: 1px solid #f8f8f8;
background: #f8f8f8;
border-radius: 0;
}
.contact-form .btn::before{
    display: none;}

.header-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  bottom: 15px;
  border-radius: 4px;
 background: #ff7350;
    border: none;
  color: #fff;
    padding: 5px 15px;
}
.header-btn i{
    margin-left: 0;
}
.search-top2 {
    float: right;
}
.search-top2 li {
margin-left: 20px;
float: left;
border-radius: 10px;
background: #125875;
width: 45px;
height: 45px;
text-align: center;
line-height: 45px;
}
.search-top2 li a {
    float: none !important;
}
.search-top2 li .fas.fa-search {
    color: #36383a;
}

/* breadcrumb */
.breadcrumb-area {
background-size: cover;
position: relative;
    min-height: 600px;
    background-repeat: no-repeat;
    background-color: #ffffff;
}
.breadcrumb-wrap2 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: -25px;
    z-index: 1;
}
.breadcrumb-wrap.text-left {
  text-align: center !important;
}
.breadcrumb {
display: inline-block;
-ms-flex-wrap: wrap;
flex-wrap: unset;
padding: 0;
margin-bottom: 0;
list-style: none;
background-color: unset;
border-radius: 30px;
background: #ff7350;
padding: 15px 35px;
position: relative;
}
.breadcrumb li {display: inline-block;}
.breadcrumb li a {
font-size: 16px;
color: #fff;
font-weight: 500;
}
.breadcrumb-item + .breadcrumb-item::before {
	display: inline-block;
	padding-right: 15px;
	padding-left: 10px;
	color: #ddd;
	content: "|";
}
.breadcrumb-title h2 {
	font-size: 50px;
	margin-bottom: 25px;
	line-height: 1;
	color: #fff;
      letter-spacing: 1px;
}
.breadcrumb-title p {
	margin-bottom: 0;
color: #777;
font-size: 16px;
}
.breadcrumb > .active {
	color:#fffc !important;
	font-weight: 500;
}
.cart-top {
    display: inline-block;
}
.cart-top li {
    display: inline-block;
    padding: 0px 17px;
}
.cart-top li a {
    color: #fff;
    font-size: 18px;
    float: none !important;
}
.cart-top li:last-child {
    border: none;
}
.call-box .icon {   
    display: inline-block;
}
.call-box li {
   float: left;
    color: #fff;
}

.call-box .text{
margin-left: 10px;
}
.call-box span {
display: block;
font-size: 12px;
color: #fff;
margin-bottom: -5px;
}
.call-box strong {
}

.header-social a {
color: #fff;
margin-left: 15px;
font-size: 18px;
}
/* 3. slider */
.overlay-bg-01 {
  position: absolute;
  left: 0;
  top: 0;
}
.overlay-bg-02 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.overlay-bg-03 {
  position: absolute;
  right: 0;
  top: 30px;
}
.overlay-bg-04 {
  position: absolute;
  right: 0;
  bottom: 100px;
}
.slider-bg {
	min-height: 856px !important;
	background-position: center bottom;
	background-size: cover;
	position: relative;
	z-index: 1;
}
.slider-bg .image-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover !important;
    transform: scale(1);
    transition: transform 7000ms ease;
    z-index: 1;
}
.slider-bg.slick-active .image-layer{
     transform: scale(1.15);
}
.slider-bg .video-i.popup-video
{
    text-align: center;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
}
.slider-bg .video-i.popup-video img {
    display: inline-block;
}

.show-bg3::before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #000;
	opacity: .7;
	z-index: 1;
}
.slider-bg2 {
	min-height: 900px;
	background-position: center;
	background-size: cover;
	position: relative;
	margin-top: -118px;
	z-index: 1;
}
.slider-bg2 .slider-content > span::before {
    content: "";
    background:#383838;
    height: 3px;
    float: left;
    width: 60px;
    margin-top: 15px;
    margin-right: 10px;
}
.slider-bg2 .slider-content h2{
	color:#fff;
}
.slider-bg2 .slider-content p {
    color:#676f67;
    font-size: 16px;
    margin-bottom: 0;
}
.slider-bg2 .slider-content > span {
    font-size: 22px;
    font-weight: 500;
    color:#ff7350;
    display: block;
    margin-bottom: 20px;
    margin-top: 30px;
}
.slider-bg .video-i i {
background: #fff;
padding: 14px;
border-radius: 100%;
width: 60px;
display: inline-block;
text-align: center;
color: #ff7350;
height: 60px;
line-height: 30px;
box-shadow: 1px 1.732px 60px 0px rgba(250, 63, 108, 0.1);
    
}

.slider-bg2 .video-i {
    background:#383838;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
}
.slider-bg2 .video-i:hover {
    background:#ff7350;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
}
.slider-three .slider-bg{
  min-height: 959px !important;
}
.slider-three .slider-content h5::after{
    display: none;
}
#particles-js {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
	z-index: -1;
}
.slider-content > span {
	font-size: 16px;
	font-weight: 500;
	color: #ff7350;
	text-transform: uppercase;
	letter-spacing: 6px;
	display: block;
	margin-bottom: 20px;
}
.slider-content h2 {
    font-size: 70px;
    letter-spacing: 1.0px;
    line-height: 1.2;
    margin-bottom: 40px;
    color: #fff;
}
.slider-content h2 span {
  color: #fff;
  position: relative;
}
.slider-content h2 span::after {
  bottom: 13px;
  height: 2px;
  width: 100%;
  content: "";
  position: absolute;
  display: inline-block;
  background: #ff7350;
  left: 0;
}
.slider-content h5{
color: #fff;
font-size: 18px;
text-transform: uppercase;
line-height: 18px;
letter-spacing: 3px;
margin-bottom: 15px;
}
.slider-content h5::after {
  content: "";
  height: 2px;
  width: 55px;
  position: relative;
  display: inline-block;
  background: #ff7350;
  top: -5px;
  left: 15px;
}
.slider-content p span {
	display: inline-block;
	height: 2px;
	width: 40px;
	background: #ff7350;
	margin-right: 20px;
	position: relative;
	top: -4px;
}
.slider-content p {
	color: #fff;
	font-size: 18px;
	margin-bottom: 0;
}

.p-relative{position: relative;}
.down-arrow {
	position: absolute;
	bottom: 60px;
	left: 0;
	right: 0;
	color: #fff;
	z-index: 1;
	height: 60px;
	width: 30px;
	margin: auto;
	text-align: center;
	line-height: 60px;
	border: 2px solid #fff;
	border-radius: 50px;
	font-size: 18px;
}
.down-arrow:hover{color: #fff;}
.slider-active .slick-arrow {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 5%;
    color: #00163b;
    font-size: 26px;
    line-height: 60px;
    border: none;
    text-align: center;
    z-index: 9;
    cursor: pointer;
    padding: 0;
    background: none;
    transition: .5s;
    width: 60px;
    height: 60px;
    background: #fff;
    box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}


.slider-active .slick-next {
	right: 5%;
left: inherit;
}
.slider-active .slick-arrow,.slider-active .slick-next{
    transition: all 0.3s ease 0s;
    opacity: 0;
}
.slider-active:hover .slick-prev
{
    left: 4%;
}
.slider-active:hover .slick-next
{
    right: 4%;
}
.slider-active:hover .slick-arrow,.slider-active:hover .slick-next{
   opacity: 1;
}
.slider-active .slick-arrow:hover {
	color: #fff;
    background: #ff7350;
}
.second-slider-content h2 {
	font-size: 90px;
	margin-bottom: 20px;
}
.second-slider-bg::before{opacity: .5;}
.second-slider-content {
	padding-top: 85px;
	padding-bottom: 5px;
}
.s-slider-content h2{margin-bottom: 25px;}
.s-slider-content p {
font-size: 18px;
color: #fff;
line-height: 26px;
margin: auto;
padding-right: 60px;
}
.slider-four .s-slider-content p {
font-size: 18px;
color: #fff;
line-height: 26px;
padding-bottom: 30px;
width: 90%;
margin: auto;
}
.btn-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    background: #ffb3a5;
    padding: 10px 15px;
    color: #fff;
}
.btn.ss-btn.active {background: none;
color: #fff; border: 1px solid #fff; box-shadow: none;}
.line {
    float: left;
    margin-right: 15px;
}
.slider-shape{position: absolute !important;z-index: 9;}
.ss-one {
top: 220px !important;
left: 200px !important;
}
.ss-two {
top: 290px !important;
left: 49% !important;
}
.ss-three {
top: 77% !important;
left: 7% !important;
}
.ss-four {
top: 56% !important;
left: 37% !important;
}
.ss-five {
	left: 61% !important;
	top: 83% !important;
}
.ss-six {
	left: 88% !important;
	top: 80% !important;
}
.ss-seven {
	top: 20% !important;
	left: 89% !important;
}

.slider-bg .text2 h2{
  font-size: 60px;
}
.slider-bg .text2 a{
    color: #FFF;
}
.slider-bg .text2 .btn.ss-btn.active {
    background: #ff7350;
    border: 2px solid #ff7350;
    color: #FFF;
}
.slider-bg .text2 p{
    padding-right: 22%;
    color:#fff;
}
.slider-text-2 h2{
    color: #fff;
}

.s-aliment-1 {
    position: absolute;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 15px;
    box-shadow: 0px 30px 60px 0px rgb(0,0,0,0.2);
    top: 361px;
    right: -122px;
    display: flex;
    align-items: center;
    z-index: 1;
    animation: movedelement 5s linear infinite;
    width: 250px;
}
.aliment-icon-red {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aliment-content {
  margin-left: 15px;
    padding-top: 15px;
}
.aliment-content p{
    margin-bottom: 0;
}
.aliment-content .h3-title {
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

@keyframes movedelement {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(10px, 10px);
        transform: translate(10px, 10px);
    }
    50% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px);
    }
    75% {
        -webkit-transform: translate(10px, -5px);
        transform: translate(10px, -5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
.slider-content2 h2{
    color: #125875;
}
.slider-content2 p{
    color: #777777;
}
.slider-content2 h5{
     color: #777777;
}

/* 4. about */
.about-area .btn{
    background:#125875;
border: 1px solid #125875;
}
.about-area .btn:hover{
border: 1px solid #ff7350;
}
.about-area .btn:hover::before{
    background: #ff7350;
}
.s-about-img img {

}
.about-img {
	margin-left: -50px;
}
.about-user {
display: flex;
margin: 30px 0;
border-bottom: 1px solid #d7d7d7;
padding-bottom: 15px;
}
.about-user .img{
  margin-right: 30px;
}
.about-user .text h5{
    color: #ff7350;
}
.about-text {
  position: absolute;
  bottom: 0px;
  right: 30px;
  width: 200px;
  background: #ff7350;
  padding: 30px;
   border-radius: 10px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.about-text::after{
position: absolute;
content: "";
height: 6px;
width: 100%;
bottom: 0;
left: 0;
display: inline-block;
background: #125875;
}

.about-text span {
font-size: 50px;
color: #fff;
position: relative;
display: inline-block;
font-weight: 600;
font-family: 'Jost', sans-serif;
margin-top: 0;
}
.about-text span sub {
font-size: 30px;
color: #fff;
font-weight: 600;
margin-left: -10px;
bottom: 5px;
}
.about-title > span {
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff;
	background: #ff7350;
	height: 30px;
	display: inline-block;
	text-align: center;
	line-height: 30px;
	margin-bottom: 30px;
}
.about-title h2 {
	font-size: 50px;
    margin-bottom: 0 !important;
}
.about-title h5{
   color: #ff7350;
    font-size: 18px;
    margin-bottom: 10px;
}
.about-title .title-strong{
    color: #4f4f4f;
    font-weight: 500;
    font-size: 18px;
}
.about-title .title-strong span {
    padding: 3px 12px;
    background: #ff7350;
    border-radius: 5px;
    color: #fff;
}
.about-title p span {
	display: inline-block;
	height: 2px;
	width: 40px;
	background: #ff7350;
	margin-right: 20px;
	position: relative;
	top: -5px;
}
.about-title p {
	margin-bottom: 0;
}
.about-content p {
	margin-bottom: 45px;
}
.about-content li {
    display: flex;
    margin-bottom: 10px;
    color: #125875;
}
.about-content li .text {
    padding-top: 0;
}
.about-title h2 span {
    color: #ff7350;
}
.about-content li .icon i {
    width: 40px;
height: 40px;
background: #ebebeb;
text-align: center;
line-height: 40px;
margin-right: 20px;
color: #ff7350;
}

.product-qulity li h3{
    font-size: 26px;
    font-weight: 600;
}
.product-qulity li .icon {
  width: 140px;
  margin-top: 12px;
}
.about-content .exprince{
      border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 30px 0;
}
.about-content .exprince h5 {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 500;
    color: #0595b8;
}
.about-content .exprince p{
    margin-bottom: 0;
}
.about-content .nav-tabs {
    border: 1px solid #141b22;
    width: 54%;
    padding: 6px;
    display: inherit;
    border-radius: 10px;
    margin-bottom: 30px;
}
.about-content .nav-tabs .nav-item {
    margin-bottom: 0;
    display: inline-block;
    margin-top: 0;
}
.about-content .nav-tabs .nav-item.show .nav-link, .about-content .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #141b22;
    border: none;
    border-radius: 10px;
    padding: 12px 32px;
    color: #fff;
    text-transform: uppercase;
}
.about-content .nav-tabs .nav-link {
   padding: 12px 32px;
     text-transform: uppercase;
    border: none;
}
.ab-ul {
    margin-top: 30px;
}
.ab-ul li{
float: left;
width: 50%;
}
.ab-ul li .icon i {
    width: 40px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #EEEEEE;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 20px;
    color: #ff7350;
}
.sr-ul {
    margin-top: 30px;
}
.sr-ul li {
    float: left;
    width: 46.2%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
    margin: 0 20px 30px 0;
    padding: 15px 20px;
}
.sr-ul li .icon i {
     width: inherit;
    height:inherit;
    background: inherit;
    border: inherit;
    border-radius: inherit;
     line-height: inherit;
   font-size: 30px;
    color: #4cc3c1;
}
.sr-ul li .text{
  font-size: 14px;
  
  color: #125875;
}

.sr-tw-ul {
    position: relative;
    z-index: 1;
}
.sr-tw-ul li {
    float: left;
    width: 100%;
    border: 1px solid;
    padding: 30px;
    border-width: 1px;
    border-color: rgb(215, 215, 215);
    border-style: solid;
    border-radius: 4px;
    margin-bottom: 15px;
}
.sr-tw-ul li p{
    margin-bottom: 0;
}

.sr-tw-ul li .icon {
    width: 108px;

}
.sr-tw-ul li .icon img,.sr-tw-ul li .icon-right img {
  width: 54px;
}
.sr-tw-ul li .icon-right{
   width: 108px;
}

.sd-img img {
  width: 100%;
}
.section-t h2 {
	font-size: 350px;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	z-index: -1;
	color: #141b22;
	opacity: .05;
	margin: 0;
	font-family: 'Playfair Display', serif;
	font-weight: 500;
	font-style: italic;
	top: -35px;
	line-height: 1;
}
.second-about {
right: 0;
bottom: inherit;
text-align: center;
top: 0;
width: 307px;
}
.about-text.second-about.three-about {
  right: 42%;
  top: 68%;
}
.second-about p {
  margin-bottom: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Jost', sans-serif;
}
.second-atitle > span {
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: #ff7350;
	letter-spacing: 2px;
	display: inline-block;
	margin-bottom: 20px;
	background: transparent;
}
.s-about-content p {
	margin-bottom: 15px;
}
.s-about-content .txt-clr{
    color: #245b73;
    font-size: 18px;
    line-height: 28px;
}
.about-content strong{
    color: #ff7350;
}
.sinature-box {
    float: left;
    width: 100%;
    box-shadow: 0px 16px 32px #0000000A;
    padding: 25px 30px 15px 30px;
    margin-top: 50px;
}
.sinature-box h2{
    font-size: 20px;
    font-weight: 500;
           font-family: 'Jost', sans-serif;
    color: #141b22;
    margin-bottom: 5px;
}
.sinature-box h3{
    font-size: 14px;
           font-family: 'Jost', sans-serif;
    color: #4CC3C1;
}
.sinature-box .user-box{
    display: flex;
}
.sinature-box .user-box .text{
    padding-top: 10px;
    padding-left: 15px;
}
.signature-text{font-size: 22px; color: #ff7350;font-family: 'Playfair Display', serif;font-weight: 600;}
.circle-right{
    display: inline-block;
}
.ab-coutner li {
    display: inline-block;
    margin-right: 22px;
}
.ab-coutner .single-counter {
    background-color: rgb(250, 162, 146);
    box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
     border-radius: 10px;
    padding: 70px 0;
}
.ab-coutner .single-counter p{
margin-top: 15px;
color: #4f4f4f;
font-family: 'Playfair Display', serif;
font-size: 20px;
font-weight: 600;
}
.clinet-abimg {
    position: absolute;
    bottom: -76px;
    right: 0;
    animation: alltuchtopdown 3s infinite;
-webkit-animation: alltuchtopdown 3s infinite;
animation-delay: 0s;
-webkit-animation-delay: 1s;
}

.about-content2 .about-title h2{
    color: #fff;
}
.about-content2 li {
display: inherit;
margin-top: 10px;
width: 50%;
float: left;
margin-bottom: 0;
color: #777;
}
.about-content2 .abcontent{
    display: flex;
}
.about-content2 li .ano{
 font-size: 20px;
width: 90px;
height: 56px;
background: #ff7350;
text-align: center;
margin-right: 15px;
color: #fff;
display: block;
border-radius: 50%;
font-weight: 500;
line-height: 56px;
font-family: 'Jost', sans-serif;
}
.about-content2 li .text h3{
    font-size: 12px;
}
.about-content2 li .text {
  padding-top: 5px;
}
.experience-text {
  float: left;
  padding: 15px 0;
  border-top: 1px solid #ffffff4f;
  border-bottom: 1px solid #ffffff4f;
  width: 100%;
    display: flex;
}
.experience-text span {
font-size: 60px;
color: #ff7350;
position: relative;
font-weight: 800;
font-family: 'Jost', sans-serif;
margin-top: 15px;
float: left;
margin-right: 15px;
}
.experience-text span sub {
  font-size: 30px;
  color: #ff7350;
  font-weight: 800;
  margin-left: -10px;
  bottom: 5px;
}
.exp-no {
width: 66%;
 border-right: 1px solid #ffffff4f;
}
.exp-no p {
  float: left;
  color: #fff;
  width: 48%;
}
.exp-text {
  padding-left: 30px;
     color: #ffffffab;
}
.achivments-outer {
  display: flex;
}
.achivments-outer .text{
    padding-left: 15px;
}
.achivments-outer .text h5 {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
}
.achivments-outer .text p{
    color: #ff7350;
}
/* 5. features */
.features-services-area .container {
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    padding: 50px 50px 20px;
    margin-top: -180px;
}
.features-services-area .features-icon img{
    height: 57px;
    margin-bottom: 30px;
}
.features-services-area p{
    margin-bottom: 0;
}
.features-content h4 {
	font-size: 24px;
	margin-bottom: 17px;
}

.s-btn {
	font-size: 14px;
	font-weight: 500;
	color: #141b22;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 9px 30px;
	border-radius: 50px;
}
.s-btn:hover {
	background: #ff7350;
	border-color: #ff7350;
	color: #fff;
}
.section-title h5{
  color: #ff7350;
font-size: 18px;
    margin-bottom: 10px;
}
.section-title h5 div {
    display: inline-block;
    margin: 0 10px;
}
.section-title h2 {
	font-size: 50px;
	padding-bottom: 0;
	margin-bottom: 0px !important;
	position: relative;
}
.section-title h2::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 4px;
	width: 60px;
	background: #ff7350;
	margin: auto;
	transition: .3s;
    display: none;
}

section:hover .section-title h2::before{width: 100px;}
.features-p {
	padding-top: 380px;
}

.features-shape{position: absolute;z-index: -1;}
.fshape-one {
	left: 120px;
	top: 25%;
}
.fshape-two {
	left: 15%;
	top: 51%;
}
.fshape-three {
	top: 69%;
	left: 6%;
}
.fshape-four {
	top: 40%;
	left: 89%;
}
.fshape-five {
	top: 71%;
	left: 83%;
}
.about-content3 li::before {
    font-family: "Font Awesome 5 Pro";
    content: "";
    margin-right: 10px;
    color: #ff7350;
}

/* 6. services */
.feature-area {

    margin-top: -80px;
    z-index: 1;
    position: relative;

}
.services-box{
     border-radius: 8px;
  background-color: #fff;
    padding: 40px 50px;
}
.services-box h3
{
    font-size: 24px;
}
.services-box .icon{
    margin-bottom: 30px;
}
.services-box .icon img{
    height: 52px;
}
.services-box a{
    color: #141b22;
    font-family: 'Jost', sans-serif;
}
.services-box a:hover{
    color: #ff7350;
}
.service-details-three{
    margin-top: -150px;
}
.service-details-three .services-box {

  padding: 30px;
}
.service-details-three .sbox div:nth-child(1) .services-box{
      background: #fff1ee;
}
.service-details-three .sbox div:nth-child(2) .services-box{
      background: #eef8ff;
}
.service-details-three .sbox div:nth-child(3) .services-box{
      background: #fffaee;
}
.service-details-three .sbox div:nth-child(4) .services-box{
      background: #eefffb;
}
.services-two .s-single-services {
    border-radius:0 0 0 0;
}
.services-two .s-single-services .btn2{
    display: inline-block;
    color: #ff7350;
}
.services-area.gray-bg{position: relative;z-index: 1;overflow: hidden;}
.single-services {
	background: #fff;
	box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.07);
	position: relative;
}
.single-services::before {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	left: 5px;
	top: 5px;
	background: #ff7350;
	z-index: -1;
	transition: .3s;
	opacity: 0;
}
.service-t h2 {
	top: 55px;
}
.services-thumb img {
	width: 100%;
}
.services-content span {
	display: block;
	height: 2px;
	width: 150px;
	background: #ff7350;
	transition: .3s;
	margin-bottom: 55px;
}
.services-content {
	padding: 60px 40px;
	padding-bottom: 55px;
}
.services-content small {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: block;
	margin-bottom: 20px;
}
.services-content h4 {
	font-size: 24px;
	margin-bottom: 23px;
}
.services-content h4:hover a{color: #ff7350;}
.services-content p{margin-bottom: 0;}
.single-services:hover .services-content span {background: #ff7350;}
.single-services:hover::before{opacity: 1;}
.services-active .slick-dots {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -40px;
	line-height: 1;
}
.services-active .slick-dots li{display: inline-block;margin: 0 5px;}
.services-active .slick-dots li button {
	text-indent: -99999px;
	border: none;
	padding: 0;
	height: 5px;
	width: 20px;
	background: #ff7350;
	border-radius: 50px;
	z-index: 1;
	cursor: pointer;
	transition: .3s;
}
.services-active .slick-dots li.slick-active button {
	width: 50px;
	background: #ff7350;
}
.services-active .slick-track {
	padding-bottom: 10px;
}

.services-icon i {
    font-size: 48px;
border-radius: 50%;
color: #ff4328;
text-align: center;
width: 100px;
height: 100px;
line-height: 100px;
border: 1px solid #ff4328;
}
.services-two .services-icon {
display: inline-block;
width: 90px;
height: 90px;
background: #FFFFFF 0% 0% no-repeat padding-box;
text-align: center;
border-radius: 50%;
padding: 25px;
    margin-bottom: 30px;
}
.services-two .services-icon img{
    width: 100%;
}
.services-two .brline{
    margin-top: 15px;
    margin-bottom: 25px;
}
.services-two ul li{
    margin-bottom: 25px;
}
.services-two ul li::before {
   font-family: "Font Awesome 5 Pro";
    content: "ï€Œ";
   margin-right: 10px;
color: #ff7350;
}
.services-icon .glyph-icon::before {
    font-size: 48px;
    color: #3763eb;
    margin-left: 0;
}
.services-icon2 {
    position: absolute;
    right: 5%;
    top: 0;
}
.services-icon2 .glyph-icon::before {
    font-size: 120px;
    color: #8ca8ff29;
    margin-left: 0;
}
.services-two .second-services-content{
    float: left;
width: 100%;
}
.services-two .s-single-services {
transition: .3s;
padding: 45px;
border: none;
margin-bottom: 30px;
border-radius: 0;
  
}
.services-two .s-single-services{
    float: left;
	width: 100%;
}


.services-three .services-icon {
display: inline-block;
width: auto !important;
height: auto !important;
margin-bottom: 30px;
}
.services-three .services-icon img{
    width: 100%;
}
.services-three .brline{
    margin-top: 15px;
    margin-bottom: 25px;
}
.services-three ul li{
    margin-bottom: 25px;
}
.services-three ul li::before {
   font-family: "Font Awesome 5 Pro";
    content: "ï€Œ";
   margin-right: 10px;
color: #ff7350;
}
.services-three .second-services-content{
    float: left;
width: 100%;
}
.services-three .s-single-services {
transition: .3s;
padding: 30px;
margin-bottom: 30px;
border-radius: 4px;
border: 2px solid #F0F0F0;
display: block;
box-shadow: none;
    
}
.services-three .s-single-services.active {
    background: #fff;
    margin-top: 0;
    position: relative;
    z-index: 1;
    left: 0;
}
.services-three .s-single-services.active .services-icon {
    background: #fff 0% 0% no-repeat padding-box;
    width: 90px;
    height: 90px;
}
.services-three .s-single-services.active a.ss-btn {
    display: none;
}

.second-services-content h5 {
  transition: .3s;
  letter-spacing: 0;
  font-size: 26px;
  color: #030c26;
}
.second-services-content p {
	margin-bottom: 0;
}
.second-services-content a {
	/*font-size: 14px;
	font-weight: 500;
	color: #444d69;
	position: relative;
	transition: .5s;*/
}

.second-services-content a::after {
	left: -40px;
	right: unset;
	opacity: 0;
}
.s-single-services {
	transition: .3s;
    background: #fff;
    position: relative;
    display: flex;
}

.s-single-services .services-icon {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
    z-index: 1;
}
.h-service {
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #141b229c;
  padding-top: 45%;
    transition: .3s cubic-bezier(.24,.74,.58,1);
    transform: scaleY(1);
}
.h-service h5 {
color: #fff;
    font-size: 26px;
    font-weight: 600;
}
.s-single-services:hover .h-service{
transform: scaleY(0);
opacity:0;
}
.services-hover .box1 {
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid #ff7350;
  position: absolute;
  z-index: 11;
  left: 40px;
  top: 40px;
  border-right: 0;
  border-bottom: 0;
}
.services-hover .box2 {
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid #ff7350;
  position: absolute;
  z-index: 11;
  right:40px;
  top:40px;
  border-left: 0;
  border-bottom: 0;
}

.services-hover {
position: absolute;
top: 0;
left: 0;
padding: 100px 60px 0;
background: #141b22;
height: 100%;
width: 100%;
color: #fff;
transform: scaleY(0);
opacity: 0;
transition: .3s cubic-bezier(.24,.74,.58,1);
}
.s-single-services:hover .services-hover {
transform: scaleY(1);
opacity:1;
}
.services-hover h5{
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
}
.services-hover a{
    color: #fff;
    text-decoration: underline;
    text-transform: uppercase;
    margin-top: 15px;
    display: block;
}
.services-hover:hover p{
   height: auto;
    width: auto;
    
}
.services-hover:hover .services-icon{ 
   margin-top: 20px;
}
.second-services-content a {
    color: 000f38;
    transition: all 0.3s ease 0s;
}

.s-single-services a.ss-btn{
    display: none;
}
.s-single-services .active-icon{display: none;}
.s-single-services .d-active-icon{display: block;}

.s-single-services:hover {background: #fff;}

.s-single-services.active a.ss-btn{
    display: inline-block;
}
.s-single-services.active .second-services-content h5{}
.s-single-services.active .second-services-content a,#feature-top .s-single-services.active .btn2,.s-single-services.active .services-icon .glyph-icon::before{}
.s-single-services.active .second-services-content p{
   
}
.s-single-services.active .active-icon{display: block;}
.s-single-services.active .d-active-icon{display: none;}
.services-bg {
	background-position: center;
	background-size: cover;
}
.services-active2{
    padding-top: 50px;
    padding-bottom: 300px;
}
.services-active2 .slick-arrow {
   position: absolute;
    top: 50%;
    left: -60px;
    border: none;
    background: none;
    padding: 0;
    font-size: 24px;
    color: #ff7350;
    z-index: 9;
    cursor: pointer;
    transition: .3s;
}
.services-active2 .slick-next {
    left: inherit;
    right: -60px;
}
.services-active2 .slider-nav .slick-list.draggable {
    width: 1100px;
    float: right;
}
.services-item {
background: #fff;
margin: 22px 15px;
box-shadow: 3px 4px 15px #0000001A;
text-align: center;
border-radius: 10px;
}
.services-active2 .slider-nav {
    position: absolute;
    bottom: 0px;
}
.services-active2 li
{
    display: flex;
    margin-bottom: 10px;
}
.services-item h3{
    font-size: 20px;
}
.services-item .glyph-icon:before{
    font-size: 60px;
}
.services-active2 li .icon{
    padding-right: 10px;
}
.services-item.slick-slide.slick-current.slick-active {
    background: #3763eb;
}
.services-item.slick-slide.slick-current.slick-active .glyph-icon:before{
    color: #fff;
}
.services-item.slick-slide.slick-current.slick-active h3{
    color: #fff;
}
.services-area .services-box {
background: #eff7ff;
padding: 50px;
}
.services-area .services-box:hover,.services-area .services-box.active {
background: #fff7ef;
}
.services-box .services-content2{
    transition: .3s;
}
.services-box .services-content2 p{
    margin-bottom: 0;
}

.services-content2 h5{
    font-size: 24px;
    margin-bottom: 15px !important;
}
.services-content2 h5 a{
    color: #30313d;
}
.services-box .ss-btn2{
    width: 60px;
    height: 60px;
    background: #ff73500% 0% no-repeat padding-box;
    box-shadow: 0px 16px 32px #ff735033;
    display: none;
    color: #fff;
    line-height: 60px;
    font-size: 31px;
    border-radius: 50%;
    position: absolute;
    left: 42%;
    bottom: -25px;
    transition: .3s;
}
.services-box .services-content2:hover .ss-btn2{
     display: inline-block;
}

.services-02 ul{
    display: flex;
    border: 2px solid #F5F5F5;
}
.services-02 ul li{
    padding: 30px;
     border-right: 2px solid #F5F5F5;
    background: #fff;
    transition: .2s;
}
.services-02 ul li:last-child{
    border: none;
}
.services-icon-02
{
    margin-bottom: 30px;

}
.services-content-02 h5{
    font-size: 24px;
    margin-bottom: 20px;
}
.services-content-02{
        font-size: 14px;
}
.services-content-02 .ss-btn2 {
    margin-top: 20px;
    display: block;
}
.services-content-02 .ss-btn2 i{
    width: 40px;
height: 40px;
    border: 2px solid #E6E6E6;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    color:#777;
}
.services-content-02 .ss-btn2 span{
   color: #ff7350; 
    margin-left: -10px;
    font-weight: 600;
    opacity: 0;
   transition: all 0.5s ease 0s;
}
.services-02 ul li:hover{
   border: none;
box-shadow: 0px 16px 32px #0000000A;
transform: scale(1.08);
    transition: .2s;
}
.services-02 ul li:hover .ss-btn2 span{
    margin-left: 5px;
    opacity: 1;
    transition: .2s;
}
.services-box-03 {
    background: #fff;
    padding: 50px;
}
.services-box-04{
     border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 38px 0px rgba(40, 40, 40, 0.04);
    padding: 45px;
}

.services-icon-04{
    display: flex;
    margin-bottom: 30px;
}
.services-icon-04 img{
    margin-right: 15px;
}
.services-icon-04 h4{
    padding: 15px 0 0;
}
.services-box-04 .services-content-02 li{
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    color: #125875;
}
.services-box-04 .services-content-02 li::before {
    font-family: "Font Awesome 5 Pro";
    content: "ï€Œ";
    margin-right: 10px;
    color: #ff7350;
}
.services-box-04 .services-content-02 .blue li::before{
    color: #4cc3c1;
} 
.services-box-04 .services-content-02 .green li::before{
    color: #2db065;
} 
.services-05 h2{
    color: #fff;
}
.services-05::before{
    content: "";
    background: #125875;
    position: absolute;
    top: 0;
    width: 100%;
    height: 529px;
}
.services-content-05{
     background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 38px 0px rgba(40, 40, 40, 0.04);
padding:30px;
    margin-bottom: 50px;
}
.services-content-05 span{
    color: #ff7350;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}
.services-text05 p{
    font-size: 20px;
}
.services-text05 a{
    font-size: 20px;
color: #ff7350;
font-weight: 500;
text-decoration: underline;
}

.services-07 .section-title h2{
    color: #fff;
}
.services-box07{
background-color: rgba(18, 88, 117, 0.85);
    padding: 35px;
    transition: all 0.3s ease 0s;
}

.services-box07:hover,.services-box07.active{
    background: #ff7350;
}
.services-box07 .sr-contner {
    display: flex;
}
.services-box07 .sr-contner .icon {
  width: 138px;
  margin-right: 20px;
}
.services-box07 .sr-contner .text h5{
    font-size:25px;
    color: #fff;
    
}
.services-box07 .sr-contner .text p{
    margin-bottom:10px;
    color: #fff;
}
.services-box07 .sr-contner .text a{
    font-family: 'Jost', sans-serif;
    color: #fff;
}

.services-07 .user-box {
    display: flex;
}
.services-07 .user-box .text {
    padding-top: 10px;
    padding-left: 15px;
}

.services-07 .user-box .text h2 {
    font-size: 24px;
    font-weight: 500;
           font-family: 'Jost', sans-serif;
    color: #fff;
    margin-bottom: 5px;
}
.services-07 .user-box .text h3 {
    font-size: 14px;
           font-family: 'Jost', sans-serif;
    color: #9a9fa2;
}

.services-08-item {
    text-align: center;
    padding:50px;
     border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}

.services-08-item .readmore {
  width: 50px;
  height: 50px;
  background: #d7d7d7;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  color: #162542;
  display: inline-block;
  margin-top: 15px;
}
.services-08-item .readmore:hover{
    color: #fff;
    background: #ff7350;
}
.services-08 h2{
    color: #fff;
}
.services-08 .bg-outer{
     background-color: #fff;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(32, 85, 125, 0.06);
    box-shadow: 0px 30px 40px 0px rgba(32, 85, 125, 0.06);    
}
.services-08 .col-lg-4.col-md-4{
    position: relative;
}
.services-08 .services-08-item--wrapper{
     border-bottom: 2px solid #f5f5f5;
}

.services-08 .services-08-item--wrapper:nth-child(2){
    border-bottom: none;
}
.services-08-item--wrapper > div{
    border-right: 2px solid #f5f5f5;
}
.services-08-item--wrapper > div:nth-child(3){
    border-right: none;
}
.services-08-content h3{
    margin-bottom: 15px;
    font-size: 24px;
}
.services-08-content,.services-08-thumb {
    position: relative;
    z-index: 1;
}
.services-08-thumb img{
    height: 40px;
}
.services-08-content .number {
  color: #f5f5f5;
  -webkit-text-stroke: 1px #ff7350;
  -webkit-text-fill-color: #fff;
  font-size: 70px;
  font-weight: bold;
    margin-bottom: 35px;    
}
.services-08-item:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: 1;
}
.services-08-item:hover::before {
    background-color: #fff;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -10px;
    left: -10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(32, 85, 125, 0.06);
    box-shadow: 0px 30px 40px 0px rgba(32, 85, 125, 0.06);
}
.services-09::before {
display: none;
}
.services-09 p,.services-09 .section-title h5{
    color: #fff;
}

.services-area2 .container {
    z-index: 1;
    position: relative;
}
.services-area2 h2{
    color: #30313d;
}
.services-area2 .services-box2 {
    background-color: rgb(255, 255, 255);
    box-shadow:2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.07) ;
}
.services-area2 .services-box2 h3 {
    padding-left: 30px;
    border-left: 5px solid #ff7350;
}
.services-area2 .services-box2 .services-icon{
    border-radius: 15px;
    background-color: #fff7e7;
    width: 80px;
    height: 80px;
    line-height: 80px;
    display: inline-block;
    text-align: center;
}
.services-area2 .services-box2 .services-icon i{
    border: none;
    color: #ff7350;
}
.services-area2 .services-box2 p{
    margin-bottom: 0;
}
.services-box2 .services-content2 {
    padding: 30px 0 30px 0;
    position: relative;
}
.services-box2 .services-content2 h3 {
    font-weight: 600;
    font-size: 24px;
}
.services-box2 .services-content2 .icon{
color: #777777;
width: 50px;
height: 50px;
border: 2px solid #7777777d;
text-align: center;
font-size: 30px;
line-height: 48px;
display: inline-block;
border-radius: 50%;
position: absolute;
top: 28%;
right: 30px;
}
.services-box2 .services-content2 .icon:hover{
color: #ff7350;
border: 2px solid #ff7350;
}
.animations-01 {
    position: absolute;
    left: 0;
    bottom: 30px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-02 {
    position: absolute;
    right: 0;
    bottom:-10px;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}
.animations-03 {
    position: absolute;
    left: -50px;
    top: 240px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-04 {
   position: absolute;
    left: 235px;
    bottom: 195px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}

.animations-100 {
    top:100px;
     bottom: 195px;
      animation: alltuchtopdown 3s infinite;
       -webkit-animation: alltuchtopdown 3s infinite;
       animation-delay: 0s;
       -webkit-animation-delay: 1s;
 }

.animations-05 {
   position: absolute;
    animation: alltuchtopdown 5s infinite;
    width: 100%;
    text-align: center;
    top: 24px;
    z-index: -1;
}

.animations-06 {
    position: absolute;
    left: 50px;
    top: 240px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-07 {
   position: absolute;
    left: 0px;
    bottom: -50px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-08 {
    position: absolute;
    right: 50px;
    top:250px;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}
.animations-09 {
  position: absolute;
    right: 50px;
    bottom:150px;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}
.animations-10 {
position: absolute;
left: 0;
bottom: 110px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-11 {
   position: absolute;
    left: 160px;
    bottom: 165px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-12 {
    position: absolute;
    right: 130px;
    top: 340px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-13 {
   position: absolute;
    right: 150px;
    bottom: 200px;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.animations-14 {
    position: absolute;
    right: 80px;
   top: 150px;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}
.animations-15 {
  position: absolute;
    right: 120px;
    bottom:0;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}
@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }

.sbox div:nth-child(1) .services-box07 {
  background: #ffeec4;
}
.sbox div:nth-child(2) .services-box07 {
  background:#e5f3ff;
}
.sbox div:nth-child(3) .services-box07 {
  background: #e1ffe5;
}
.sbox div:nth-child(4) .services-box07 {
  background: #fff4f3;
}
.service-details-two {
  margin-top: 100px;
}
/* steps area */
.steps-area .section-title  p{
    color: #96bece;
    font-size: 18px;
    margin-top: 15px;
}
.steps-area h2,.steps-area h3{
color: #fff;}
.steps-area h3{
    font-size: 30px;
}
.steps-area li {
  margin-bottom: 30px;

}
.steps-area li:last-child{
    margin-bottom: 0;
}
.step-box {
  display: flex;
}
.step-box .text p{
    margin-bottom: 0;
    color: #96bece;
}
.step-box .date-box {
width: 75px;
  height: 75px;
  border-radius: 50%;
background-color: #ff7350;
margin-right: 20px;
text-align: center;
position: relative;
float: left;
color: #fff;
font-family: 'Jost', sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 70px;
}


.step-img {
margin-right: -302px;
background: #fff;
padding-bottom: 30px;
}
.step-img img{
    margin-top: -30px;
}
.step-box.step-box2 {
  padding-right: 50px;
    display: inherit;
}
.step-box.step-box2 .dnumber {
  display: block;
}
.step-box.step-box2 .text {
  display: inline-block;
  margin-top: 30px;
    font-size: 18px;
}
.step-box.step-box2  h2{
    font-size: 50px;
    margin-bottom: 15px;
    color: #fff;
}
.step-img2 {
  margin-right: -350px;
    margin-left: -15px;
}
.step-img2 img{
    width: 996px;
}

.step-box.step-box3 {
 padding-left: 50px;
    display: inherit;
}
.step-box.step-box3 .dnumber {
  display: block;
}
.step-box.step-box3 .text {
  display: inline-block;
  margin-top: 30px;
    font-size: 18px;
}
.step-box.step-box3  h2{
    font-size: 50px;
    margin-bottom: 15px;
    color: #fff;
}
.step-img3 {
  margin-left: -350px;
    margin-right: -15px;
}
.step-img3 img{
    width: 996px;
}
/* 7. video */

.video-position {
	position: absolute;
	bottom: -170px;
	left: 50px;
}
.video-img {
	position: relative;
	overflow: hidden;
	z-index: 1;   
    height: 588px;
}
.video-img img{
    width: 100%;
}
.video-img::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: .65;
}
.video-img a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  font-size: 30px;
  color: #fff;
  height: 80px;
  width: 80px;
  text-align: center;
  background: #ff7350;
  border-radius: 50%;
  padding: 25px;
}
.video-img a::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	height: 90px;
	width: 90px;
	background: transparent linear-gradient(90deg, #ff73500%, #ff7350100%) 0% 0% no-repeat padding-box;
	z-index: -1;
	border-radius: 50%;
	transform: translate(-50%,-50%);
	animation: pulse-border 1500ms ease-out infinite;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	opacity: 1;
	}
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
	opacity: 0;
	}
}
.left-align h2::before{margin: unset;}
.video-content p {
	margin-bottom: 30px;
}
.v-list ul li {
	margin-bottom: 20px;
}
.v-list ul li:last-child {
	margin-bottom: 0px;
}
.v-list ul li i {
	color: #ff7350;
	display: inline-block;
	margin-right: 5px;
}
.v-padding {
	padding-top: 35px;
}
.s-video-wrap2 {
  height: 500px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.s-video-wrap {
background-position: center;
background-size: cover;
height: 660px;
display: flex;
align-items: center;
background-repeat: no-repeat;
border-radius: 10px;
}
.s-video-content {
width: 100%;
text-align: center;
}
.s-video-content a {
z-index: 9;
font-size: 14px;
text-align: center;
border-radius: 50%;
display: inline-block;
margin-bottom: 15px;
position: relative;
}
.s-video-content a::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 120px;
    width: 120px;
    background: rgba(255, 255, 255, 0.09);
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    animation: pulse-border 1500ms ease-out infinite;
}
.s-video-content h2 {
font-size: 50px;
color: #fff;
margin-bottom:10px;
}
.s-video-content p {
	font-size: 18px;
	color: #fff;
	margin-bottom: 30px;
    font-family: 'Jost', sans-serif;
}
/* 8. choose */
.chosse-img {
	background-size: cover;
	height: 100%;
	right: 0;
	position: absolute;
	top: 0;
	width: 50%;
	background-repeat: no-repeat;
	background-position: center;
}
.choose-list{
	float:left;
	width: 100%;
}
.choose-list ul li {
	margin-bottom: 20px;
	color:#444d69;
	width: 50%;
	float: left;
}
.choose-list ul li i {
	color: #ff7350;
	display: inline-block;
	margin-right: 5px;
	font-size:18px;
}
.choose-area {
	background-size: cover;
	background-position: center;
}
.choose-content p {
	margin-bottom: 15px;
	color: #444d69;
	padding-right: 50px;
}
.choose-btn a {
   border-radius: 8px;
  background-color: rgb(31, 83, 219);
  box-shadow: 0px 2px 60px 0px rgba(31, 83, 219, 0.3);
    width: 241px;
    display: inline-block;
    color: #fff !important;
    display: flex;
    padding: 10px 25px;
    float: left;
    margin-right: 25px;
    position: relative;
    border: 2px solid #1f53db;
    font-family: 'Jost', sans-serif;
}
.choose-btn a .icon {
    padding: 0 20px 0 0;
    line-height: 50px;
    z-index: 99;
}
.choose-btn a .text {
    z-index: 99;
    font-size: 20px;
}
.choose-btn a .text strong {
    font-size: 22px;
    margin-top: 0px;
    display: block;
    font-weight: 500;
}
.choose-btn a.g-btn {
    box-shadow: none;
    width: 262px;
    background: none;
   border: 2px solid #1f53db;
    margin-right: 0;
}
.g-btn .text {
    color: #141b22;
}
.g-btn .text strong {
    color: #1F53DB;
}
/* 9. brand */
.single-brand {text-align: center;}
.single-brand img {display: inline-block;}
.brand-area2{
    margin-top: -70px;
    position: relative;
}


/* 10. work-process */
.wp-bg {
	background-position: center;
	background-size: cover;
}

.w-title h2::before{background-color: #ff7350;}
.wp-list {
	background: #232c8e;
	box-shadow: 0px 10px 30px 0px rgba(29, 38, 129, 0.48);
	padding: 80px 50px;
	padding-bottom: 75px;
}
.wp-list ul li {
	display: flex;
	align-items: start;
	overflow: hidden;
	margin-bottom: 45px;
}
.wp-list ul li:last-child {
	margin-bottom: 0px;
}
.wp-icon {
	margin-right: 25px;
}
.wp-content h5 {
	color: #fff;
	font-size: 24px;
	margin-bottom: 15px;
}
.wp-content p{color: #fff;margin-bottom: 0;}
.wp-tag {
	position: absolute;
	left: 70px;
	top: 90px;
	animation: alltuchtopdown 3s infinite;
    -webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 1s;
}
@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
	transform: rotateX(0deg) translateY(0px);
	}
  50% {
    -webkit-transform: rotateX(0deg) translateY(-10px);
    -moz-transform: rotateX(0deg) translateY(-10px);
    -ms-transform: rotateX(0deg) translateY(-10px);
    -o-transform: rotateX(0deg) translateY(-10px);
	transform: rotateX(0deg) translateY(-10px);
	}
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
	transform: rotateX(0deg) translateY(0px);
	}
}
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
	transform: rotateX(0deg) translateY(0px);
	}
  50% {
    -webkit-transform: rotateX(0deg) translateY(-10px);
    -moz-transform: rotateX(0deg) translateY(-10px);
    -ms-transform: rotateX(0deg) translateY(-10px);
    -o-transform: rotateX(0deg) translateY(-10px);
	transform: rotateX(0deg) translateY(-10px);
	}
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
	transform: rotateX(0deg) translateY(0px);
	}
}
.wp-img {
	margin-right: -60px;
	margin-top: -60px;
	margin-bottom: -15px;
}
.wp-thumb img {
	width: 100%;
}
.inner-wp-icon {
	float: left;
	display: block;
	margin-right: 30px;
}
.inner-wp-c {
	overflow: hidden;
	display: block;
}
.inner-wp-c h5 {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 15px;
}
.inner-wp-c p{margin-bottom: 0;}
/* 11. team */
.team-area .single-team {
  margin-left: 0;
  margin-right: 0;
}
.single-team {
  border-radius: 2px;
  background-color: #fff;
  padding: 30px;
}

.team-area .container{
    z-index: 1;
    position: relative;
}
.team-active .slick-dots{
    text-align: center;
    display: none;
}
.team-active .slick-dots li {
    display: inline-block;
    margin: 0 5px;
}
.team-active .slick-dots .slick-active button {
  width: 10px;
  background-color: #ff7350;
}
.team-active .slick-dots li button {
text-indent: -99999px;
padding: 0;
margin-left: 10px;
z-index: 1;
cursor: pointer;
transition: .3s;
background-color: #cccccc;
height: 10px;
width: 10px;
border-radius: 2px;
border: none;
    
}
 .team-active .slick-dots li.slick-active button {
}
.team-active .slick-dots li.slick-active button::before {
    opacity: 1;
}
.team-active .slick-dots li button::before {
    content: "";
    width: 19px;
    height: 19px;
    float: left;
    position: relative;
    margin-top: -9px;
    left: -19px;
    border-radius: 50%;
    opacity: .7;
    background-repeat: no-repeat;
    background-position: center;
}

.team-thumb{
    text-align: center;
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.team-thumb img{
display: inline-block !important;
width: 150px;
height: 150px;
border-radius: 50%;
}
.team-thumb .dropdown {
  position: absolute;
bottom: 0;
right: 15px;
}
.team-thumb .dropdown .xbtn {
width: 50px;
height: 50px;
background: #ff7350 0% 0% no-repeat padding-box;
display: inline-block;
font-size: 18px;
line-height: 48px;
font-family: 'Titillium Web', sans-serif;
color: #fff;
cursor: pointer;
border-radius: 50%;
position: relative;
}
.team-thumb .dropdown .xbtn::before {
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  border: 2px dashed #ffffff5c;
  border-radius: 50%;
  left: 5px;
  top: 5px;
}
.team-social {
text-align: center;
transition: all 0.3s ease 0s;
width: 100%;
margin-top: 10px;

}
.team-social li{
    display: inline;
    margin:0 3px;
}
.team-thumb .dropdown-menu {
    background: none;
    border: none;
    padding: 0;
    transform:translate3d(0px, -107px, 0px) !important;
}

.team-thumb .social {
    position: absolute;
    top: 0;
}

.team-info {
padding: 30px 0px 0;
transition: .3s;
position: relative;
text-align: center;
}

.team-info .text {
    padding-left: 30px;
    border-left: 5px solid #ff7350;
}

.team-info h4 {
	font-size: 24px;
	margin-bottom: 5px;
	transition: .3s;
}
.team-info span {
	font-size: 16px;
	transition: .3s;
    color: #ff7350;
    font-weight: 600;
}
.team-info p{
    margin-bottom: 0;
    color: #ff7350;
}
.team-social a {
color: #777;
display: inline-block;
text-align: center;
border-radius: 50%;
font-size: 16px;
position: relative;
z-index: 999;
margin: 5px 0;
width: 40px;
height: 40px;
background: #fff;
line-height: 40px;
border: 1px solid #ebebeb;
}
.team-social a:hover{
    background: #ff7350;
    color: #fff;
    border: 1px solid #ff7350;
}
.single-team:hover .team-thumb .brd::before,.single-team:hover .team-thumb .brd::after{
    color: #ff7350;
}

.single-team:hover .team-info{
   border-color: #ff7350;
}
.team-t h2 {
	top: 50px;
}
.single-team:hover .team-info h4{
    color: #ff7350;
}
.slick-slide {
    outline: none;
}

.team-active{
    position: relative;
}
.team-active .slick-arrow {
position: absolute;
top: 40%;
left: -60px;
border: none;
background: none;
padding: 0;
font-size: 20px;
color: #fff;
z-index: 9;
cursor: pointer;
transition: .3s;
background: #ff7350;
width: 50px;
height: 50px;
border-radius:4px;
}
.team-active .slick-arrow:hover{
    background: #125875;
}
.team-active .slick-next {
    left: inherit;
    right: -60px;
}
.team-area-content{
    padding: 120px 0;
}

.team-info h4 a:hover{
      color: #ff7350;
}
.team-area-content span {
    margin-bottom: 10px;
    color: #ff7350;
    font-size: 18px;
    display: inline-block;
    font-weight: 500;
    position: relative;
}
.team-area-content span::before {
    content: "";
    width: 50px;
    height: 1px;
    background: #ff7350;
    position: relative;
    display: inline-block;
    top: -5px;
    margin-right: 15px;
}
.team-area-content ul{
    margin-top: 30px;
    margin-bottom: 50px;
}
.team-area-content li{
    display: flex;
    margin-top: 15px;
}
.team-area-content li .icon i{
    margin-right: 10px;
    color: #ff7350;
    width: 20px;
}
.team-area-content li .icon strong{
     color: #125875;
}
.team-area-content li .icon{
    margin-right: 10px;
    width: 35%;
}
.team-area-content .social a {
font-size: 12px;
color: #fff;
margin: 0 5px;
display: inline-block;
width: 40px;
height: 40px;
text-align: center;
border-radius: 50%;
line-height: 40px;
box-shadow: 3px 4px 15px #00000026;
}
.team-area-content .social a:nth-child(1) {
    background: #345aa8;
}
.team-area-content .social a:nth-child(2) {
    background: #00ACED;
}
.team-area-content .social a:nth-child(3) {
    background: #0057FF;
}
.team-area-content .social a:nth-child(4) {
    background: #CD201F;
}
.per-info {
  background: #f5f5f5;
  padding: 30px;
  display: flex;
    border-radius: 4px;
    margin-bottom: 30px;
    margin-top: 30px;
}
.per-info .info-text {
  width: 100%;
}
.per-info .info-text p{
    margin-bottom: 0;
    font-size: 16px;
}
.per-info .info-text p i{
    color: #ff7350;
    margin-right: 10px;
}
.team-img-box img {
  width: 100%;
    border-style: solid;
  border-width: 7px;
  border-color: rgb(255, 255, 255);
     box-shadow: 5px 8.66px 58px 0px rgba(0, 0, 0, 0.08);
}

.s-about-content h2{
    font-size: 50px;
    margin-bottom: 20px;
}
.s-about-content h3{
    font-size: 30px;
    margin-bottom: 20px;
}
.s-about-content .footer-social a{
    background: #125875;
}
.per-info ul {
  margin-bottom: 0;
}

.brand-anim-01{
  position: absolute;
  left: -40px;
  top: -60px;
}
.brand-anim-02{
position: absolute;
right: -36px;
top: 40px;
}

.team-area2 .single-team {
 padding: 0;
background: #f7f9ff;
}

.team-area2 .team-thumb img{
    height: auto;
    width: auto;
    border-radius: 0;
}
.team-area2 .team-info{
    padding-bottom: 30px;
}
.conterdown {
  background: #125875;
    padding: 50px 0;
    border-radius: 4px;
    margin: 30px 0;
}
.conterdown .timer {
  display: flex;
}
.conterdown .timer-outer {
  width: 100%;
  text-align: center;
}
.conterdown  .smalltext {
  color: #75b4ce;
}
.conterdown span{
  font-size: 50px;
  font-family: 'Jost', sans-serif;
  color: #fff;
font-weight: 600;
}
/* 12. project */
.project h2{

}
.single-project .project-info {
position: absolute;
bottom: 40px;
left: 40px;
color: #fff;
}
.single-project .project-info h4{
    margin-bottom: 0;
}
.single-project .project-info h4 a
{
    color: #fff;
    font-size: 24px;
}
.single-project .project-info p{
    color: #ff7350;
    margin-bottom: 0;
}
.single-project .project-info a{
    color: #ff7350;
}
.single-project .project-info a img{
    display: inline;
    margin-left: 5px;
}
.portfolio-active {
padding-left: 40px;
}
.portfolio-area2 h2{
    color: #fff;
}
.portfolio-active .single-project {
    position: relative;
}

.portfolio-active .slick-arrow {
    position: absolute;
    top: -124px;
    right: 19%;
    border: none;
    background: none;
    padding: 0;
    color: #fff;
    z-index: 9;
    cursor: pointer;
    transition: .3s;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    color: #ff7350;
    box-shadow: 1px 1.732px 30px 0px rgba(255, 94, 21, 0.25);
    font-size: 30px;
}

.portfolio-active .slick-arrow:hover {
    background: #ff7350;
    color: #fff;
}
.portfolio-active .slick-next {
right: 15%;
left: inherit;
}
.portfolio-box {
    width: 100%;
    height: 390px;
    box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.portfolio-box img {
    height: 390px !important;
    max-width: 600px !important;
    margin-left: -150px;
}
.portfolio-box .caption {
    position: absolute;
    bottom: -150px;
    padding: 30px;
    width: 100%;
    background: #fff;
    transition: opacity .4s ease, visibility .2s ease;
    opacity: 0;
}
.portfolio-box:hover .caption
{
    bottom: 0;
    opacity: 1;
}
.portfolio-box .caption .caption-text{
    position: relative;
}
.portfolio-box .caption .arrow-icon {
    position: absolute;
    right: 40px;
    top: 25%;
    font-size: 28px;
    color: #ff7350;
}
.caption-text h5{
    margin-bottom: 5px !important;
}
.caption-text p{
    margin-bottom: 0 !important;
}
.project-two h2{
    color: #fff;
}
.project-two .grid-item,.project-two .col-xl-6{
    padding: 0;
    margin: 0;
}
/* 12. counter */

.counter-tittle {
  display: inline-block;
  background: #ff7350;
  padding: 11px 30px;
  position: relative;
  top: -60px;
}
.counter-tittle h2{
    color: #fff;
    font-size: 25px;
}
.counter-tittle::before {
   content: "";
background: #ff7350;
width: 17%;
height: 62px;
position: absolute;
top: -2px;
transform: skew(20deg);
left: -13px;
}
.counter-tittle::after {
   content: "";
background: #ff7350;
width: 17%;
height: 62px;
position: absolute;
top: -2px;
transform: skew(-20deg);
right: -13px;
}
.counter-area {
  margin-top: -290px;
  padding-top: 370px;
}
.counter-area .single-counter{
padding-bottom: 30px;
padding-left: 0;
padding-right: 0;
}
.counter-area .row div:nth-child(4) .single-counter {
  display: ;
  border-right: none;
}
.single-counter {
  padding: 0 25px;
}
.single-counter i {
	display: inline-block;
	width: 80px;
	height: 80px;
	background:#fe576b;
	border-radius: 50%;
	margin-bottom: 30px;
	color:#000;
	font-size: 40px;
	line-height: 80px;
}
.single-counter .icon{
    margin-bottom: 15px;
    float: left;
    width: 100px;
}
.count {
font-size: 50px;
color: #fff;
font-weight: 600;
margin-bottom: 5px;
line-height: 1;
font-family: 'Jost', sans-serif;
display: inline-block;
}
.counter {
  display: flex;
    padding-left: 40px;
}
.counter small {
	font-size: 35px;
	font-weight: 600;
	color: #002c47;
	line-height: 1;
	position: relative;
}
.single-counter p {
font-size: 16px;
margin-bottom: 0;
color: #fff;
padding-top: 5px;
padding-left: 30px;
line-height: 22px;
}
.single-counter .line {
    display: inline-block;
    width: 1px;
    height: 50px;
    background: #fe576b;
    margin-bottom: -5px;
    margin-top: 10px;
}
/* event */

.event-item .thumb{
    overflow: hidden;
}
.event-content{
    padding: 30px;
    background: #f0f7ff;
    position: relative;
    transition: all 0.5s ease 0s;
}
.event-item:hover .event-content{
    background: #125875;
    color: #96bece;
}
.event-item:hover .event-content .time{
    color: #bbeafe;
}
.event-item:hover .event-content .time strong,.event-item:hover .event-content h3 a{
    color: #fff;
}
.event02 .event-content h3{
    font-size: 24px;
}
.event-content .date {
  position: absolute;
  background-color: #ff7350;
  width: 87px;
  height: 67px;
  left: 30px;
  top: -60px;
  color: #fff;
    text-align: center;
    font-size: 12px;
    font-family: 'Jost', sans-serif;
}
.event-content .date strong{
    font-size: 24px;
    display: block;
    margin-top: 10px;
}
.event-content h3{
    font-size: 24px;
}
.event-content .time{
     font-family: 'Jost', sans-serif;
}
.event-content .time strong{
    font-weight: 500;
    color: #ff7350;
}
.event-content .time i{
    margin-left: 5px;
    margin-right: 5px;
}
.event02 .event-item{
    width: 100%;
    float: left;
}
.event02 .thumb {
  width: 219px;
  height: 270px;
  float: left;
}
.event02 .event-content {
  float: left;
  width: 59%;
}
.event02 .event-content .date{
    position: relative;
    left: inherit;
    top: inherit;
    display: inline-block;
    margin-bottom: 20px;
}
.event02 .thumb {
width: 219px;
height: 277px;
}
.event02 .thumb img{
  width: 219px;
height: 277px;
}
.event02 .event-content .date{
    border-radius: 4px;
    margin-bottom: 20px;
}
.event02 .event-content .icon {
  position: absolute;
  right: 30px;
  top: 0;
  font-size: 90px;
  color: #dfeeff;
}
.event03 .event-content,.event03 .event-item{
    float: left;
}
.event03 .event-content{
    background: #fff;
    border: 1px solid #ff7350;
    padding: 30px;
}
.event03 .event-content h3{
    font-size: 24px;
}
.event03 .event-content .date{
position: relative;
top: 0;
left: 0;
float: left;
border-radius: 4px;
height: 80px;
padding-top: 6px;
}
.event03 .event-content .icon {
  position: absolute;
  right: 30px;
  top: 0;
  font-size: 90px;
  color: #f7f9ff;

}
.event03 .event-content .text {
  float: left;
  width: 81%;
  margin-left: 20px;
}
 .event03 .event-item:hover .icon {
     color: #356c84;
}
.event03 .event-content .time i{
    color: #ff7350;
}
.event03 .event-content .time strong {
  font-weight: 500;
  color: #777;
}
/* courses */
.class-scroll .schedule li {
  float: left;
  padding: 0 15px;
    padding-left: 15px;
  border-right: 1px solid #ccc;
}
.class-scroll .class-item .class-content p{
    margin-bottom: 0;
}
.class-scroll .schedule span {
  display: block;
  line-height: 22px;
  color: #12265a;
  margin-bottom: 5px;
    font-weight: 600;
}
.class-scroll .schedule span.class-age,.class-scroll .schedule span.class-size {
  color: #ff7350;
    font-weight: 400;
}
.class-scroll .slick-track .class-item:nth-child(1) ul,.class-scroll .slick-track .class-item:nth-child(4) ul,.class-scroll .slick-track .class-item:nth-child(7) ul,.class-scroll .slick-track .class-item:nth-child(10) ul,.class-scroll .slick-track .class-item:nth-child(13) ul {
  background: #fff1ee;
}
.class-scroll .slick-track .class-item:nth-child(2) ul,.class-scroll .slick-track .class-item:nth-child(5) ul,.class-scroll .slick-track .class-item:nth-child(8) ul,.class-scroll .slick-track .class-item:nth-child(11) ul,.class-scroll .slick-track .class-item:nth-child(14) ul  {
  background:#eef8ff;
}
.class-scroll .slick-track .class-item:nth-child(3) ul,.class-scroll .slick-track .class-item:nth-child(6) ul,.class-scroll .slick-track .class-item:nth-child(9) ul,.class-scroll .slick-track .class-item:nth-child(12) ul,.class-scroll .slick-track .class-item:nth-child(15) ul  {
  background:#fffaee;
}


.class-scroll .col-lg-4:nth-child(1) ul,.class-scroll .col-lg-4:nth-child(4) ul,.class-scroll .col-lg-4:nth-child(7) ul,.class-scroll .col-lg-4:nth-child(10) ul,.class-scroll .col-lg-4:nth-child(13) ul {
  background: #fff1ee;
}
.class-scroll .col-lg-4:nth-child(2) ul,.class-scroll .col-lg-4:nth-child(5) ul,.class-scroll .col-lg-4:nth-child(8) ul,.class-scroll .col-lg-4:nth-child(11) ul,.class-scroll .col-lg-4:nth-child(14) ul  {
  background:#eef8ff;
}
.class-scroll .col-lg-4:nth-child(3) ul,.class-scroll .col-lg-4:nth-child(6) ul,.class-scroll .col-lg-4:nth-child(9) ul,.class-scroll .col-lg-4:nth-child(12) ul,.class-scroll .col-lg-4:nth-child(15) ul  {
  background:#fffaee;
}

.class-scroll .schedule li:last-child {
  padding-right: 0;
  border-right: none;
}
.class-scroll .schedule {
display: inline-block;
width: 100%;
padding: 15px 15px;
background: #fff1ee;
margin-bottom: -8px;
}
.class-scroll .class-item{
    margin: 0 15px 15px;
    box-shadow: 1px 1.732px 20px 0px rgba(0, 0, 0, 0.08);
}
.class-active{
    margin-right: -350px;
}
.courses-item {
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
   background: #f0f7ff;
    border-radius: 4px;
    transition: all 0.3s ease 0s;
}
.courses-item:hover{
   box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1); 
}
.courses-item:hover .courses-content{
    background: #fff;
}
.courses-img-outer {
  overflow: hidden;
    border-radius: 4px 4px 0 0;
}
.courses-content {
    padding: 30px;    
    float: left;
    position: relative;
    
}
.courses-content .cat {
  border-radius: 4px;
  background-color: #ff7350;
  position: absolute;
  top: -18px;
  right: 30px;
  color: #fff;
  padding: 5px 10px;
}
.courses-content .cat i{
    margin-right: 5px;
}

.courses-item .icon img{
    display: inherit;
}
.courses-item .icon {
  float: right;
  text-align: right;
  margin-top: -60px;
  margin-right: 30px;
}
.courses-content h4{
    font-size: 26px;
    margin-bottom: 15px;
}
.courses-content .schedule span {
  display: block;
  line-height: 22px;
  color: #12265a;
}
.courses-content a{
    color: #141b22;
    font-family: 'Jost', sans-serif;
}

.courses-content a.readmore{
     color: #ff7350;
}
.courses-content a.readmore:hover{
     color: #141b22;
}
.courses-content a:hover{
    color: #ff7350;
}
.course-widget-price ul {
  width: 100%;
  display: inline-block;
}
.course-widget-price li i {
  padding-right: 9px;
  font-size: 14px;
  padding-left: 2px;
  color: #ff7350;
}
.course-widget-price li span:last-child {
  float: right;
}

.shop-area .courses-item{
    margin-left: 0;
    margin-right: 0;
}
.class-area2 h2{
    color: #fff;
}
.class-active .single-project {
    position: relative;
}

.class-active .slick-arrow {
position: absolute;
top: -122px;
left: 71.6%;
border: none;
background: none;
padding: 0;
font-size: 36px;
color: #fff;
z-index: 9;
cursor: pointer;
transition: .3s;
background: #ff7350;
width: 60px;
height: 60px;
border-radius: 4px;
}
.class-active .slick-arrow:hover {
    background: #125875;
    color: #fff;
}
.class-active .slick-next {
left: 76%;
right: 0;
}

.class-item {
    display: inline-block;
     background-color: rgb(255, 255, 255);
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
}
.class-img-outer {
  overflow: hidden;
    position: relative;
}
.course-meta {
  position: absolute;
  bottom: 0;
  background: #00000082;
  width: 100%;
  padding: 15px;
  color: #fff;
}
.course-meta .author {
  display: flex;
  align-items: center;
  gap: 13px;
}
.course-meta .author .thumb img{
    height: 60px;
}
.course-meta .author .text{
    line-height: 22px;
}
.course-meta .author .text p{
    margin-bottom: 0;
}
.course-meta .author a{
    color: #fff;
    font-size: 16px;
    font-family: 'Jost', sans-serif;
}
.course-meta2 {
  background: #125875;
  border-radius: 8px;
    padding: 15px 30px;
}
.course-meta2 p{
    margin-bottom: 0 !important;
}
.course-meta2 .author {
  display: flex;
  align-items: center;
  gap: 13px;
    color: #75b4ce;
}
.course-meta2 .author a{
    color: #fff;
    font-family: 'Jost', sans-serif;
}
.course-meta2 .left {
    float: left;
}
.course-meta2 .left li{
   float: left;
    margin-right: 30px;
}
.course-meta2 .right{
    float: right;
}
.course-meta2 .price {
  padding: 5px 30px;
  color: #fff;
  background: #ff7350;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  margin-top: 7px;
}
.course-meta2 .author .thumb img {
  height: 50px;
}
.shop-area .class-item {
  margin-left: 0;
  margin-right: 0;
    margin-bottom: 30px;
}
.class-item .class-content {
  padding: 30px;
}
.class-item .class-content h4{
    font-size: 24px;
    margin-bottom: 15px;
}

.class-content .schedule li:first-child {
  padding-left: 0;
}
.class-content .schedule li:last-child {
  padding-right: 0;
  border-right: none;
}
.class-content .schedule li {
  float: left;
  padding: 0 15px;
  border-right: 1px solid #ccc;
}
.class-content .schedule span {
  display: block;
  color: #12265a;
    font-weight: 600;
}
.class-content .schedule {
  display: inline-block;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  width: 100%;
}
.class-content .schedule span.class-size,.class-content .schedule span.class-age {
  color: #ff7350;
}
/* 13. cta */
.cta-bg {
	position: relative;
	background-size: cover;
	background-position: center;
	z-index: 1;
}
.cta-title.video-title h2{
    font-size: 68px;
}
.cta-title h2 {
  padding-bottom: 0;
  font-size: 50px;
color: #fff;
}
.cta-title h2 span{
    color: #fe7350;
    text-decoration: underline;
}
.cta-title h3{padding-bottom: 0; font-size:18px; color: #125875; text-transform: uppercase;}
.cta-title p{font-size: 18px;
color: #fff; margin-top: 15px;}
.cta-title h2::before{content: none;}
.cta-content p {
	color: #fff;
	padding-right: 80px;
	margin-bottom: 35px;
}
.cta-btn .btn{
    border: 1px solid #ff7350;
}
.cta-btn .btn:hover{
        border: 1px solid #125875;
}
.cta-btn .btn::before{background: #125875;}


.cta-btn2 .btn{
    border: 1px solid #125875;
    background: #125875;
}
.cta-btn2 .btn:hover{
        border: 1px solid #4ea9b4;
}
.cta-btn2 .btn::before{background: #4ea9b4;}
.cta-right p {
	margin-bottom: 0;
	color: #fff;
	font-size: 24px;
	font-weight: 500;
}
.call-tag {
	margin-bottom: 20px;
	animation: alltuchtopdown 3s infinite;
    -webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 1s;
}
.cta-bg .video-img {
    height: 757px;
}
.cta-bg .social{
    text-align: center;
}
.cta-bg .social .icon {
   display: inline-block;
    width: 110px;
    height: 110px;
    background: #ff7350;
    padding: 30px;
    border-radius: 50%;
    line-height: 47px;
    margin-bottom: 30px;
    box-shadow: 3px 4px 15px #00000066;
}
.cta-bg .line{
    text-align: center;
}
.cta-bg .social a{
    font-size: 22px;
    color: #fff;
    font-weight: 600;
}
.portfolio .button-group {
    padding-bottom: 0;
}
/* 14. testimonial */

.testimonial-area .container{
    z-index: 1;
    position: relative;
}
.testimonial-area .test-bg {
    position: absolute;
    top: 0;
    left: -27px;
}
.testimonial-active2 {
    width: 95%;
    margin: auto;
}
.testimonial-area h2,.testimonial-area p{
	
}
.ta-bg {
	height: 475px;
	width: 475px;
	background-repeat: no-repeat;
	margin-top: 25px;
}
.testimonial-author .ta {
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -webkit-transition: all 1200ms ease;
    -ms-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
}
.author-one.now-in-view {
	top: -25px;
	left: 110px;
}
.author-two.now-in-view {
	top: 21%;
	left: 69%;
}
.author-three.now-in-view {
	left: 67%;
	top: 66%;
}
.author-four.now-in-view {
	top: 77%;
	left: 53px;
}
.author-five.now-in-view {
	left: 50px;
	top: 41%;
}

.ta-info {
	overflow: hidden;
	display: block;
}
.ta-info h6 {
	font-size: 20px;
	margin-bottom: 5px;
	color:#fff;
}
.ta-info span {
	color:#fff;
	font-size: 16px;
}
.testi-author {
width: 100%;
float: left;
text-align: center;
}
.testi-author img{
    display: inline-block;
    margin-top: 15px;
}
.single-testimonial p {
	margin-bottom: 0;
}
.testimonial-active .slick-arrow {
	position: absolute;
	bottom: -80px;
	left: 0;
	border: none;
	background: none;
	padding: 0;
	font-size: 24px;
	color: #fff;
	z-index: 9;
	cursor: pointer;
	transition: .3s;
	width: 94%;
}
.testimonial-active i {
    font-size: 30px;
    color:#fff;
    margin-bottom: 30px;
}
.testimonial-active .slick-next {
	left: 40px;
}
.testimonial-active .slick-arrow:hover {color: #ff7350;}
.testimonial-avatar.p-relative {
	min-height: 455px;
	display: block;
	overflow: hidden;
}
.testimonial-avatar .ta {
	position: absolute;
	left: 50%;
	top: 50%;
	overflow: hidden;
	transition: all 1200ms ease;
	-moz-transition: all 1200ms ease;
	-webkit-transition: all 1200ms ease;
	-ms-transition: all 1200ms ease;
	-o-transition: all 1200ms ease;
}
.avatar-one.now-in-view {
	top: 0%;
	left: 28%;
}
.avatar-two.now-in-view {
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
.avatar-three.now-in-view {
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.avatar-four.now-in-view {
	top: 20%;
	left: 70%;
}
.avatar-five.now-in-view {
	top: 70%;
	left: 25%;
}
.avatar-six.now-in-view {
	top: 59%;
	left: 64%;
}
.single-testimonial-bg{
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 3px 4px 15px #0000001A;
border-radius: 10px;
width: 85%;
float: right;
padding: 30px 50px 100px 150px;
margin: 20px;
    color: #525c7b;
}
.testimonial-active .slick-slide
{
position: relative;
background-color:#f0f7ff;
padding:30px;
margin: 0 15px 15px;
box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.01);
 border-radius: 4px;
}
.testimonial-active .slick-slide:hover{
    background: #fff;
    box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.05);
}
.testimonial-active .testi-img {
    float: left;
    margin-bottom: 20px;
    position: absolute;
    left: 0;
    top: 80px;
}
.testimonial-active .ta-info {
width: 100%;
margin-top: 15px;
display: inline-block;
}
.testimonial-active .ta-info span {
    color: #777;
    font-size: 12px;
}
.testimonial-active .ta-info h6 {
    font-size: 18px;
     margin-top: 5px;
    margin-bottom: 0;
    font-weight: 500;
    color: #141b22;
}

.testimonial-active .com-icon {
    position: absolute;
    right: 50px;
    top: 45px;
}
.testimonial-active .slick-dots {
text-align: center;
position: relative;
width: 100%;
margin-top: 35px;
}
.testimonial-active .slick-dots li {
    display: inline-block;
    margin: 0 0px;
    line-height: 0;
}
.testimonial-active .slick-dots li button {
    text-indent: -99999px;
    border:none;
    padding: 0;
    margin-left: 10px;
    z-index: 1;
    cursor: pointer;
    transition: .3s;
    background-color:#ccc;
    height: 10px;
    width: 10px;
    border-radius: 2px;
}

.testimonial-active .slick-dots .slick-active button {
   width: 10px;
     background-color:#ff7350;
}
.testimonial-active .qt-img {
   width: 100%;
text-align: center;
display: inline-block;
}
.testimonial-active .qt-img img{
    display: inline-block;
    margin-bottom: 30px;
}
.testimonial-active2 .qt-img{
    margin-bottom: 30px;
}
.testimonial-active2 .qt-img img{
    display: inline-block;
}
.testimonial-active2 .slider-nav {
    float: right;
    width: 100%;
}
.slider-nav .slick-list.draggable {
    width: 233px;
    float: right;
}

.testimonial-active2 .slick-arrow {
	position: absolute;
top: inherit;
left: inherit;
border: none;
background: none;
padding: 0;
font-size: 24px;
color: #fff;
z-index: 9;
cursor: pointer;
transition: .3s;
right: -96px;
    bottom: 0;
}

.testimonial-active2 .slick-next {
    left: inherit;
    right: -165px;
}
.testimonial-active2 .slick-arrow:hover {color: #ff7350;}
.testimonial-avatar.p-relative {
	min-height: 455px;
	display: block;
	overflow: hidden;
}
.testimonial-active2 .slick-next.slick-arrow i,.testimonial-active2 .slick-arrow i {
border-radius: 50%;
  background-color: rgb(80, 82, 171);
  box-shadow: 2.5px 4.33px 15px 0px rgba(80, 82, 171, 0.4);
width: 60px;
  height: 60px;
line-height: 60px;
}




.testimonial-active2 .ta-info span {
    color: #ff7350;
    font-size: 16px;
}
.testimonial-active2 .testi-author{
    border-bottom: none;
    padding-bottom: 0;
}
.testimonial-active2 .ta-info h6 {
font-size: 20px;
margin-bottom: 5px;
color: #141b22;
}
.com-icon {
    position: absolute;
    right: 0;
    top: 20px;
}
.testimonial-item img {
    width: 80px;
    cursor: pointer;
}

.testimonial-active2 .single-testimonial
{
    text-align: center;
    background: #fff;
    float: left;
    padding: 50px;
    border-radius: 8px;
}

.testimonial-active2 .testi-author img {
    float: none;
    margin-top: 0;
    margin-bottom: 20px;
    display: inline-block;
}
.testimonial-active2 .slick-dots li {
    display: inline-block;
    margin: 0 0px;
}
.testimonial-active2 .slick-dots {
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 25px;
}
.testimonial-active2 .slick-dots li button {
    text-indent: -99999px;
    border: none;
    padding: 0;
    margin-left: 10px;
    z-index: 1;
    cursor: pointer;
    background: no-repeat;
    transition: .3s;
    background-color:#ccc;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}
.testimonial-active2 .ta-info {
    overflow: hidden;
}
.testimonial-active2 .single-testimonial p {
    margin-bottom: 50px;
    font-size: 28px;
    line-height: 38px;
    color: #125875;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
}
.testimonial-active2 .slick-dots .slick-active button {
border-radius: 5px;
     background-color: #ff7350;
}
.testimonial-area .test-an-01{
    position: absolute;
    bottom: 0;
    animation: alltuchtopdown 3s infinite;
-webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
animation-delay: 0s;
-webkit-animation-delay: 1s;
}
.testimonial-area .test-an-02{
    position: absolute;
    top: 0;
    right: 0;

}
.test-line
{
     margin-top: 30px;
    margin-bottom: 30px;
}
.test-line img{
    display: inline-block;
}
.testimonial-active2.testimonial-area3 .single-testimonial{
    background: none;
    padding-top: 0;
    padding-bottom: 0;
}
.testimonial-active2.testimonial-area3 .single-testimonial p{
    color: #fff;
}
.testimonial-active2.testimonial-area3 .ta-info h6{
    color: #fff;
}
/* faq */
.faq-area .section-title p{
font-size: 24px;
margin-top: 25px;
line-height: 34px;
}
.faq-img {
  margin-right: -350px;
}
.faq-btn {
font-size: 17px;
font-weight: 600;
border: none;
outline: none;
cursor: pointer;
padding: 20px 30px;
  padding-right: 30px;
width: 100%;
text-align: left;
padding-right: 75px;
background: #fff;
border: 1px solid #ff7350;
border-radius: 4px;
}
.card .collapse.show {
    display: block;
}
.faq-btn.collapsed {
 background: no-repeat;
color: #190a32;
border: 1px solid #c6c6c6;
border-radius: 4px;
}
.faq-wrap .card-header:first-child {
	border-radius: 0;
}
.faq-wrap .card-header {
	padding: 0;
	margin-bottom: 0;
	background-color: unset;
	border-bottom: none;
}
.faq-wrap .card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: unset;
	padding: 25px 0px;
}
.faq-wrap .card-header h2 {
	font-size: unset !important;
}
.faq-wrap .card {
border: none;
border-radius: 4px !important;
margin-bottom: 15px;
box-shadow: none;
overflow: hidden;
background: no-repeat;
}

.faq-wrap .card:last-child {
    
}
.faq-wrap .card-header h2 button::after {
position: absolute;
content: "\f107";
top: 5px;
right: 5px;
font-size: 26px;
font-family: "Font Awesome 5 Pro";
font-weight: 600;
width: 50px;
height: 50px;
text-align: center;
line-height: 50px;
color: #ff7350;
border-radius: 4px;
 
}

.faq-wrap .card-header h2 button.collapsed::after {
	content: "\f104";
    color: #141b22;
        box-shadow: none;
    font-size: 26px;
       border: 1px solid #c6c6c6;
}
/* 15. blog */
.home-blog-active {
  margin-right: -500px;
}

.home-blog-active .slick-dots{
    text-align: center;
    margin-top: 30px;
}
.home-blog-active .slick-dots li {
    display: inline-block;
    margin: 0 5px;
}
.home-blog-active .slick-dots li button {
    text-indent: -99999px;
    border: none;
    padding: 0;
    margin-left: 10px;
    border-radius: 50px;
    z-index: 1;
    cursor: pointer;
    background: no-repeat;
    transition: .3s;
    
}
 .home-blog-active .slick-dots li.slick-active button {
}
.home-blog-active .slick-dots li.slick-active button::before {
    opacity: 1;
}
.home-blog-active .slick-dots li button::before {
    content: "";
    width: 19px;
    height: 19px;
    float: left;
    position: relative;
    margin-top: -9px;
    left: -19px;
    border-radius: 50%;
    opacity: .7;
    background-repeat: no-repeat;
    background-position: center;
}


.home-blog-active .slick-arrow,.home-blog-active2 .slick-arrow {
   position: absolute;
top: 55%;
transform: translateY(-50%);
left: -440px;
border: none;
background: none;
padding: 0;
color: #f6fbff;
z-index: 1;
-webkit-transition: all 0.3s ease-out 0s;
-moz-transition: all 0.3s ease-out 0s;
-ms-transition: all 0.3s ease-out 0s;
-o-transition: all 0.3s ease-out 0s;
transition: all 0.3s ease-out 0s;
cursor: pointer;
width: 70px;
height: 70px;
text-align: center;
color: #fff;
background: #ff7350;
font-size: 24px;
    box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
}

.home-blog-active .slick-prev {

}
.home-blog-active .slick-arrow:hover,.home-blog-active2 .slick-arrow:hover{
    background: #125875;
}

.home-blog-active .slick-arrow.slick-next,.home-blog-active2 .slick-arrow.slick-next {
right: inherit;
left:-355px;
}
.home-blog-active .single-post2
{
    margin: 0 15px 15px;
}
.home-blog-active .blog-content2{
box-shadow: 1px -0.268px 17px 0px rgba(0, 0, 0, 0.09);
}

.blog-content2 .category {
 position: absolute;
top: -250px;
background: #ff7350;
padding: 10px 19px;
left: 20px;
border-radius: 10px;
box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
font-weight: 500;
text-transform: uppercase;
color: #fff;
}
.blog-thumb img {
	width: 100%;
}
.blog-thumb .b-meta {
padding: 30px 25px 0;
color: #ff7350;
position: absolute;
top: 0;
left: 0;
    z-index: 1;
}
.blog-thumb img{visibility: hidden;}
.blog-thumb:hover img{visibility: visible;}
.blog-content {
	background: #fff;
	padding: 40px 30px;
	position: relative;
	z-index: 1;
}
.single-post2 {
    display: inline-block;
}
.single-post .blog-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: none;
    padding-bottom: 30px;
}
.blog-content h4 {
	font-size: 19px;
	margin-bottom: 20px;
	line-height: 1.3;
}
.blog-content h4:hover a{color: #ff7350;}
.blog-thumb:hover::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #141b22 0% 0% no-repeat padding-box;
opacity: 0.8;
    display: inline-block;
}

.blog-thumb2 img {
width: 100%;
border-radius: 8px;
overflow: hidden;
}
.blog-thumb2 .b-meta {
padding: 30px 25px 0;
color: #ff7350;
top: 0;
left: 0;
    z-index: 1;
}
.blog-content2 {
padding: 30px;
border-radius: 0 0 8px 8px;
float: left;
position: relative;
background: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
}
.blog-thumb2{
    position: relative;
}
.blog-thumb2 .date-home {
  position: absolute;
  top: 30px;
  background: #ff7350;
  color: #fff;
  padding: 5px 15px;
  border-radius: 6px;
  left: 30px;
  font-size: 14px;
}
.blog-content2 .b-meta{
    color: #777;
    padding-bottom: 15px;
}
.blog-content2 .b-meta ul {
    padding-left: 0 !important;
}
.blog-content2 h4{
    font-size: 24px;
     margin-bottom:15px;
    font-weight: 500;
}
.blog-content2 h4 a{
    text-decoration: none;
    line-height: 30px;
}

.blog-area3 .blog-thumb2 .date-home{
    background: #4ea9b4;
}
.blog-area3 .blog-content2 {
  margin: 0 10px;
}
.adim-box{
    display: flex;
}
.adim-box .text {
    padding-top: 10px;
    padding-left: 15px;
    font-size: 14px;
    color: #141b22;
}
.blog-thumb:hover .b-meta h4,.blog-thumb:hover .b-meta h4 a:hover{
    color: #fff;
}
.b-meta ul li {
  display: inline-block;
  margin-right: 8px;
  padding-right: 8px;
  color: #777;
}
.b-meta ul li:last-child{margin-right: 0; padding-right: 0;border: none;}
.b-meta ul li a {
	font-size: 14px;
	text-transform: uppercase;
	color: #444d69;
}
.b-meta ul li a.corpo{color: #ff7350;}
.b-meta ul li a:hover {color: #ff7350;}


.single-post:hover .blog-btn{opacity: 1;}

.blog-btn a {
display: inline-block;
color: #ff7350;
text-align: center;
font-size: 15px;
font-weight: 500;
}
.blog-btn a:hover {
color: #141b22;
}
.blog-btn2 a {
    display: inline-block;
    color: #C0C0C0;
    text-align: center;
    font-size: 14px;
}
.blog-btn a i{
    margin-left: 5px;
}
.b-meta i{
    margin-right: 5px;
    color: #ff7350;
}
.single-post{transition: .3s;background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow:3px 4px 15px #00000026;
 }

.blog-thumb {
    position: relative;
}
.bsingle__post-thumb img {
	  width: 100%;
         height: auto;
}
.bsingle__post .video-p {
  	position: relative;
}
.bsingle__post .video-p .video-i {
	height: 80px;
	width: 80px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 80px;
	border-radius: 50%;
	background: #fff;
	color: #ff7350;
}
.blog-active .slick-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 40px;
	border: none;
	background: none;
	padding: 0;
	font-size: 30px;
	color: #fff;
	z-index: 9;
	opacity: .4;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	cursor: pointer;
}
 .blog-active .slick-arrow.slick-next {
    right: 40px;
	left: auto;
}
 .blog-active .slick-arrow:hover {
	opacity: 1;
}
.bsingle__content {
	padding: 40px 30px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
border-style: solid;
  border-width: 2px;
  border-color: #f5f5f5;
    position: relative;
}
.bsingle__content .admin {
    position: absolute;
    top: -35px;
    background: #fff;
    padding: 15px 25px;
    border-radius: 10px;
    right: 20px;
     border-radius: 16px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    font-weight: 500;
}
.bsingle__content .admin i{
    margin-right: 10px;
    font-size: 18px;
}

.blog-details-wrap .meta-info ul {
    padding-top: 25px;
    border-top: 2px solid #f5f5f5;
    padding-bottom: 25px;
}
.blog-details-wrap .meta-info ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 15px;
    font-weight: 500;
    padding-right: 15px;
    border-right: 2px solid #f5f5f5;
}
.blog-details-wrap .meta-info ul li i {
    color: #ff7350;
    margin-right: 5px;
}
.blog-details-wrap .meta-info ul li:last-child {
    border: none;
}

.blog-deatails-box02 .meta-info ul {
    margin-top: 15px;
    padding-left: 0;
    padding-top: 40px;
}
.bsingle__content .meta-info ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 15px;
    font-weight: 500;
}
.bsingle__content .meta-info ul li:last-child{
    border: none;
}
.bsingle__content .meta-info ul li i{
    color: #ff7350;
}
.bsingle__content .meta-info ul li a {
	color: #3763eb;
	text-transform: capitalize;
}
.bsingle__content .meta-info ul li a i {
	margin-right: 5px;
}
.bsingle__content .meta-info ul li a:hover {
	color: #ff7350;
}
.bsingle__content .meta-info ul li i {
	margin-right: 5px;
}
.bsingle__content h2 {
	font-size: 30px;
	line-height: 1.3;
	margin-bottom: 20px;
	font-weight: 500;
}
.bsingle__content h2:hover a {
	color: #ff7350;
}
.bsingle__content p {
	margin-bottom: 25px;
}
.bsingle__content .blog__btn .btn {
	font-size: 16px;
}

.bsingle__content .blog__btn .btn:hover {
	background: #ff7350;
	color: #fff;
}


/* 18. pricing */
.pricing-area .nav {
    box-shadow: 0px 16px 32px #00000014;
    margin-top: 50px;
    border-radius: 30px;
    padding: 15px;
}

.pricing-area .nav-pills .nav-link{
    padding: 15px 30px;
}
.pricing-area .nav-pills .nav-link.active
{
    background: #4cc3c1;
    padding: 15px 40px;
    border-radius: 30px;
}
.pricing-area .nav-pills .nav-link span {
    background: #ffebe4;
    padding: 5px 9px;
    font-size: 12px;
    border-radius: 15px;
    color: #ff7350;
    font-weight: 600;
}
.pricing-box {
   float: left;
width: 100%;
position: relative;
background: #fff;
border-radius: 4px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
padding:50px;
}

.pricing-head {
float: left;
width: 100%;
position: relative;
}
.pricing-head .month{
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 4px;
  background-color: rgb(225, 225, 225);
    padding: 3px 10px;
}

.pricing-head h3{
 font-size: 24px;
font-weight: 500;
    margin-bottom: 20px;
}
.price-count sub{
    font-size: 14px;
    bottom: 5px;
}
.pricing-head .glyph-icon::before{
font-size: 32px;
margin-left: 0;
width: 85px;
background: #3763eb;
height: 85px;
display: inline-block;
line-height: 65px;
border-radius: 50%;
color: #fff;
margin-bottom: 30px;
border: 10px solid #eee;
}

.pricing-head h4 {
    font-size: 30px;
    font-weight: 600;
    color: #141b22;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.price-count h2 {
font-size: 50px;
font-weight: 600 !important;
margin-bottom: 0 !important;
color: #141b22;
font-family: 'Roboto', sans-serif;
}
.price-count h2 strong {
font-size: 15px;
margin-left: -5px;
color: #141b22;
font-weight: 600;
}
.price-count small {
	font-size: 18px;
	font-weight: 600;
	position: relative;
	top: -20px;
	margin-right: 3px;
}
.price-count span {
color: #141b22;
font-size: 15px;
margin-left: -10px;
}
.pricing-body {
	padding: 0px;
    width: 100%;
}

.pricing-body li{
	margin-top:15px;
    list-style: none !important;
}
.pricing-body li:first-child {
    margin-top: 0;
}
.pricing-body li::before{
content: '';
font-family: "Font Awesome 5 Pro";
color: #777;
position: relative;
float: right;
text-align: center;
font-size: 12px;
line-height: 22px;
margin-top: 2px;
border-radius: 50%;
margin-right: 0;
}
.pricing-body p {
	margin-bottom: 15px;
	font-size: 14px;
}

.pricing-box.active {
    border-color: #125875;
    background: #125875;
}
.pricing-box.active .pricing-head .glyph-icon::before{background: #fff;color: #3763eb;}
.pricing-box.active .pricing-head h4{color: #fff;}
.pricing-body {
    float: left;
}
.pricing-head h5 {
font-size: 30px;
margin-bottom: 15px !important;
}
.pricing-box2 .icon{
    margin-bottom: 15px;
}
.pricing-box2 .icon img{
    height: 40px;
}
.pricing-box2 hr {
background: #d7d7d7;
margin: auto;
padding: 0;
display: inline-block;
width: 100%;
height: 2px;
border: none;
}


.pricing-box2.active {
background: #fff9f7;
}

.pricing-box.active .pricing-btn .btn {
    background: #ff7350;
    color: #fff;
    border-color: #ff7350;
}
.pricing-box2 .pricing-head p {
margin-bottom: 10px;
margin-top: 0;
}
.pricing-box2 .price-count {
  display: inline-block;
    margin-bottom: 15px;
}
.pricing-box2 .price-count h2 {
font-size: 36px;

}
.pricing-btn .btn {
   background: #125875;
    border-color:  #125875;
    
}
.pricing-btn .btn::before {
      background: #ff7350;
    border-color:  #ff7350;
}
.pricing-btn {
  width: 60%;
  margin: auto;
}
/* 19. contact */
 .contact-form .btn {
  background: #ff7350;
  border: 1px solid #ff7350;
}
 .contact-form .btn:hover {
  background: #125875;
  border: 1px solid #125875;
}
.search-area h2{
    color: #fff;
}
.search-area p{
    font-size: 18px;
    color: #b0cbd7;
    margin-top: 15px;
}
.search-area .slider-btn,.search-area .slider-btn button{
    width: 100%;
}
.contact-bg input,.contact-bg .contact-field textarea{
    border: none !important;
    
}
.contact-bg input{
    height: calc(3.5em + .55rem + 2px);
}
.contact-bg .contact-field textarea{
    height: 250px;
}
.contact-bg .contact-field i{
    position: absolute;
    right: 20px;
    top: 20px;
}
.contact-bg02 {
background: #032e3f;
padding: 80px 50px;
border-radius: 10px;
}
.contact-bg02 h2{
   font-size: 35px;
color: #fff;
}
.contact-area {
	background-repeat: no-repeat;
	background-position: center center;
}
.contact-area .map {
    position: absolute;
    right: 0;
    top: 0;
}
.contact-wrapper textarea {
	border: 0;
	color: #000;
	font-size: 15px;
	height: 200px;
	width: 100%;
	text-transform: capitalize;
	transition: .3s;
	background: #f4f4fe;
	padding: 30px 40px;
}
.contact-wrapper textarea::-moz-placeholder {
	color: #b3bdcd;
	font-size: 14px;
}
.contact-wrapper textarea::placeholder {
	color: #8990b0;
	font-size: 14px;
}
.c-icon i {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 40px;
	color: #8990b0;
	font-size: 14px;
}
.contact-message.c-icon i{top: 30px;transform: unset;}
.contact-wrapper input {
	border: 0;
	color: #000;
	font-size: 15px;
	height: 80px;
	text-transform: capitalize;
	width: 100%;
	padding: 0 40px;
	transition: .3s;
	background: #f4f4fe;
}
.contact-wrapper input::-moz-placeholder {
	color: #8990b0;
	font-size: 14px;
}
.contact-wrapper input::placeholder {
	color: #8990b0;
	font-size: 14px;
}
.contact-name {
	position: relative;
}
.contact-field label {
	display: block;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: #141b22;
	margin-bottom: 20px;
	cursor: unset;
}
.contact-field {
 padding-right: 0;
}
.contact-field input  {
	width: 100%;
border: none;
padding-left: 15px;
transition: .3s;
border-radius: 10px;
    border: 1px solid #777;
    height: calc(3.0em + .55rem + 2px);
}

.contact-field.c-name::after{content: "\f007";}
.contact-field::after {
	position: absolute;
	font-family: "Font Awesome 5 Pro";
	display: inline-block;
	font-size: 14px;
	text-align: center;
	right: 25px;
	color: #ff7350;
	bottom: 22px;
    display: none;
}
.contact-field.c-email::after{content: "\f0e0";}
.contact-field.c-subject::after{content: "\f249";}
.contact-field.c-message::after {
	content: "\f303";
	font-weight: 600;
	top: 20px;
	bottom: unset;
}
.contact-field input::placeholder,.contact-field textarea::placeholder{color: #9e9e9e;}
.contact-field textarea {
width: 100%;
padding: 15px;
transition: .3s;
height: 115px;
border: 1px solid #777;
border-radius: 10px;
}


.contact-bg {
	background-size: cover;
	background-position: center;
	z-index: 1;
}
.contact-img {
	position: absolute;
	bottom: 0;
	right: 170px;
	z-index: -1;
}
/* 20. footer */
.recent-blog-footer li {
    display: flex;
    margin-bottom: 25px !important;
}
.recent-blog-footer li .thum {
    float: left;
    width: 155px;
    margin-right: 10px;
}
.recent-blog-footer li .thum img {
  border-radius: 10px;
}
.recent-blog-footer a {
    width: 100%;
    display: inline-block;
    color:#fffc !important;
}
.recent-blog-footer a:hover {
    color:#fff !important;
}
.recent-blog-footer span {
    color:#ff7350;
}

.footer-top-heiding {
    
    margin-bottom: 50px;
}
.footer-top-heiding .container{
    padding-bottom: 50px;
    border-bottom: 1px solid #ffffff2e;
}
.f-contact {
display: flex;
}
.f-contact i {
  width: 40px;
  height: 40px;
background: #ff7350;
  line-height: 40px;
  color: #fff !important;
  text-align: center;
  border-radius: 50%;
  float: left;
}


.f-contact a{
    color:#b2c0d8 !important;
}
.f-contact a:hover{
    color: #fffc!important;
}
.footer-top-heiding .col-lg-4 {
    border-right: 2px solid #20282E;
}
.footer-top-heiding .col-lg-4:last-child{
    border-right: none;
}

.f-contact span{
    color:#b2c0d8;
}
.f-contact h3 {
    color: #fff;
    font-size: 24px;
}
.footer-top-heiding h2{
    color: #fff;
}
.footer-bg {
    color:#777;
    background-position: center center!important;
    background-size: cover;
    background-repeat: no-repeat;
}
.f-cta-area.gray-bg {
	background: #f4f4fe;
	border-top: 3px solid #ff7350;
}
.footer-text p {
	color: #ddd;
	margin-bottom: 0;
}

.footer-social span {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	font-family: 'Playfair Display', serif;
	display: inline-block;
	margin-right: 20px;
}
.footer-social a {
font-size: 16px;
display: inline-block;
margin-right: 10px;
width: 40px;
height: 40px;
 border-radius: 50%;
background-color: #ffffff47;
text-align: center;
line-height: 40px;
color: #fff !important;
}
.footer-social a:hover{    
  background: #ff7350;
    color: #fff !important;
}
.f-widget-title h2,.footer-widget .widgettitle  {
	font-size: 24px;
margin-bottom: 5px;
position: relative;
padding-bottom: 30px;
    color: #fff;
}
.f-widget-title h2::before,.footer-widget .widgettitle::before {
     content: "";
position: absolute;
left: 0;
bottom: 22px;
height: 1px;
width: 70px;
background: #ff7350;
}
.f-widget-title img {
    width: 50%;
}

.footer-link ul li i {
    color: #ff7350;
    margin-right: 5px;
}
.footer-link ul li a {
	color:#b2c0d8;
	display: inline-block;
}
.footer-link ul li a::before {
  content: "+";
  margin-right: 8px;
  color: #ff7350;
  font-size: 18px;
    display: none;
}
.footer-link ul li a:hover{color: #fff;}
.footer-link ul li {
margin-bottom: 10px;
}
.footer-link ul li:last-child{margin-bottom: 0;}
.f-insta ul li {
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 9px;
    width: 31.2% !important;
}
.f-insta ul li img {
  width: 100%;
}
.f-insta ul li:nth-child(3), .f-insta ul li:nth-child(6) {
    margin-right: 0 !important;
}
.f-insta ul li a{display: block;position: relative;}
.f-insta ul li a::before {
	content: "\e035";
	font-family: "dripicons-v2" !important;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%,-50%);
	font-size: 24px;
	color: #fff;
	transition: .3s;
	opacity: 0;
	z-index: 9;
}
.f-insta ul li a::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #ff7350;
	opacity: 0;
	transition: .3s;
}
.f-insta ul li:hover a::before{top: 50%;opacity: 1;}
.f-insta ul li:hover a::after{opacity: .6;}
.copyright-wrap {
    background: #ff7350;
    color: #fff;
    font-size: 15px;
}
.copyright-wrap .container{
    padding: 20px 0;
}
.copyright-wrap li {
    display: inline;
    padding-left: 15px;
    margin-left: 40px;
    position: relative;
}
.copyright-wrap li::before {
background-color: #141b22;
width: 8px;
height: 8px;
content: "";
position: absolute;
left: -4px;
top: 5px;
border-radius: 50px;
}
.copy-text img{
    width: 50%;
}
.copyright-wrap li a{
    color: #fff;
}
.f-contact .icon {
    float: left;
    margin-top: 7px;
    margin-right: 15px;
    width: 40px;
}

.f-contact li {
    float: left;
    margin-bottom: 15px !important;
    width: 100%;
}
.f-contact li:last-child{
    margin-bottom: 0px !important;
}
#contact-form4{
    margin-top: 20px;
}
#contact-form4 input {
    float: left;
    padding: 23px 30px;
    border: none;
    width: 75%;
}
#contact-form4 button {
    padding: 15px 30px;
    border-radius: 0;
}
/* Fillter css */

.portfolio .col2 .grid-item {
    width: 49%;
	padding:15px;
}
.portfolio .col3 .grid-item {
    width: 33.33%;
    margin: 0;
    padding: 15px;
}
.portfolio .col3 .grid-item .box{
    position: relative;
    overflow: hidden;
}
.portfolio .col3 .grid-item .box a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #b68c5a;
    opacity: 0;
    transition: .3s;
}
.portfolio .col3 .grid-item .box a::before {
    content: "\e035";
    font-family: "dripicons-v2" !important;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 24px;
    color: #fff;
    transition: .3s;
    opacity: 0;
    z-index: 9;
}
.portfolio .col3 .grid-item .box a:hover::before {
    top: 50%;
    opacity: 1;
}
.portfolio .col3 .grid-item .box a:hover::after {
    opacity: .6;
}
.portfolio .col3 .grid-item .box20 img {
    width: auto;
    height: 380px;
    border-radius: 0px;
    max-width: inherit;
    margin-left: -150px;
}
.portfolio .col4 .grid-item {
    width: 25%;
    margin: 0;
    padding: 0;
}
.gallery-image {
overflow: hidden;
position: relative;
    margin: 0;
}
.gallery-image figcaption {
	width: 100%;
	padding: 0 35px 30px 60px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	color: #ff7350;
	font-size: 16px;
	font-weight: 500;
    position: absolute;
     bottom:-150px;
    z-index: 2;
    text-align: left;
}
.gallery-image::before {
    content: "\e035";
    font-family: "dripicons-v2" !important;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 24px;
    color: #fff;
    transition: .3s;
    opacity: 0;
    z-index: 9;
    background: #125875;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
}
.gallery-image:hover::before {
    top: 50%;
    opacity: 1;
}
.gallery-image::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ff7350;
    opacity: 0;
    transition: .3s;
}

.gallery-image:hover::after {

    opacity: .85;

}
.masonry-gallery-huge {
    margin: auto;
padding-right:1.85%;
padding-left: 1.85%;
}
.grid-item{
    margin: 15px;
}
.gallery-image:hover figcaption {
   opacity: 1;	
    bottom:30px;

}
.gallery-image figcaption h4{
    font-weight: 600;
    font-size: 36px;
    color: #fff;
}
.gallery-image figcaption .span{
    color: #b68c5a;
    font-size: 14px;
     position: relative;
    padding-left: 40px;
    display: inline-block;
    margin-bottom: 0;
}
.gallery-image figcaption .span::before {
    content: "";
    width: 30px;
    position: absolute;
    height: 2px;
    background: #b68c5a;
    top: 50%;
    left: 0;
}
.gallery-image figcaption .span p{
    margin-bottom: 0;
}
.gallery-image figcaption .icon {
    position: absolute;
    right: 0;
    background: #3763eb;
    width: 45px;
    height: 45px;
    line-height: 45px;
    top: 0;
    color: #fff;
    text-align: center;
    font-size: 40px;
}
.button-group {
    padding-bottom: 15px;
}

.button-group button {
    border: none;
    background: none;
    transition: .3s;
    cursor: pointer;
    outline: none;
    color:#777;
    position: relative;
    padding-top: 0;
    margin-left: 15px;
    font-family: 'Jost', sans-serif;
}
.button-group button:hover {
    color: #030c26;
}
.button-group button.active{
    color: #fff;
background: #ff7350;
font-weight: 500;
padding: 5px 10px;
border-radius: 4px;
}

.button-group button::before {
  content: "/";
  position: relative;
  left: -12px;
    display: none;
}
.button-group button:first-child::before {
display: none;
}
.gallery-image img{
   width: 100%;
    margin-bottom: 0;
}


/* 21. Menu Theme dropdown default css */
.f-cta-icon {
    float: left;
    margin-right: 30px;
}
.single-cta {
    line-height: 28px;
}
.single-cta a {
    color: #141b22;
}
.main-menu ul li.has-sub .current a{
   color:#222;
}
.main-menu ul li.current a{
   color:#ff7350;
}
.main-menu .has-sub > ul {
visibility:hidden;
position: absolute;
background-color: #ffff;
min-width: 250px;
z-index: 1;
transition: all 0.3s ease-in-out;
margin-top: 15px;
border-top: 4px solid #ff7350;
box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
opacity: 0;
}
.main-menu .has-sub li a{
    padding: 15px;

}
.main-menu .has-sub li {
margin-left: 0 !important;
float: left;
border-bottom: 1px solid #ddd;
width: 100%;
text-align: left;

}

.main-menu .has-sub a:hover{
	color:#ff7350;
}

.main-menu .has-sub:hover > ul { visibility: visible; margin-top: 0px; opacity: 1;}
.main-menu .has-sub > ul > .has-sub > ul {
  display: none;
  position: absolute;
background-color: #ffff;
width: 250px;
z-index: 1;
padding: 0;
margin-left: -255px;
margin-top: 0;
top: -3px;
}
.main-menu .has-sub > ul > .has-sub:hover > ul{display: block;}
.main-menu .has-sub > ul > .has-sub > ul > .has-sub > ul {
   display: none;
  position: absolute;
background-color: #ffff;
width: 250px;
z-index: 1;
padding: 0;
margin-left: 255px;
margin-top: 0;
top: -3px;
}
.main-menu .has-sub > ul > .has-sub > ul > .has-sub:hover > ul {display: block;}

.main-menu .has-sub ul li a {
    color: #4f4f4f;
}
.main-menu .has-sub ul li a:hover {
    color: #ff7350;
}
/* 23. mega menu */

.offcanvas-menu .has-mega-menu ul {
    display: none;
}
.offcanvas-menu  li.has-sub.has-mega-menu:hover > ul {
   margin-left: -1160px;
background: #00081b;
top: 121px;
}
.offcanvas-menu .mega-menu-column.has-sub ul li a {
    color: #fff;
	font-size: 14px;
}
.offcanvas-menu .mega-menu-column.has-sub ul li a:hover {
    color: #ff7350;
}
.has-mega-menu{
        position:static;
    }
	 
    li.has-sub.has-mega-menu ul{
        position: absolute;
        top: NNpx; /*insert the needed value*/
        z-index: 100;
        left:0px;
        right:0px;
		width: 1180px;

    }
	.main-menu .mega-menu-column.has-sub a {
    color: #333;
}
.main-menu .mega-menu-column.has-sub a:hover {
    color: #ff7350;
}
    li.has-sub.has-mega-menu:hover>ul{
    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
        display: flex;
        flex-wrap: wrap;
    /*CSS GRID lyout*/ 
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(25%, 1fr));   
		margin-left: -820px;
		
    }
	li.has-sub.has-mega-menu:hover>ul>li>ul{
    /*flexbox fallback for browsers that do not support CSS GRID lyout*/
        display: flex;
        flex-wrap: wrap;
width: auto;
background:none;
    /*CSS GRID lyout*/ 
        display: grid;       
		position: relative;
border: none;
margin-left:0;
    }


@media screen and (min-width: 992px){
    li.has-mega-menu:hover>ul{grid-template-columns: repeat(auto-fit,minmax(33.3333%, 1fr)); }
}
@media screen and (min-width: 1200px){
    li.has-mega-menu:hover>ul{grid-template-columns: repeat(auto-fit,minmax(25%, 1fr)); }
}

@media screen and (min-width: 600px){
    li.mega-menu-column {
        width: 100%;
        max-width: 100%;
        min-height: 1px;
        padding: 10px 25px;
        flex: 1 0 calc(50%);
    }
}
@media screen and (min-width: 992px){
    li.mega-menu-column {flex: 1 0 calc(33.333%);}
}

   .main-menu .has-sub .mega-menu-column li {flex: 1 0 calc(25%); border:none;}

 .menu .children{
    display: none;
    position: absolute;
    background-color: #ffff;
    min-width: 250px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
    border-top: 4px solid #ff7350;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}
.menu .sub-menu li, .menu .children li  {
margin-left: 0 !important;
float: left;
border-bottom: 1px solid #ddd;
width: 100%;
text-align: left;
}
.main-menu .sub-menu li a, .menu .children li a {
    padding: 15px;
      color: #000;
}
.menu .menu-item-has-children:hover > ul,.menu .page_item_has_children:hover > ul {
    display: block;
    margin-top: 0px;
}
.main-menu ul li.page_item {
    margin-left: 15px;
}
.main-menu ul li.page_item::after{
    display: none;
}
.main-menu ul li.page_item.current_page_item{
    margin-left: 50px;
}
.main-menu ul li.page_item.current_page_item::after{
    display: block;
}

/* 22. Theme sidebar widge css */
.sidebar-widget {
  height: 100%;
}
.sidebar-widget a{
	color:#777;
}
.tag-cloud-link {
   font-size: 14px !important;
border: 1px solid #e4e4e4;
padding: 10px 19px !important;
display: inline-block;
margin: 5px 1px !important;
text-transform: capitalize;
float: none !important;
width: auto !important;
}
.tag-cloud-link:hover {
     border: 1px solid #ff7350;
	 color:#6a6a6a;
}
.widget_text img{

	height:auto;
}
.sidebar-widget select{
	width:100%;
	padding:3px 0;
	border: 2px solid #e4e4e4;
}
.recentcomments a{
 color:#141b22;
}
.sidebar-widget a.rsswidget{
 color:#141b22;
}
#wp-calendar caption {
    color: #141b22;
    font-weight: 500;
    font-size: 14px;
}

.widget_media_image a:hover {
    padding-left: 0 !important;
}
.sidebar-widget .widget li {
    padding-bottom: 5px;
    border-bottom: 1px solid #e4e4e4;
    padding-top: 5px;
    float: left;
    width: 100%;
    min-height: 42px;
}
.sidebar-widget .widget a {
    width: 100%;
    text-align: left;
    line-height: 28px;
    margin-bottom: -26px;
    position: relative;
    z-index: 2;
}
.sidebar-widget .widget li .children {

    border-top: 1px solid #e4e4e4;
    margin-top: 10px;
    padding-bottom: 0px;
    display: inline-block;
    width: 100%;

}
.mobile-menu{
    display: none;
}

@media (max-width: 912px) {
    .mobile-menu{
      display: block;
    }
}

.z-class{
    z-index: 999;
}

.z-class-two{
    z-index: 1;
}

.sidebar-widget .widget li.page_item_has_children{
    padding-bottom:0;
}

.sidebar-widget .widget .children{
	padding-left:15px;
}

.sidebar-widget .widget .children ul.children{
	padding-left:30px;
}
.sidebar-widget .page_item_has_children li {

    padding-top: 10px;
    display: block;

}
.sidebar-widget .widget .children li {
    padding-top: 10px;
}
.sidebar-widget .widget .children .children li {
    padding-top: 10px;
}
.sidebar-widget .widget li:last-child,.sidebar-widget .widget .children li:last-child,.sidebar-widget .widget .children .children li:last-child {
	border-bottom:none;
	padding-bottom:0;
	margin-bottom:0;
}

.sticky .bsingle__content,.tag-sticky-2{

background: #e4e4e4;

}
.sticky .bsingle__content .meta-info ul {
    padding-top: 40px;
    border-top: 2px solid #e8e8e8;
    display: inline-block;
    width: 100%;
    padding-left: 0;
}
.sticky .bsingle__content ul li {
margin-bottom: 0;
list-style: disc;
margin-left: 0;
border-color: #e8e8e8;
}

#attachment_907 {
    width: 100% !important;
}
.wp-image-907 {
    width: 100% !important;
	height: auto;
}
.sidebar-widget .widget a{
width:85%;
text-align:left;
}
.sidebar-widget .widget a:hover {
	padding-left:10px;
}
.sidebar-widget .widget .widget_archive li:hover,.widget_categories li:hover,.sidebar-widget .widget_archive li:hover{
	  color: #ff7350;
}
.post-password-form input[type="password"]{
    background: #e4e4e4;
    border: none;
    width: 300px;
    padding: 10px 30px;
}
.post-password-form input[type="submit"] {
   border: none;
background: #141b22;
padding: 10px 30px;
color: #ffffff;
font-size: 14px;
cursor: pointer;
}
.post-password-form input[type="submit"]:hover {
    background: #ff7350;
}
.wp-block-cover__video-background {
    width: 100%;
}
.sidebar-widget .widget_nav_menu .sub-menu {
    padding-left: 15px;
}
.sidebar-widget .widget_nav_menu .sub-menu {
    display: block;
    position: relative;
    margin-top: 5px !important;
    border: none;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: medium;
    border-top: 1px solid #ddd;
}

.with-avatar .avatar {
    border-radius: 50% !important;
    display: none !important;
}
.ab-submenu .ab-item img {
    display: none !important;
}
.page #comments {
    float: left;
    width: 100%;
    margin-top: 50px;
}
video {
    width: 100%;
}
.sidebar-widget .widget_text a:hover{
	padding-left:0;
}
.dsnone
{
	display:none;
}

.wp-block-media-text__media img {
    width: 100%;
}

.wp-block-image .alignleft {
    float: left;
    margin-right: 1em;
}
.wp-block-image figure.alignright {
    margin-left: 1.5em;
}
.wp-block-image .alignright {
    float: right;
    margin-left: 1em;
}
.wp-block-image img {
    max-width: 100%;
}
.wp-block-image:not(.is-style-rounded) img {
    border-radius: inherit;
    height: auto;
}
.wp-block-columns.alignwide .wp-block-column {
   margin-left: 0;
}
.wp-block-column .wp-block-quote {
    margin-top: 0;
    margin-right: -15px;
    float: right;
}
.wp-block-columns.alignwide .wp-block-column {
    margin-left: 0;
    padding-right: 15px;
}
.wp-block-gallery.columns-2 li
{
	width: 48%;
padding: 10px;
}

 .blocks-gallery-item img {
    margin: 0 1em 1em 0;
        margin-right: 1em;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-self: flex-start;
    width: calc(50% - 1em);
}
.wp-block-image figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    font-weight: 400;
    color: #777;
}
.wp-block-galler {
    overflow: hidden;
	display:inline-block;
    position: relative;
	width:100%;
}
.blocks-gallery-item {
    margin: 0;
    position: relative;
}
.blocks-gallery-item img, .blocks-gallery-item img:hover {
    background: 0 0;
    border: none;
    box-shadow: none;
    max-width: 100%;
    padding: 0;
    vertical-align: middle;
}
.blocks-gallery-item figcaption {
    background: #eee;
    background: rgba(255,255,255,.8);
    color: #333;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    text-indent: 10px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
	opacity: 0;
}
.blocks-gallery-item:hover  figcaption {
	opacity: 1;
}
.wp-block-gallery.columns-3 li
{
	width: 33.333%;
padding: 10px;
height: 200px;
}
.wp-block-gallery.columns-2 li
{
	width: 48%;
padding: 10px;
}
.wp-block-gallery {
    width: 100%;
    float: left;
}

.wp-block-gallery.columns-5 li {
    width: auto;
    height: auto;
    overflow: hidden;
    columns: auto;
}
.bsingle__content .alignright {
    float: right;
	  margin-left: 1.5em;
}
.bsingle__content .alignleft {
    float: left;
    margin-right: 1em;
}
.alignright {
    float: right;
	  margin-left: 1.5em;
}
.alignleft {
    float: left;
    margin-right: 1em;
}
figure.aligncenter {

    width: 100% !important;
    text-align: center;
	float:left;
}
.aligncenter {
	margin:auto;
	display: inherit;
}
.page-links {
    float: left;
    width: 100%;
    margin-top: 50px;
}
#comments {
    float: left;
    width: 100%;
}
.bsingle__content ul,.pages-content ul{
	padding-left:17px;
}
.comment-text ul{
	padding-left:20px;
}
.bsingle__content ul li,.comment-text ul li,.pages-content ul li {
    list-style: disc;
}
 .bsingle__content table th ,.bsingle__content table td,.comment-text table th ,.comment-text table td,.pages-content table th,.pages-content table td{
   border: 1px solid #e4e4e4;
padding: 10px;
}
 .bsingle__content table,.comment-text  table,.pages-content table  {
    margin-bottom: 20px;
}
.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
    padding: 30px;
}
 .bsingle__content ol {
    padding: 0 0 0 1.5em;
}
.bsingle__content ol li,.comment-text ol li,.pages-content ol li{
    list-style: decimal;
}
.bsingle__content figure {
    margin: 0 0 1rem;
}
a, h1, h2, h3, h4, h5, h6, p, span {
    overflow-wrap: break-word;
}
h1, h2, h3, h4, h5, h6{
    overflow-wrap: break-word;
}
.has-text-color.has-background.has-very-light-gray-color {
    color: #fff;
    padding: 21px;
}
.wp-block-cover-text {
    color: #fff !important;
    padding: 30px;
}
.inner-linke-page a,.post-page-numbers {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	background: #c5015f;
	border-radius: 50%;
	color: #fff;
	 font-weight: 400;
	font-size: 14px;
	text-align: center;
}
.inner-linke-page a:hover,.post-page-numbers:hover {
	color: #ffffff;
	background: #010f2e;
}
.inner-linke-page > span,.post-page-numbers.current {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	font-weight: 400;
	border-radius: 50%;
	font-size: 14px;
	text-align: center;
    background: #010f2e;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32);
}

.tag-template img {
    width: auto !important;
}
.error-page .error-code {
    display: block;
    font-size: 150px;
    line-height: 150px;
    color: #333;
    margin-bottom: 20px;
    text-shadow: 5px 5px 1px rgba(0,0,0,.1);
}
.error-body a {

    margin-top: 30px;
    margin-bottom: 100px;

}
.tag-markup-2 li > ul, li > ol, .pages-content li > ul, li > ol {
    padding-left: 1.5em;
}
.tag-markup-2 ul li,.tag-markup-2 ol li,.comment-text ul li,.comment-text ol li,.pages-content ul li,.pages-content ol li {
    margin-top: 10px;
    margin-bottom: 0;
}
.parent-pageid-1725 figure.aligncenter {
    margin: 50px 0;
}
.bsingle__content ul ul li, .pages-content ul ul li {
  list-style: circle;
}

footer #wp-calendar caption
{
  color:#fff;
}
ol {
    padding-left: 15px;
}
iframe {
    width: 100%;
}

.comment-list li {

    margin-bottom: 30px;

}
.wp-block-button__link {
    border: none;
    font-weight: 600;
    padding: .76rem 1rem;
    outline: none;
    outline: none;
    display: inline-block;
    background: #141b22;
    color: #fff !important;
    width: auto;
	border-radius: 5px;
	text-align:center;
     margin-bottom: 15px;
}
.wp-block-button.aligncenter .wp-block-button__link {

    width: 50%;
    display: block;
    margin: auto;

}
.wp-block-button.is-style-outline .wp-block-button__link {

    background: none;
    border: 2px solid #141b22;
    color: #141b22 !important;

}

.wp-block-button.is-style-squared .wp-block-button__link{
	border-radius: 0;
}
.has-2-columns,.has-3-columns,.has-5-columns,.has-6-columns
{
	float:left;
	width:100%;
}
.has-2-columns .wp-block-column {
float:left;
width:50%;
padding-right: 20px;
}
.has-3-columns .wp-block-column {
float:left;
width:33.333%;
padding-right: 20px;
}
.has-5-columns .wp-block-column {
float:left;
width:20%;
padding-right: 20px;
}
.has-4-columns .wp-block-column {
float:left;
width:25%;
padding-right: 20px;
}
.has-6-columns .wp-block-column {
float:left;
width:16.66666666666667%;
padding-right: 20px;
}
.wp-block-media-text.alignwide figure {

    float: left;
    width: 100%;
    margin-right: 20px;

}
 .wp-block-media-text.alignwide .has-large-font-size {
    font-size: 28px;
	color: #141b22;
}
.wp-block-media-text__content {

    float: left;
    width: 47%;
    padding-top: 0;

}
.wp-block-columns.alignwide.has-3-columns blockquote{
	margin-top:0;
	padding-left: 20px;
}
.columns-3.is-cropped li {
    list-style: none;
    float: left;
    width: 33.333%;
}
.wp-block-gallery.columns-3.is-cropped.alignfull {
    margin-top: 0;
}
.blocks-gallery-grid, .wp-block-gallery {
    padding: 0 !important;
}
.wp-block-gallery.alignleft.columns-2.is-cropped li {

    float: left;
    list-style: none;

}
.wp-block-gallery.alignwide.columns-4.is-cropped li {

    float: left;
    list-style: none;
    width: 25%;
    padding: 10px;

}
.wp-block-gallery.columns-6.is-cropped li {

    float: left;
    width: 16.666%;
    height: 129px;
    list-style: none;
    padding: 10px;

}
.wp-block-gallery.columns-7.is-cropped li {

    float: left;
    width: 14%;
    padding: 10px;
    list-style: none;
	min-height: 150px;

}
.wp-block-gallery.columns-8.is-cropped li {

    float: left;
    width: 12%;
    padding: 10px;
    list-style: none;
    min-height: 100px;

}
.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile {
    float: left;
}
.wp-block-media-text.alignfull.has-media-on-the-right.is-stacked-on-mobile figure {
    float: left;
    width: 50%;
    margin-right: 20px;
}
.footer-widget .widgettitle,.footer-widget .cat-item,.footer-widget .widget ul li{
	text-align:left;
	color:#fff;
}

.blog-deatails-box.single .single {
    display: none;
}

.redux-footer h2
{
	margin-top: 0px;
}
.footer-widget td {
    padding: 5px;
    text-align: center;
}

.footer-widget table {
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
}
.footer-widget th {
    background: #e4e4e4;
    padding: 10px 5px;
    text-align: center;
    color: #141b22;
}

.bsingle__content h1,.bsingle__content h2,.bsingle__content h3,.bsingle__content h4,.bsingle__content h5,.bsingle__content h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 600;
}
.pages-content h1,.pages-content h2,.pages-content h3,.pages-content h4,.pages-content h5,.pages-content h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 600;
}
.comment-list h1,.comment-list h2,.comment-list h3,.comment-list h4,.comment-list h5,.comment-list h6 {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 600;
}

.inner-linke-page {
    font-size: 16px;
    font-weight: 600;
    color: #141b22;
}
.inner-linke-page a {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	background: #ff7350;
	border-radius: 50%;
	color: #fff;
	 font-weight: 400;
	font-size: 14px;
	text-align: center;
}
.inner-linke-page a:hover {
	color: #ffffff;
	background: #141b22;
}
.inner-linke-page > span {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	font-weight: 400;
	border-radius: 50%;
	font-size: 14px;
	text-align: center;
    background: #141b22;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32);
}

pre {
	padding: 30px;
    background: #e4e4e4;
}
pre cite {
    color: #000;
}
.inner-linke-page {
    margin-bottom: 50px;
}
.attachment-aports-featured-large {
    width: 100%;
    height: auto;
}
.quote-post {
    min-height: 200px;
}

/* Here's the new CSS to add... */

.admin-bar .menu-area {
   
}
.admin-bar .second-header {
  
}
.admin-bar .sticky-menu {
  top: 32px;
  margin-top:0;
}


/* 24. call */
.call-text {
  padding: 120px 0 0 60px;
}
.call-area h5{
    color: #ff7350;
}
.call-area h2 {
    color: #fff;
    font-size: 58px;
    margin-bottom: 30px;
}
.call-area h2 span{
    color: #ff7350;
}
.call-area p{
    color: #fff;
    opacity: 0.9;
}
.number {
    font-size: 55px;
    font-weight: 600;
    color: #ff7350;
}


/* Shop */
.shop-area select {
    padding: 10px;
}
.product__img {
  position: relative;
}
.product__img img {
	width: 100%;
}
.product:hover .product-action a {
  margin: 0 5px;
  opacity: 1;
  visibility: visible;
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.product-action a {
	display: inline-block;
	background: #ffffff;
	line-height: 40px;
	color: #a39fb4;
	margin: 0 8px;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
	padding: 0 20px;
}

.product-action a:hover {
  background: #ff7350;
  color: #ffffff;
}

.pro-cat {
  margin-bottom: 15px;
  display: block;
}

.pro-cat a {
  color: #ff7350;
  font-size: 14px;
  letter-spacing: 2px;
  
  text-transform: uppercase;
}

.pro-title {
  margin-bottom: 12px;
}

.pro-title a {
  font-size: 24px;
}

.pro-title a:hover {
  color: #ff7350;
}

.price span {
  color: #595B6B;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px;
}

.price span.old-price {
  color: #b5b5b5;
  text-decoration: line-through;
}

.product-showing p {
  margin: 0;
  border: 2px solid #eaedff;
  padding: 17px 30px;
  text-align: center;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .product-showing p {
    padding: 17px 15px;
  }
}

.pro-filter {
  position: relative;
  display: inline-block;
}

@media (max-width: 767px) {
  .pro-filter {
    float: left;
  }
}
.img, img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
.pro-filter select {
  border: 2px solid #eaedff;
  padding: 17px 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 1;
  color: #6f7172;
  appearance: none;
  -moz-appearance: none;
  width: 145px;
  font-weight: 500;
}
.pro-filter .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	border-radius: 0;
	border: solid 1px #e8e8e8;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 50px;
	line-height: 48px;
	outline: none;
	padding-left: 18px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	width: auto;
}
.pro-filter::before {
  content: "\f107";
  right: 15px;
  top: 15px;
  position: absolute;
  color: #758799;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.shop-tab ul li {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .shop-tab ul li {
    margin-left: 10px;
  }
}

.shop-tab ul li a {
	font-size: 14px;
	font-weight: 500;
	color: #6f7172;
	letter-spacing: 2px;
	padding: 0;
	text-transform: uppercase;
	position: relative;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background: #f6f6ff;
	line-height: 51px;
	text-align: center;
}

.shop-tab ul li a.active {
  color: white;
  background: #8fb569;
}

.shop-thumb-tab {
  width: 160px;
  float: right;
}

@media (max-width: 767px) {
  .shop-thumb-tab {
    float: none;
    width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .shop-thumb-tab ul {
    margin: 0 -5px;
  }
}

.shop-thumb-tab ul li {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .shop-thumb-tab ul li {
    width: 33.33%;
    float: left;
    padding: 0 5px;
  }
}

.shop-thumb-tab ul li a {
  padding: 0;
}

.product-details-img {
  margin-right: 180px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .product-details-img {
    margin-left: 0;
  }
}

.product-details-title p {
  color: #6a667b;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.product-details-title h1 {
  font-size: 40px;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -2px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .product-details-title h1 {
    font-size: 36px;
  }
}
.details-price span {
	color: #ff7350;
	font-size: 20px;
	font-weight: 400;
	margin-left: 0;
	margin-right: 10px;
}

.details-price {
  border-bottom: 2px solid #eaedff;
}

.product-cat span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #100d1c;
}

.product-cat a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
}

.product-cat a:hover {
  color: #8fb569;
}

.product-social a {
  margin-right: 10px;
  background: #f5f5ff;
  height: 50px;
  width: 50px;
  line-height: 48px;
  border-radius: 30px;
  color: #6f7172;
  display: inline-block;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 767px) {
  .product-social a {
    margin-bottom: 10px;
  }
}

.product-social a:hover {
  background: #8fb569;
  color: #ffffff;
}

.plus-minus {
  display: inline-block;
}

@media (max-width: 767px) {
  .plus-minus {
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .plus-minus {
    display: inline-block;
    margin-bottom: 0;
  }
}

.action-btn {
	background: #8fb569;
	padding: 20px 25px;
	border: none;
	margin-left: 15px;
	color: #ffffff;
	display: inline-block;
}

.action-btn:hover {
  background: #ff7350;
  color: #ffffff;
}
.product-action-list {
	overflow: hidden;
}
.product-action-list a {
  float: left;
}

@media (max-width: 767px) {
  .product-action-list a.btn {
    padding: 23px 30px;
    margin-bottom: 7px;
  }
}

@media (max-width: 767px) {
  .product-action-list a.action-btn {
    margin-left: 5px;
    margin-bottom: 7px;
  }
}

.cart-plus-minus input {
  height: 60px;
  width: 100px;
  border: 0;
  border: 2px solid #eaedff;
  text-align: center;
  -moz-appearance: none;
  appearance: none;
}

.cart-plus-minus {
  display: inline-block;
  position: relative;
  margin-right: 15px;
}

.cart-plus-minus .qtybutton {
  position: absolute;
  top: 15px;
  left: 17px;
  font-size: 20px;
  color: #c4bedd;
  cursor: pointer;
}

.cart-plus-minus .inc {
  left: auto;
  right: 17px;
}

.additional-info .table td, .additional-info .table th {
  padding: .75rem 0;
  vertical-align: top;
  border-top: 1px solid #e4e4e4;
  font-weight: 400;
}

.shop-cat a {
  padding: 45px 30px;
  border: 2px solid #eaedff;
  display: block;
}

.shop-cat a i {
  font-size: 100px;
  color: #8fb569;
  margin-bottom: 30px;
  transition: .3s;
}

.shop-cat a h4 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  transition: .3s;
  letter-spacing: 1px;
}

.shop-cat a:hover {
  background: #8fb569;
  border-color: #8fb569;
}

.shop-cat a:hover i {
  color: #ffffff;
}

.shop-cat a:hover h4 {
  color: #ffffff;
}

.product-list-content .price span:first-child {
  margin-left: 0;
}

.bakix-details-tab ul {
  border-bottom: 2px solid #e1e1e1;
}

.bakix-details-tab ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #141b22;
  letter-spacing: 2px;
  padding: 0;
  text-transform: capitalize;
  position: relative;
  padding: 0 25px;
}

@media (max-width: 767px) {
  .bakix-details-tab ul li a {
    padding: 0 10px;
  }
}

.bakix-details-tab ul li a.active {
  color: #ff7350;
}

.bakix-details-tab ul li a.active:before {
  position: absolute;
  bottom: -32px;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: #ff7350;
  transition: .3s;
}

@media (max-width: 767px) {
  .bakix-details-tab ul li a.active:before {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bakix-details-tab ul li a.active:before {
    display: block;
  }
}

/* Cart */
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: #ff7350;
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th, .table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.product-quantity input {
	border: none;
	color: #6f7172;
	font-size: 14px;
	font-weight: normal;
	border: 0;
}

.table td, .table th {
	border-top: 1px solid #eaedff;
}

.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
	height: 62px;
	border: 2px solid #eaedff;
	padding: 0 15px;
	margin-right: 10px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}

.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}

.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
	font-size: 25px;
	margin-bottom: 20px;
	text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #eaedff;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}
td.product-thumbnail img {
	width: 125px;
}

/*Checkout */
.coupon-accordion h3 {
	background-color: #fff1f0;
	border-top: 3px solid #ff7350;
	font-size: 14px;
	font-weight: 400;
	margin: 0 0 25px;
	padding: 1em 2em 1em 3.5em;
	position: relative;
	width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: .3s;
}

.coupon-accordion span:hover, p.lost-password a:hover {
  color: #ff7350;
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 600;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
}

.coupon-info p.form-row input[type="submit"]:hover, p.checkout-coupon input[type="submit"]:hover {
  background: #ff7350none repeat scroll 0 0;
}

.coupon-info p.form-row input[type="checkbox"] {
  position: relative;
  top: 2px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type="submit"], .buttons-cart a, .coupon-info p.form-row input[type="submit"] {
  background: #141b22 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type="text"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: red;
}

.country-select select {
  -moz-appearance: none;
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
}

.country-select::before {
  content: "\f107";
  right: 15px;
  top: 38px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type="text"], .checkout-form-list input[type="password"], .checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
}

.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 90px;
  padding: 15px;
  width: 100%;
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
	padding: 30px 40px 45px;
	border: 3px solid #eaedff;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}

.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}

.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: #ff7350;
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #FE4536 none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}
.country-select .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	border-radius: 0;
	border: solid 1px #eaedff;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 45px;
	line-height: 45px;
	outline: none;
	padding-left: 18px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	width: 100%;
	margin-bottom: 19px;
}

/* 24. Login */
.zcube-login {
  padding: 90px;
  border: 2px solid #eaedff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .zcube-login {
    padding: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .zcube-login {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .zcube-login {
    padding: 30px;
  }
}

.zcube-login h3 {
  font-size: 30px;
}

.zcube-login input {
  width: 100%;
  height: 60px;
  border: 2px solid #eaedff;
  color: #6f7172;
  padding: 0 20px;
  margin-bottom: 20px;
}

.zcube-login label {
  color: #222;
  display: block;
}

.zcube-login label span {
  color: #fe4536;
}

.login-action input {
  width: inherit;
  height: auto;
}

.login-action label {
  display: inline-block;
  margin-left: 5px;
}

.or-divide {
  border-top: 2px solid #eaedff;
  margin: 40px 0;
  text-align: center;
  position: relative;
}

.or-divide span {
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  background: #ffffff;
  display: inline-block;
  width: 40px;
  margin: auto;
  line-height: 1;
  font-weight: 500;
}

@media (max-width: 767px) {
  .forgot-login {
    float: none;
  }
}

.forgot-login a {
  color: #fe4536;
}

.forgot-login a:hover {
  color: #84b77c;
}

@media (max-width: 767px) {
  .log-rem {
    float: none;
    margin-bottom: 10px;
    display: block;
  }
}

/*Services Category*/
.services-sidebar .sidebar-widget {
  background: #f3f4f8;
  padding: 40px;
margin-bottom: 30px;
}

.services-categories{
	position:relative;
}

.services-categories li{
	position:relative;
	border:1px solid #e7e7e7;
    margin-bottom: 15px;    
	z-index: 1;
}

.services-categories li:last-child{
	border-bottom: 1px solid #e7e7e7;
}

.services-categories li:before{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: #30313d;
    content: "";
    opacity: 0;
    visibility: hidden;
}

.services-categories li.active:before,
.services-categories li:hover:before{
	opacity: 1;
	visibility: visible;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.services-categories li a{
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    line-height: 30px;
    padding: 10px 25px;
    display: block;
    overflow: hidden;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
    background: #fff;
}
.services-categories li a:hover{
    background: #ff7350;
}
.services-categories li.active a,
.services-categories li:hover a{
	color: #ffffff;
    background: #ff7350;
}

.services-categories li a:before{
position: absolute;
right: 0;
top: 0;
font-size: 12px;
line-height: 30px;
color: #222222;
font-weight: 600;
content: "\f061";
font-family: "Font Awesome 5 pro";
transition: all 300ms ease;
width: 50px;
height: 50px;
background: #E8EAEC;
    text-align: center;
    line-height: 50px;
}

.services-categories li.active a:before,
.services-categories li:hover a:before{
	color: #ffffff;
}
.services-categories li:hover a::before,.services-categories li.active a::before{
    background: #125875;
}


/*=== Services Contact Box ===*/
.service-detail-contact h3 {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.service-detail-contact {
  background-color: #f3f4f8;
  padding: 40px;
  text-align: center;
  margin-top: 50px;
    margin-bottom: 50px;
}
.service-detail-contact h3::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 6px;
  background-color: #ff7350;
  border-radius: 10px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
}
.service-detail-contact a {
color: #ff7350;
font-size: 25px;
font-weight: 900;
transition: 0.3s;
}
.service-details-three h2 {
  color: #fff;
}
/*=== Brochures Box ===*/

.brochures-box .box{
display: flex;
flex-wrap: wrap;
align-items: center;
background: #fff;
transition: 0.3s;
}
.brochures-box .box:hover {
  background-color: #1A1E5D;
    color: #fff;
}
.brochures-box .box:hover h4,.brochures-box .box:hover i{
    color: #fff;
}
.brochures-box .icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex: 0 0 60px;
  -ms-flex: 0 0 60px;
  max-width: 60px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
    background: #ff4328;
}
.brochures-box .icon i {
  font-size: 25px;
  color: #fff;
  transition: .3s;
}
.brochures-box  .content {
  padding-left: 20px;
}

/*Help Box*/

.help-box{
	position:relative;
	background-size:cover;
	padding:30px 25px;
}

.help-box:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(54,54,54,0.90);
}

.help-box > span{
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-bottom: 10px;
}

.help-box h4{
	position:relative;
	color:#ffffff;
	font-size:21px;
	font-weight:700;
	line-height:1.2em;
	margin-bottom:15px;
}

.help-box p{
	position:relative;
	color:#ffffff;
	font-size:14px;
	line-height:24px;
	margin-bottom:20px;
}

.help-box .theme-btn{
	padding: 8px 30px;
	font-size: 14px;
	font-weight: 600;
}


/*** 

====================================================================
		Service Detail
====================================================================

***/

.service-detail{
	position: relative;
	padding-left: 20px;
}

.service-detail .images-box{
	position: relative;
	margin: 0 -5px;
}

.service-detail .images-box .column{
	padding: 0 5px;
}

.service-detail .images-box .image{
	position: relative;
	margin-bottom: 10px;
}

.service-detail .images-box .image img{
	display: block;
	width: 100%;
	height: auto;
}

.service-detail .content-box{
	position: relative;
	padding: 0px 0 0;
}

.service-detail .content-box h2{
	position: relative;
	display: block;
	font-size: 32px;
	line-height: 1.2em;
	color: #141b22;
	margin-bottom: 20px;
}

.service-detail .content-box h3{
	position: relative;
	display: block;
	font-size: 24px;
	line-height: 1.2em;
	color: #141b22;
	margin-bottom: 20px;
}

.service-detail .content-box p{
	position: relative;
	display: block;
	margin-bottom: 25px;
}

.service-detail .two-column{
	position: relative;
}

.service-detail .two-column .title{
	margin-bottom: 40px;
}

.service-detail .two-column p{
	margin-bottom: 25px;
}

.service-detail .two-column .image-column{
	position: relative;
	margin-bottom: 26px;
}

.service-detail .two-column .image-column .image{
	margin-bottom: 0;
}

.service-detail .two-column .text-column{
	position: relative;
}

.service-detail .prod-tabs{
	position:relative;
}

.service-detail .prod-tabs .tab-btns{
	position:relative;
	z-index:1;	
	border-bottom:1px solid #dddddd;
}

.service-detail .prod-tabs .tab-btns .tab-btn{
	position:relative;
	top:1px;
	display:block;
	float:left;
	margin-right:5px;
	font-size:16px;
	color:#333333;
	background:#ffffff;
	text-transform:capitalize;
	font-weight:400;
	line-height:23px;
	cursor:pointer;
	border:1px solid #e5e5e5;
	border-bottom: 0;
	padding: 10px 25px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	top: 0;
	left: 50%;
	width:0%;
	height:3px;
	background-color:#ff7350;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover:before,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn:before{
	width: 100%;
	left: 0;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover:after,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:-3px;
	width:100%;
	height:5px;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.service-detail .prod-tabs .tab-btns .tab-btn:hover,
.service-detail .prod-tabs .tab-btns .tab-btn.active-btn{
	color: #ff7350;
    background: #ffffff;
}

.service-detail .prod-tabs .tabs-content{
	position:relative;
	padding:25px 30px 45px;
	border:1px solid #dddddd;
}

.service-detail .prod-tabs .tabs-content .tab{
	position:relative;
	display:none;
	border-top:0px;
}

.service-detail .prod-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.service-detail .prod-tabs .tabs-content .tab .content{
	position:relative;
}

.service-detail .prod-tabs .tabs-content .tab .content p{
	position:relative;
	font-size:16px;
	line-height: 26px;
	color:#666666;
	margin-bottom:0px;
}
.list-style-one {
    position: relative;
    display: block;
    margin-bottom: 30px;
}
.list-style-one li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
}
.list-style-one li::before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    line-height: 25px;
    color: #ff7350;
    content: "\f101";
    font-weight: 600;
    font-family: "Font Awesome 5 Pro";
}

/*** 

====================================================================
		Project Detail
====================================================================

***/

.project-detail{
	position: relative;
	padding: 120px 0 120px;
}

.project-detail .upper-box{
	position: relative;
	margin-bottom: 40px;
} 

.project-detail .upper-box .image{
position: relative;
margin-bottom: 0;
overflow: hidden;
height: 550px;
}

.project-detail .upper-box .image img{
	display: block;
	width: 100%;
	height: auto;
}

.project-detail .single-item-carousel .owl-nav{
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	margin-top: -50px;
}

.project-detail .single-item-carousel .owl-next,
.project-detail .single-item-carousel .owl-prev{
	position: absolute;
	left: 0;
	height: 100px;
	width: 30px;
	background-color: rgba(0,0,0,0.5);
	text-align: center;
	font-size: 18px;
	line-height: 100px;
	color: #ffffff;
	font-weight: 400;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.project-detail .single-item-carousel .owl-next{
	left: auto;
	right: 0px;
}

.project-detail .single-item-carousel .owl-next:hover,
.project-detail .single-item-carousel .owl-prev:hover{
	background-color: rgba(255,255,255,.5);
	color: #000000;
}

.project-detail .text-column{
	position: relative;
}

.project-detail .text-column .inner-column{
	position: relative;
}
.project-detail .text-column .inner-column .dropcap{
    float: left;
    background: #ff7350;
    border-radius: 20px;
    padding: 30px;
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 600;
    margin-right: 20px;
}
.project-detail .text-column .inner-column p strong{
    color: #141b22;
}

.project-detail .pr-ul {
    margin-top: 10px;
    float: left;
    margin-bottom: 30px;
}
.project-detail .pr-ul li {
    display: flex;
    margin-bottom: 15px;
    float: left;
width: 50%;
    align-items: center;
}
.project-detail .pr-ul .icon i {
    width: 40px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #EEEEEE;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 20px;
    color: #ff7350;
}


/* Lower Content */
.project-detail .leactures li a{
  display: flex;
    margin-bottom: 15px;
    color: #777;
}
.project-detail .leactures li:last-child a{
    margin-bottom: 0;
}
.project-detail .leactures li a:hover{
    color: #000;
}
.project-detail .leactures li .text i{
    margin-right: 5px;
}
.project-detail .leactures li .text{
    font-weight: 600;
    font-size: 16px;
}
.project-detail .leactures li .time{
 text-align: right;
}
.project-detail .faq-wrap2 .faq-btn span {
  float: right;
  font-weight: 400;
  font-size: 15px;
}
.project-detail .faq-wrap2 .faq-btn{
     border: 2px solid #f7f8fc !important;
    background: #f7f8fc;
    padding: 15px 30px 15px 40px;
    font-weight: 600;
    
}
.project-detail .faq-wrap2 .card-header h2 button::after{
    right: inherit;
    left: 10px;
    width: auto;
    height: auto;
    border: none;
    line-height: 47px;
    color: #777;
    font-weight: 400;
}
.project-detail .faq-wrap2 .faq-btn span{
    float: right;
}
.project-detail .faq-wrap2 .card {
  margin-bottom: 15px;
  border: 2px solid #f7f8fc !important;
}
.project-detail .faq-wrap2 .card-body{
    padding: 30px;
}
.project-detail .leactures li div {
  width: 100%;
}
.project-detail .lower-content{
	position: relative;
}

.project-detail .lower-content h2{
position: relative;
display: block;
margin-bottom: 50px;
font-size: 50px;
}

.project-detail .lower-content h3{
    position: relative;
    display: block;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

.project-detail .lower-content h4{
    position: relative;
    display: block;
    font-size: 22px;
    margin-bottom: 20px;
}

.project-detail .lower-content p{
	position: relative;
    display: block;
    margin-bottom: 20px;
}


.project-detail .info-column{
	position: relative;
}

.project-detail .info-column .inner-column{
  position: relative;
  padding: 30px;
  background: #fff;
  border: 2px solid #f5f5f5 !important;
}
.project-detail .info-column .inner-column2{
border-radius: 8px;
background-color: rgb(255, 255, 255);
box-shadow: 5px 8.66px 58px 0px rgba(0, 0, 0, 0.08);
overflow: hidden;
padding-bottom: 30px;
margin-top: -153px;
border: 1px solid #e3e3e3;
position: sticky;
top: 67px;
}
.project-detail .info-column .inner-column2 h3{
    background: #125875;
    color: #fff;
    font-size: 20px;
    padding: 15px;
    margin-bottom: 15px;
}
.project-detail .info-column .inner-column3{
border-radius: 8px;
background-color: rgb(255, 255, 255);
box-shadow: 5px 8.66px 58px 0px rgba(0, 0, 0, 0.08);
overflow: hidden;
padding-bottom: 30px;
border: 1px solid #e3e3e3;
position: sticky;
top: 67px;
}
.project-detail .info-column .inner-column3 .footer-social a{
    background: #125875;
}
.project-detail .info-column .inner-column3 .footer-social a:hover{
    background: #ff7350;
}
.project-detail .info-column .inner-column3 h3{
    background: #125875;
    color: #fff;
    font-size: 20px;
    padding: 15px;
    margin-bottom: 15px;
}

.project-detail .project-info{
	position: relative;
}
.project-detail .project-info li .btn{
    width: 100%;
    color: #fff;
}
.project-detail .project-info li .slider-btn {
  width: 100%;
}
.project-detail .project-info li{
position: relative;
margin-bottom: 15px;
float: left;
width: 100%;
border-bottom: 1px solid #e3e3e3;
padding-bottom: 15px;
display: flex;
align-items: center;
gap: 15px;
padding-left: 15px;
padding-right: 15px;
}
.project-detail .project-info h5 {
  float: left;
}

.project-detail .project-info li:last-child {
  padding-bottom: 0;
  border-bottom: none;
    margin-bottom: 0;
}
.project-detail .project-info li .icon{
	font-size: 18px;
	line-height: 28px;
	color:#ff7350;
}

.project-detail .project-info li strong{
color: #141b22;
line-height: 24px;
display: inline-block;
    font-weight: 600;
    font-family: 'Jost', sans-serif;
}
.project-detail .project-info .priceing{
    padding: 10px 0;
    position: relative;
    width: 100%;
}
.project-detail .project-info .priceing strong{
    font-family: 'Roboto', sans-serif;
    color:#ff7350;
    font-size: 24px;
}
.project-detail .project-info .priceing sub{
    bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-decoration:line-through;
}
.project-detail .project-info .priceing span{
border-radius: 5px;
position: absolute;
background: #ff73503d;
padding: 1px 12px;
font-size: 14px;
color: #ff7350;
top: 6px;
margin-left: 15px;
font-weight: 600;
}


.project-detail .project-info li p{
	font-size: 15px;
    line-height: 24px;
    
    font-weight: 400;
    margin-bottom: 0;
}

.project-detail .project-info li p a{
	
	display: inline-block;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.project-detail .project-info li p a:hover{
	color: #ff7350;
}
.project-detail .lower-content th {
  background: #ff7350;
  color: #fff;
}
/* Load More Option */
.load-more-option{
	position: relative;
	display: block;
	width: 100%;
	text-align: center;
	margin-top: 70px;
}

.load-more-option li.prev a,
.load-more-option li.next a{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 20px;
	width: 80px;
	padding: 10px 10px;
	text-align: center;
	color: #ffffff;
	background-color: #ff7350;
	border-radius: 10px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.load-more-option li.prev a:hover,
.load-more-option li.next a:hover{
	box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.load-more-option .load-more{
	position: relative;
	display: inline-block;
	font-size: 30px;
}

.load-more-option .load-more a{
	position: relative;
	display: inline-block;
	font-size: 30px;
	line-height: 42px;
	color: #ff7350;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.load-more-option .load-more a:hover{
	color: #000000;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.video2 {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: -250px;
    width: auto;
    z-index: 0;
}
/* 8. newslater-area */
.newslater-area h2{
    color: #fff;
   font-size: 45px;
}
.newslater-area p{
    color: #fff;
     width: 100%;
    margin-bottom: 0px;
}
.newslater .input-bg{
    background: #3A3D3E;
    border-radius: 30px !important;
    float: left;
    padding-right: 5px;
    position: relative;
    z-index: 3;
    width: 40%;
}
.newslater .input-bg2{
background: #3A3D3E;
border-radius: 30px !important;
float: left;
padding-right: 5px;
position: relative;
z-index: 2;
margin-left: -35px;
width: 40%;
}
.newslater input {
background: #fff !important;
border: 1px solid #FFF !important;
box-shadow: none !important;
padding: 9px 20px !important;
color: #000 !important;
height: calc(2em + .75rem + 20px);
width: 100% !important;
border-radius: 0px !important;
position: relative;
z-index: 1;
margin-right: 7px;
border-radius: 4px !important;
    
}
.newslater .form-group{
    margin-bottom: 0;
}
.newslater button {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    color: #000;
}
.newslater button:hover {
    background: #ff3494;
    color: #fff;
}

.newslater *::-moz-placeholder {
	color: #939393;
	font-size: 14px;
    opacity: 0.8;
	opacity: 1;
}
.newslater *::placeholder {
	color: #939393;
    opacity: 0.8;
	font-size: 14px;
	opacity: 1;
}
.newslater-area #contact-form4 {
    float: left;
    width: 95%;
    margin-bottom: 30px;
}
.newslater-area #contact-form4 button {
border-radius: 4px;
background: #125875;
color: #fff;
height: 55px;
padding: 0 30px;
z-index: 1;
text-align: center;
margin-top: 4px;
margin-right: 4px;
border: none;
}
.newslater-title{
    display: flex;
}
.newslater-title .icon{
  margin-right: 30px;
line-height: 74px;
}
.newslater-title .text h2{
    font-size: 40px;
}
.slick-slide{outline: none;}


.features-area h5{
    margin-bottom: 15px;
}
.features-area ul{
    margin-top: 30px;
    color: #fff;
    display: inherit;
}
.features-area li{
    display: flex;
    margin-bottom: 15px;
}
.features-area li .icon {
    margin-right:20px;
    color: #84DAA9;
}
.features-area .nav-pills .nav-link{
    width: 100%;
    font-size: 24px;
        font-family: 'Jost', sans-serif;
    font-weight: 600;
}
.features-area .nav-pills .nav-link img{
    margin-right: 15px;
}
.features-area .nav-pills .nav-link.active, .features-area .nav-pills .show > .nav-link{
      border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    color: #141b22;
}
.feature-text h3 {
    font-size: 48px;
}
.fe-img{
    margin: 0 -40px;
}
/* 8. search-popup */
.search-popup {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	box-shadow: 0 0 0;
	margin-top: 200px;
	text-transform: uppercase;
}
.search-bg {
	background: #f5f5f5;
	opacity: 1;
}
.close2 {
	color: #ff7350;
	cursor: pointer;
	font-size: 14px;
}
.search-bg a i {
	color: #ff7350;
	font-size: 18px;
}
.search-outer {
    border-bottom: 1px solid #141b22;
    float: left;
    margin-top: 100px;
    padding-bottom: 16px;
    width: 100%;
}
.search-outer input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #000;
    font-size: 14px;
    width: 100%;
}

.case-study-content {
   padding: 30px;
background: #FFF5F4 0% 0% no-repeat padding-box;
box-shadow: 0px 16px 32px #FFF5F4;
}
.case-study-content span{
   margin-bottom: 10px;
display: inline-block;
}
.case-study-content02 {
    padding: 30px 30px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 32px #141b220A;
}
.case-study-content02 span{
   margin-bottom: 10px;
display: inline-block;
    color: #ff7350;
}
.case-study-content02 .ss-btn{
    display: none;
    transition: all 0.5s ease 0s;
}

.case-study-box:hover .case-study-content02 .ss-btn{
  display: inline-block;
} 
.case-study-box .case-study-content02{
    transition: all 0.5s ease 0s;
}
.case-study-box:hover .case-study-content02 {
    transform: translate(0,-62px);
}
/* 24. process-area */
.process-area h2{
    color: #fff;
}
.process-area .section-title h5{
     color: #ff7350;
}
.process-area .col-lg-3.col-md-12:nth-child(2),.process-area .col-lg-3.col-md-12:nth-child(4) {
    margin-top: 50px;
}
.process-area .col-lg-3.col-md-12:nth-child(2) .process-icon,.process-area .col-lg-3.col-md-12:nth-child(2) .no{
    background: #ff73500% 0% no-repeat padding-box;
}
.process-area .col-lg-3.col-md-12:nth-child(3) .process-icon,.process-area .col-lg-3.col-md-12:nth-child(3) .no{
    background: #27ae61 0% 0% no-repeat padding-box;
}
.process-area .col-lg-3.col-md-12:nth-child(4) .process-icon,.process-area .col-lg-3.col-md-12:nth-child(4) .no{
    background: #8145DA 0% 0% no-repeat padding-box;
}
.process-area  h5{
    color: #fff;
}
.process-icon{
 width: 180px;
height: 180px;
line-height: 180px;
background: #4CC3C1 0% 0% no-repeat padding-box;
text-align: center;
border-radius: 50%;
margin-bottom: 30px;
position: relative;
display: inline-block;
}
.process-area .no {
    position: absolute;
    top: 0;
    line-height: 40px;
    right: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    background: #4CC3C1 0% 0% no-repeat padding-box;
    border: 4px solid #141b22;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 600;
}

/* 24. skills-area */
.team-area-content .skill-name {
  color: #141b22;
}
.skills {
  width: 100%;
  padding: 0 20px 0 0;
}
.skills-img {
  margin-right: -322px;
}
.skills-content p{
    color: #fff;
}
.skill-name {
font-size: 18px;
font-weight: 600;
margin: 0px 0 15px;
color: #fff;
font-family: 'Jost', sans-serif;
    
}
.skill-bar {
height: 10px;
background: #e0e0e0;
border-radius: 0;
}
.skill-per {
height: 10px;
background-color: #ff7350;
border-radius: 0;
width: 0;
position: relative;
transition: 1s linear;
}
.skill-per::before {
  content: attr(id);
  position: absolute;
  padding: 4px 6px;
  background-color: #ff7350;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  top: -43px;
  right: 0;
  transform: translateX(50%);
}
.skill-per::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ff7350;
  top: -16px;
  right: 0;
  transform: translateX(50%) rotate(45deg);
  border-radius: 2px;
}

.skills-title h5 {
   color: #ff7350;
font-size: 18px;
text-transform: uppercase;
margin-bottom: 10px;
}
.skills-title h2 {
  color: #fff;
    font-size: 70px;
}
.progress-outer li{
    padding-left: 100px;
}
.progress-outer li:first-child{
    text-align: right;
      padding-right: 50px;
    margin-bottom: -50px;
}
.progress-outer li:last-child{
    text-align: right;
      padding-right: 50px;
    margin-top: -50px;
}
.progress-box {
  display: inline-block;
}
 .progress{
    width: 235px;
    height: 235px;
    line-height: 235px;
    background: none;
    box-shadow: none;
    position: relative;
}
.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #e1e1e1;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 101%;
    border-top-right-radius: 130px;
    border-bottom-right-radius: 130px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress .progress-right{
    right: 0;
}
.progress .progress-right .progress-bar{
    left: -101%;
    border-top-left-radius: 130px;
    border-bottom-left-radius: 130px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}
.progress .progress-value{
width: 90%;
height: 90%;
border-radius: 50%;
background: none;
font-size: 48px;
font-weight: 800;
color: #ff7350;
line-height: 135px;
text-align: center;
position: absolute;
top: 15%;
left: 5%;
}
.progress .progress-value sub{
    font-size: 20px;
    color: #ff7350;
    margin-left: 5px;
    font-weight: 400;
}
.progress p{
font-size: 16px;
line-height: inherit;
color: #777777;
font-weight: inherit;
width: 100%;
text-align: center;
      margin-top: 25px;
}
.progress.blue .progress-bar{
    border-color: #ff7350;
}
.progress.blue .progress-left .progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
}
.progress.yellow .progress-bar{
    border-color: #fdba04;
}
.progress.yellow .progress-left .progress-bar{
    animation: loading-3 1s linear forwards 1.8s;
}
.progress.pink .progress-bar{
    border-color: #ed687c;
}
.progress.pink .progress-left .progress-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
}
.progress.green .progress-bar{
    border-color: #1abc9c;
}
.progress.green .progress-left .progress-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
}
@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}
@keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}
@keyframes loading-5{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}
@media only screen and (max-width: 990px){
    .progress{ margin-bottom: 20px; }
}

.conunter-img {
    margin-left: -400px;
}

/* 27. map-area */
.map-img li {
    position: absolute;
    display: inline-block;
    z-index: 999;
    top: 93px;
    left: 0;
}
.map-img li:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 45%;
}
.map-img li:nth-child(2) .map-text-hover{
    left: -520px;
}
.map-img li:nth-child(3) {
    position: absolute;
    top: 100px;
    left: 85%;
}
.map-img li:nth-child(3) .map-text-hover{
    left: -520px;
}
.map-img li:nth-child(4) {
    position: absolute;
    top: 40%;
    left: 15%;
}
.map-img li:nth-child(4) .map-text-hover{
      left: 50px;
}
.map-img li:nth-child(5) {
    position: absolute;
    top: 43%;
    left: 70%;
}
.map-img li:nth-child(5) .map-text-hover{
    left: -520px;
}
.map-text-hover{
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.15);
  position: absolute;;
  width: 510px;
  height: 133px;
  z-index: 138;
    top: 0;
    left: 50px;
    padding: 30px;
    display: none;
}
.map-img li:hover .map-text-hover{
    display: block;
}
.map-content {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 60px 0px rgba(40, 40, 40, 0.04);
    margin-top: -150px;
    position: relative;
}
.map-text-user {
    display: flex;
}

.map-text-user .text{
    padding: 30px 30px 0px 30px;
}
.map-text-user .img img {
    max-width: initial;
    height: 152px;
}
.map-line {
    height: 100%;
    width: 5px;
    background: #27ac60;
    position: absolute;
    left: 50%;
}
.map-cirl {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 146;
    border: 5px solid #27ac60;
    text-align: center;
    line-height: 50px;
    color: #27ac60;
    left: 47.4%;
    top: 43px;
}


.contact-area .container{
    position: relative;
    z-index: 1;
}
.contact-bg02 .slider-btn {
    width: 100%;
}

.contact-bg02 .btn.ss-btn.active {
    background: #ff7350;
    border: 2px solid #ff7350;
    color: #fff;
}
.contact-field select {
    width: 100%;
    border: none;
    background-color: rgb(255, 255, 255);
    transition: .3s;
    border-radius: 10px;
    border: 1px solid rgb(183, 183, 183);
    height: calc(2.5em + .75rem + 2px);
    padding: 15px;
    color: #777;
}
.contact-field .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgb(225, 225, 225);
}
.contact-field .form-control {
    color: #9e9e9e;
}
.contact-bg-an-01 {
    position: absolute;
    left: 9.8%;
    top: 25%;
     animation: alltuchtopdown 3s infinite;
      -webkit-animation: alltuchtopdown 3s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 1s;
}
.contact-bg-an-02 {
    position: absolute;
    right: 3%;
    bottom: 7%;
     animation: alltuchtopdown 5s infinite;
      -webkit-animation: alltuchtopdown 5s infinite;
      animation-delay: 0s;
      -webkit-animation-delay: 3s;
}


@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }
@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }


/* Here's the new blog dtl to add... */

.quote-post {
	background-position: center;
	background-repeat: no-repeat;
}
.quote-post .quote-icon {
    float: left;
    margin-right: 30px;
    display: block;
	margin-top: 20px;
}
.quote-post h2 {
    overflow: hidden;
	margin-bottom: 0;
}
.blog-deatails-box.single .bsingle__post {
    margin-bottom: 0;
}

.sidebar-widget .widget table{
	width:100%;
	padding:10px;
	background:#f5f5f5;
}
.sidebar-widget .widget th,.footer-widget .widget th
{
background: #ff7350;
padding: 10px 5px;
text-align: center;
color: #fff;
}
.sidebar-widget .widget td
{
	padding:5px;
	text-align:center;
}
.sidebar-widget .widget {
padding: 0px 40px 10px;
overflow: hidden;
margin-bottom: 40px;
border: none !important;
background: #fff;
}
.sidebar-widget .widget li {
    padding-bottom: 5px;
	border-bottom: 1px solid #e4e4e4;
	padding-top: 5px;
	float: left;
	width: 100%;
}
.widget-title {
  	margin-bottom: 40px;
}
.sidebar-widget .widgettitle, .sidebar-widget .widget-title, .wp-block-group__inner-container h2{
    font-size: 20px;
margin-bottom: 0;
line-height: 1;
text-align: left;
font-weight: 300;
margin-bottom: 30px;
border-bottom: 1px solid #e1e1e1;
padding-left: 0;
padding-bottom: 20px;
position: relative;
}
.sidebar-widget .widgettitle::after, .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  background: #ff7350;
  height: 1px;
  width: 50px;
  left: 0;
  bottom: -1px;
}
.sidebar-widget .widget .gallery-icon a:hover
{
	padding-left:0;
}

.tags {
    border-bottom: 1px solid #eaeaea;
	/*float:left;*/
	width:100%;
	display: inline-block;
}
.wp-block-search .wp-block-search__label {
    display: none;
}
.search-form, .wp-block-search__button-outside.wp-block-search__text-button.wp-block-search {
  	position: relative;
}
.search-form label {
  width: 100%;
}
.search-form input, .wp-block-search .wp-block-search__input{
	background: #f5f5f5;
	border: none;
	width: 100%;
	padding: 18px 30px;
}
.search-form input::placeholder {
	font-size: 12px;
	color: #b0b0b0;
}
.search-form .search-submit, .wp-block-search .wp-block-search__button {
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	background:#ff7350;
	padding: 18px 23px;
	color: #ffffff;
	font-size: 14px;
	cursor: pointer;
	min-height: 88%;
	width: 106px;
}
.sidebar-widget .widget .widget_archive,.widget_categories,.sidebar-widget .widget_archive{
	text-align:right;
}
.sidebar-widget .widget .widget_archive,.sidebar-widget .widget_categories a,.sidebar-widget .widget_archive a{
	float:left;
	height: 30px;
}
.widget-insta-post li {
	display: inline-block;
	margin: 5px 3px;
}
.widget-insta-post li:hover a::before {
	opacity: .7;
}
.widget-insta-post li a {
    position: relative;
	display: block;
}
.widget-insta-post li a::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background:#ff7350;
	opacity: 0;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.widget-social a {
	height: 40px;
	width: 40px !important;
	line-height: 40px !important;
	border-radius: 50%;
	display: inline-block;
	background: transparent;
	border: 1px solid #e4e4e4;
color: #6e6e6e;
	margin: 0 3px;
	text-align: center !important;
}
.widget-social a:hover {
	background:#ff7350;
	color: #ffffff !important;
	border-color:#ff7350;
	padding: 0 !important;
	box-shadow: 0px 10px 19px 0px #ff735066 !important;
}
.rpwwt-widget a{
	padding-left: 0 !important;
}
.rpwwt-widget li {

    line-height: 23px !important;

}
.cat__list li {
	padding-bottom: 10px;
	border-bottom: 1px solid #e4e4e4;
	margin-bottom: 10px;
}
.cat__list li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
	border: none;
}
.cat__list li:hover a {
	color:#ff7350;
}
.cat__list li a {
    font-size: 14px;
	color: #777777;
}
.cat__list li a span {
	float: right;
	display: block;
}
.widget__post ul li {
	margin-bottom: 20px;
	overflow: hidden;
}
.widget__post ul li:last-child {
	margin-bottom: 0;
}
.widget__post-thumb {
	float: left;
	display: block;
	margin-right: 20px;
}
.widget__post-content {
	overflow: hidden;
	display: block;
}
.widget__post-content h6 {
	font-size: 16px;
	margin-bottom: 1px;
	padding-right: 15px;
	
}
.widget__post-content h6:hover a {
	color:#ff7350;
}
.widget__post-content span {
	font-size: 14px;
}
.widget__post-content span i {
	margin-right: 8px;
}
.widget__tag ul li {
	display: inline-block;
	margin: 5px 3px;
}
.widget__tag ul li:hover a {
	background:#ff7350;
	border-color:#ff7350;
	color: #ffffff;
}
.widget__tag ul li a {
	display: block;
	border: 1px solid #d8d8d8;
	font-size: 14px;
	color: #8f8bb8;
	padding: 9px 20px;
}
.widget.widget__banner {
	border: none;
	padding: 0;
	position: relative;
}
.widget__banner-thumb img {
	width: 100%;
}
.widget__banner-overly {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
	text-align: center;
}
.widget__banner-overly > span {
	font-size: 14px;
	
	text-transform: uppercase;
	color: #ffffff;
	letter-spacing: 2px;
	display: block;
	margin-bottom: 160px;
}
.widget__banner-overly h3 {
	color: #ffffff;
	font-size: 40px;
	margin-bottom: 129px;
	
}
.widget__banner-overly h3 span {
	display: block;
	font-family: 'Roboto', sans-serif;
	font-size: 30px;
	font-weight: 300;
}
.widget__banner-overly .btn {
	border-radius: unset;
	background: #fff;
	border: 2px solid #fff;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 17px 35px;
	color:#ff7350;
}
.widget__banner-overly .btn:hover {
	background: transparent;
	color: #fff;
}
.meta__info ul {
  	margin-bottom: 7px;
}
.meta__info ul li {
    display: inline-block;
    font-size: 14px;
	margin-right: 30px;
}
.meta__info ul li a {
	color: #777777;
	text-transform: capitalize;
}
.meta__info ul li a i {
	margin-right: 5px;
}
.meta__info ul li a:hover {
	color: #ff7350;
}
.meta__info ul li i {
	margin-right: 5px;
}
.details__content h2 {
	font-size: 32px;
	line-height: 1.3;
	margin-bottom: 20px;
	padding-right: 0;
}
.details__content p {
  	margin-bottom: 18px;
}
blockquote,.pages-content blockquote,.comment-list blockquote {
background:#141b22;
border-radius: 10px;
padding: 50px 45px 57px !important;
position: relative;
z-index: 5;
text-align: center;
margin-top: 30px;
margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    font-size: 18px;
}
.comment-list blockquote a:hover{
    color: #fff;
}
.news-text blockquote h3{
    color: #fff;
}
.news-text blockquote i {
    font-size: 190px;
    color: #e4e9ff;
    z-index: -1;
    position: absolute;
    bottom: 15px;
    right: 30px;
}
blockquote p{
    color: #fff;
}
.news-text blockquote footer {
    color: #FF8000 !important;
    font-weight: 600;
    margin-bottom: 15px;
}
.news-text figure img {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 15px;
}
.news-text ul li {
    line-height: 30px;
	list-style:none ;
}
.news-text ul li i,.quote-post ul li i {
    color: #F15B26;
    padding-right: 5px;
}
.news-text blockquote p,.pages-content blockquote p,.comment-list blockquote p{
	color: #fff;
    line-height: 28px;
}

.news-text blockquote footer,.pages-content blockquote footer {
	font-size: 14px;
	margin-top: 15px;
	color: #fff;
	font-weight: 600;
}
.wp-block-media-text .wp-block-media-text__content {
    width: 100%;
}
.wp-block-pullquote cite {
   color: #bacdff!important;
}
cite {
	margin-top: 15px;
	color:#ff7350;
	font-weight: 600;
}
.post-categories li {
    display: none !important;
}
.post-categories li:first-child {
    display: block !important;
}

.details__content-img {
  	margin: 45px 0;
}
.details__content-img img {
	width: 100%;
    height: auto;
}
.details__content figure {
	margin-top: 45px;
	margin-bottom: 60px;
}
.details__content figure img {
    float: left;
    width: 255px;
	margin-right: 30px;
}
.tags.pb-50 {
    padding-bottom: 20px;
}
.post__tag h5 {
	font-size: 20px;
margin-bottom: 20px;
font-weight: 600;
padding-top: 30px;
border-top: 2px solid #f5f5f5;
    margin-top: 25px;
}
.post__tag ul li {
	display: inline-block;
	margin-right: 10px;
}
.post__tag ul li a {
	font-size: 12px;
	text-transform: uppercase;
	border: 2px solid #e5e5e5;
	padding: 7px 10px;
	
	display: inline-block;
	border-radius: 3px;
	color: #9d9d9d;
	margin-bottom: 10px;
}
.post__tag ul li a:hover {
	border-color:#ff7350;
	background:#ff7350;
	color: #ffffff;
}
.post__share h5 {
	font-size: 18px;
	margin-bottom: 30px;
	
}
.post__share ul li {
	margin-left: 20px;
	display: inline-block;
}
.post__share ul li a {
    font-size: 18px;
    display: inline-block;
	color: #b5becc;
}
.post__share ul li a:hover {
	color:#ff7350;
}
.posts_navigation {
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
}
.posts_navigation .prev-link span {
    font-size: 12px;
    text-transform: uppercase;
	
	display: block;
	letter-spacing: 2px;
	margin-bottom: 15px;
}
.posts_navigation .prev-link h4 {
	font-size: 20px;
	margin-bottom: 0;
	
	text-transform: capitalize;
	height: 30px;
overflow: hidden;
}
.navigation.posts-navigation {
    display: none;
}
.avatar {
    border-radius: 50% !important;
}
.wpding .footer-widget .widget_recent_comments ul li,.wpding .footer-widget .widget_rss ul li,.wpding .footer-widget .widget_meta ul li {
    padding-bottom: 0px;
    border-bottom: 1px solid #e4e4e4;
    padding-top: 0px;
    width: 100%;
    text-align: right;
    color: #62605c;
    min-height: 42px;
    line-height: 28px;
}
.wpding .footer-widget .widget_pages li .children li {
    min-height: 42px;
}

.comment-list .pingback, .comment-list .trackback {
    border-top: 1px solid #d1d1d1;
        border-top-color: rgb(209, 209, 209);
    border-top-color: rgb(209, 209, 209);
    padding: 1.75em 0;
    margin-bottom: 0;
}
.avatar_post img {
    border-radius: 50%;
    box-shadow: 1px 5px 6px #8a8a8a33;
}
.posts_navigation .prev-link h4:hover a {
	color:#ff7350;
}
.posts_navigation .next-link span {
    font-size: 12px;
	margin-bottom: 15px;
	
	display: block;
    text-transform: uppercase;
	letter-spacing: 2px;
}
.posts_navigation .next-link h4 {
    font-size: 20px;
	margin-bottom: 0;
	
	height: 30px;
overflow: hidden;
}
.posts_navigation .next-link h4:hover a {
	color:#ff7350;
}
.related__post .post-title {
  	margin-bottom: 35px;
}
.related__post .post-title h4 {
	font-size: 26px;
	margin-bottom: 0;
	
}
.related-post-wrap .post-thumb img {
  	width: 100%;
}
.related-post-wrap .rp__content {
	padding: 30px;
	border: 2px solid #f4f3fb;
	border-top: none;
}
.related-post-wrap .rp__content h3 {
	font-size: 24px;
	margin-bottom: 20px;
	
	line-height: 1.4;
}
.related-post-wrap .rp__content h3:hover a {
	color:#ff7350;
}
.related-post-wrap .rp__content p {
    margin-bottom: 0;
	font-size: 14px;
}
.avatar__wrap {
	background: #f9f9f9;
	padding: 50px 80px;
	float:left;
	width:100%;
}
.avatar__wrap .avatar-img {
    margin-top: -115px;
	margin-bottom: 35px;
}
.avatar__wrap-content p {
    font-size: 14px;
	margin-bottom: 0;
}
.avatar__info h5 {
	font-size: 26px;
	margin-bottom: 10px;
	
}
.avatar__info-social {
  	margin-bottom: 20px;
}
.avatar__info-social a {
    font-size: 12px;
    color: #ff7350;
    display: inline-block;
	margin: 0 5px;
}
.avatar__info-social a:hover {
	color:#ff7350;
}
code {
    font-size: 15px;
    display: inline-block;
           font-family: 'Jost', sans-serif;
    margin-top: 10px;
}
figcaption{
    font-weight: 600;
    color: #202020;

}
.comment-form-comment label{
	display:none;

}
.form-submit {
    margin-bottom: 0;
}
.comment__wrap {
  	border-bottom: 1px solid #eaeaea;
}
.comment__wrap-title {
	margin-bottom: 35px;
}
.comment__wrap-title h5 {
	font-size: 26px;
	margin-bottom: 0;
	
}
.single__comment .comments-avatar {
	float: left;
	width: 100px;
	margin-right: 30px;
}
.single__comment.children {
 	margin-left: 130px;
}
.single__comment.children .avatar-name h6 i {
    font-size: 12px;
    color: #cacfef;
	margin-left: 20px;
}
.comment-text {
  	overflow: hidden;
}
.comment-text .avatar-name {
	overflow: hidden;
}
.comment-text .avatar-name h6 {
	font-size: 16px;
	margin-bottom: 7px;
	
}
.comment-text .avatar-name h6 i
{
	display:none;
}
.comment-author-admin .avatar-name h6 i
{
	font-size: 12px;
	display:inline-block;
color: #cacfef;
margin-left: 20px;
}

.comment-text .avatar-name span {
	font-size: 12px;
	
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #ff7350;
}
.comment-text .comment-reply {
    display: inline-block;
    border: 2px solid #f5f5f5;
    padding: 8px 18px;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    width: 100px;
}
.comment-text .comment-reply a{
	    color: #777777;
    width: 100%;
    position: absolute;
    left: 0;
    display: inline-block;
    top: -2px;
    padding: 10px 0 10px 41px;
}
.comment-text .comment-reply:hover a{
	color: #fff;
}
.comment-list {
    padding: 0;
	border-bottom: 1px solid #eaeaea;
	margin-top: 45px;
	margin-bottom: 45px;
	padding-bottom: 45px;
}
.comments-title {
    font-size: 22px;
    margin-bottom: 0;
    
}
.comment-reply-title {
    font-size: 20px;
	margin-bottom:20px;
}
.comment-author-admin{
    margin-left: 130px;
}
.comment-text .comment-reply:hover {
	border-color:#ff7350;
	background:#ff7350;
	color: #ffffff;
}
.comment-text .comment-reply i {
	margin-right: 5px;
}
.comment-text p {
    font-size: 16px;
	margin-bottom: 0;
}
.screen-reader-text {
    display: none;
}
.comment-form {
	padding: 50px;
background: #f5f5f5;
border-radius: 5px;
}
.comment-form .comment-field {
	position: relative;
}
.comment-form .comment-field.text-area i {
	top: 25px;
	transform: unset;
}
.comment-form i {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	color:#ff7350;
	font-size: 14px;
}
.comment-form textarea {
	height: 150px;
	width: 100%;
	padding: 20px;
	padding-right: 50px;
	background: #ffffff;
	border: none;
}
.comment-form textarea::placeholder {
	font-size: 14px;
	color: #a7a7c1;
}
.comment-form textarea:focus {
	outline: 1px solid#ff7350;
}
.comment-form input {
	width: 100%;
	padding: 20px;
	padding-right: 50px;
	background: #ffffff;
	border: none;
}
.comment-form input::placeholder {
	font-size: 14px;
	color: #b0b0b0;
}
.comment-form input:focus {
	outline: 1px solid#ff7350;
}
.comment-form .submit {
	background:#ff7350;
	border: none;
	font-size: 14px;
	text-transform: uppercase;
	color: #fff;
	border-radius: 0px;
	padding: 12px 45px;
	margin-top:20px;
	border-radius: 5px;
	width: auto;
}
.comment-form .submit::before{content: none;}
.comment-form .submit:hover {
	color: #fff;
	background:#ff7350;
}
 .page .comments-area ol .comment-respond{
    margin-bottom: 30px;
        margin-top: -26px;
}
  .comments-area ol >  .comment-respond {
    margin-bottom: 30px;
        margin-top: -30px;
}
  .comments-area ol > li > ol .comment-respond {
  margin-left: -32px;
       margin-top: -35px;
}
  .comments-area ol > li > ol > li > ol >  .comment-respond {
    margin-left: -42px;
        margin-top:-30px;
}
  .comments-area ol > li > ol > li > ol > li > ol > .comment-respond {
    margin-left: -62px;
      margin-top: -35px;
}
.comment-reply-title small{
    margin-left: 15px;
}
.blist li {
    list-style: none !important;
}
.wp-block-gallery.columns-3.is-cropped {
    margin-top: 30px;
}

.single-post .footer-widget{
  
}
figure.aligncenter img {
    margin-bottom: 15px;
}
.wp-image-907 {
    width: 100% !important;
    height: auto;
    margin-bottom: 15px;
}
figure img {
    display: inline-block;
    margin-bottom: 15px;
}



.tag-markup-2 ol li:last-child, .tag-markup-2 ul li:last-child {
    margin-bottom: 20px;
}
.bsingle__content table th,.pages-content table th,.comment-text table th{
	color: #141b22;
}
.bsingle__content table a, .pages-content table a,.comment-text table a{
    color: #76BC02;
    outline: medium none;
}


.footer-widget select {
    width: 100%;
    padding: 3px 0;
    border: 2px solid #e4e4e4;
}



.footer-widget .tag-cloud-link{
    color: #020202;
}
.footer-widget .textwidget,.footer-widget .recentcomments a,.footer-widget a,.footer-widget .widget_categories a{
    color: #99A3AC;
}
.footer-widget .recentcomments a:hover,.footer-widget a:hover,.footer-widget .widget_categories a:hover{

}
.footer-widget .tag-cloud-link:hover{
    color: #fff;
}
.single-post .bsingle__content .admin{
    display: none;
}
.single-post .blog-deatails-box.single p {
    margin-bottom: 25px;
}
.single-post .blog-deatails-box.single {
    padding: 40px;
   border-style: solid;
border-width: 2px;
border-color: rgb(243, 243, 243);
    float: left;
    width: 100%;
}
.single-post .blog-deatails-box.single p.form-submit {
    margin-bottom: 0;
}
.blog-deatails-box.single .bsingle__content {
    padding: 0;
    border: none;
}
.single-post .bsingle__post-thumb img{
margin-bottom: 50px;
}

blockquote h3{
    color: #fff;
}
blockquote footer {
    color: #ff7350;
    margin-bottom: 30px;
}
.blist li i  {
    color: #F15B26;
    padding-right: 5px;
}
.blist li {
    line-height: 30px;
    list-style: none;
}
.comment-form-cookies-consent input {
    width: auto;
    margin-top: 7px;
    margin-right: 10px;
}
.comment-text ul, .comment-text ol {
    margin-bottom: 15px;
    padding-left: 20px;
}
/* 16. pagination */
.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: block;
	padding-left: 0;
	list-style: none;
	border-radius: 0;
}
.pagination .page-item {
	display: inline-block;
	margin: 0 5px;
}
.pagination .page-item a.page-link {
	border-radius: 50%;
	padding: 0;
	height: 70px;
	width: 70px;
	line-height: 70px;
	background: #f8f8f8;
	color: #9fa7c5;
	font-size: 14px;
	border: none;
	font-weight: 500;
}
.pagination .page-item:hover a.page-link {
	box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
	background: #ff4a57;
	color: #fff;
}
.pagination .page-item.active a.page-link {
	box-shadow: 0px 16px 32px 0px rgba(255, 74, 87, 0.2);
	background: #ff4a57;
	color: #fff;
}
.pagination-wrap .pagination {
	display: block;
	border-radius: unset;
}
.pagination-wrap .pagination li {
	display: inline-block;
	margin-right: 6px;
	margin-left: 0;
	margin-bottom: 15px;
}
.pagination-wrap .pagination li.active a {
	background:#252525;
	color: #ffffff;
	box-shadow: 0px 8px 16px 0px rgba(26, 35, 126, 0.32);
}
.pagination-wrap .pagination li a {
	border: none;
	height: 50px;
	width: 50px;
	display: block;
	line-height: 50px;
	background: #ff7350;
	border-radius: 50%;
	color: #fff;
	font-size: 14px;
	text-align: center;
}
.pagination-wrap .pagination li a:hover {
	color: #ffffff;
	background: #ff7350;
}
/* 17. f-cta */
.f-cta-area {
	background: #fff;
	margin-left: 260px;
	margin-right: 260px;
	position: relative;
	top: -70px;
	margin-bottom: 30px;
	box-shadow: 0 -3px 65px 0 rgba(0,0,0,.09);
}
.f-cta-icon i {
	display: inline-block;
	height: 80px;
	width: 80px;
	text-align: center;
	line-height: 78px;
	background: #ff7350;
	border-radius: 50%;
	color: #fff;
	font-size: 28px;
}

.contact-info .single-cta {
   float: left;
}
.contact-info p{
}
.contact-info h5{
	color:#141b22;
}
.single-cta {
    border-bottom: 1px solid #ccc;
    float: left;
width: 100%;
}
div.single-cta:last-child {
    border: 0;
}
.single-cta h5 {
	font-size: 20px;
	margin-bottom:15px !important;
}
.single-cta p{margin-bottom: 0;}
.single-cta p a{color: #777;}
.s-cta-btn .btn{margin-top: 10px;}



.widget.widget_media_image {
    margin: 25px 0 0;
}

.award-box .date{
    font-weight: 600;
    font-size: 24px;
    color: #141b22;
           font-family: 'Jost', sans-serif;
}
.aw-line{
position: relative;
}
.aw-line::before {
content: "";
position: absolute;
height: 1px;
left: 9%;
border-bottom: 1px dashed;
top: 32%;
width: 81%;
margin: auto;
}

/* --- blog new css ----*/

.bsingle__content .meta-info ul {
padding-bottom: 0;
border-bottom: none;
display: inline-block;
width: 100%;
padding-left: 0;
margin-bottom: 20px;
}
.blog-deatails-box02 .meta-info ul {
    margin-top: 0;
    padding-left: 0;
    padding-top: 0px;
}
.single-post .blog-deatails-box.single p {
    margin-bottom: 25px;
}
.sticky .bsingle__content .meta-info ul {
padding-top: 0;
border-top: none;
display: inline-block;
width: 100%;
padding-left: 0;
}
.single-post .blog-deatails-box.single p.form-submit {
    margin-bottom: 0;
}
.blog-deatails-box.single .bsingle__content {
    padding: 0;
    border: none;
}

.sidebar-widget .widget li {
    padding-bottom: 5px;
    border-bottom: none;
    padding-top: 5px;
    float: left;
    width: 100%;
    min-height: 42px;
}
.sidebar-widget .widget a {
    width: 100%;
    text-align: left;
    line-height: 28px;
    margin-bottom: -26px;
    position: relative;
    z-index: 2;
}
.wp-block-cover-text {
    color: #fff !important;
    padding: 30px;
}
.inner-linke-page a,.post-page-numbers {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	background: #ff7350;
	border-radius: 50%;
	color: #fff;
	 font-weight: 400;
	font-size: 14px;
	text-align: center;
}
.inner-linke-page a:hover,.post-page-numbers:hover {
	color: #ffffff;
	background: #010f2e;
}
.inner-linke-page > span,.post-page-numbers.current {
	border: none;
	height: 32px;
	width: 32px;
	display: inline-block;
	line-height: 32px;
	font-weight: 400;
	border-radius: 50%;
	font-size: 14px;
	text-align: center;
    background: #010f2e;
    color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32);
}
.wp-block-media-text__media img {
    width: 100%;
    height: auto;
}
.wp-block-button__link {
    border: none;
    font-weight: 600;
    padding: .76rem 1rem;
    outline: none;
    outline: none;
    display: inline-block;
    background: #141b22;
    color: #fff !important;
    width: auto;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.tag-markup-2 ol li:last-child, .tag-markup-2 ul li:last-child {
    margin-bottom: 20px;
}
.bsingle__content table th,.pages-content table th,.comment-text table th{
	color: #141b22;
}
.bsingle__content table a, .pages-content table a,.comment-text table a{
    color: #ff7350;
    outline: medium none;
}

.sidebar-widget .widget_nav_menu .sub-menu {
    display: block;
    position: relative;
    margin-top: 5px !important;
    border: none;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: medium;
    border-top: 1px solid #ddd;
}
.with-avatar .avatar {
    border-radius: 50% !important;
    display: none !important;
}
.ab-submenu .ab-item img {
    display: none !important;
}
.avatar {
    border-radius: 50% !important;
}
.wpding .footer-widget .widget_recent_comments ul li,.wpding .footer-widget .widget_rss ul li,.wpding .footer-widget .widget_meta ul li {
    padding-bottom: 0px;
    border-bottom: 1px solid #e4e4e4;
    padding-top: 0px;
    width: 100%;
    text-align: right;
    color: #62605c;
    min-height: 42px;
    line-height: 28px;
}


.comment-list .pingback, .comment-list .trackback {
    border-top: 1px solid #d1d1d1;
        border-top-color: rgb(209, 209, 209);
    border-top-color: rgb(209, 209, 209);
    padding: 1.75em 0;
    margin-bottom: 0;
}
.wpding .footer-widget .widget ul li,.wpding .footer-widget .widget ul li a,.wpding .footer-widget p{
    color: #62605c;
}
.wpding .footer-widget .widget ul li:hover,.wpding .footer-widget .widget ul li a:hover{
    color: #ff7350;
}
.wpding .footer-widget .widget_recent_comments ul li, .wpding .footer-widget .widget_rss ul li, .wpding .footer-widget .widget_meta ul li {
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    padding-top: 0px;
    width: 100%;
    text-align: left;
    color: #62605c;
    margin-bottom: 10px;
}
.wpding .footer-widget .widgettitle {
    text-align: left;
    color: #0d0d0d;
    width: 100%;
    display: inline-block;
}
.sidebar-widget .widget .children, .footer-bg .widget .children {
    padding-left: 15px;
}
.sidebar-widget .widget_nav_menu .sub-menu {
    display: block;
    position: relative;
    margin-top: 5px !important;
    border: none;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: medium;
    border-top: 1px solid #ddd;
}
.footer-top.wpding .menu .sub-menu {
    display: block;
    position: relative;
    margin-top: 0;
    border: none;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: medium;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: medium;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: medium;
    border-top: 1px solid #ddd;
    box-shadow: none;
    padding-left: 10px;
    float: left;
    line-height: 28px;
    min-width: 100%;
}
.footer-top.wpding .menu .sub-menu li {
    float: none;
    line-height: 40px;
}
.footer-widget .menu-item-has-children {
    border-bottom: 1px solid #ddd;
    line-height: 40px;
}
.footer-bg .wpding .widget {
    margin-bottom: 30px;
}


.blog-deatails-box.single .single {
    display: none;
}
.footer-widget .tag-cloud-link ,.footer-widget p{
     color: #b2c0d8;
}
.wpding .footer-widget .tag-cloud-link {
    color: #62605c;
}
.calendar_wrap {
    color: #777;
}
.logo {
  width: 242px;
}
.logo a {
    color: #fff;
}
blockquote a ,blockquote cite{
    color: #bacdff;
}

.booking-area .contact-form {
  background: #fff;
  padding: 50px 50px 30px 50px;
   border-radius: 4px;
  background-image: -moz-linear-gradient( 0deg, rgb(9,152,230) 0%, rgb(54,96,217) 100%);
  background-image: -webkit-linear-gradient( 0deg, rgb(9,152,230) 0%, rgb(54,96,217) 100%);
  background-image: -ms-linear-gradient( 0deg, rgb(9,152,230) 0%, rgb(54,96,217) 100%);
  box-shadow: 0px 20px 60px 0px rgba(54, 96, 217, 0.3);
    position: relative;
    z-index: 1;
}
.booking-area ul {
  display: flex;
}
.booking-area ul li {
  margin-right: 18px;
}
.booking-area input {
 width: 322px;
  border: none;
  background: #fff;
  padding: 10px 20px;
  transition: .3s;
  border-radius: 4px;
}
.booking-area .custom-select {
display: inline-block;
width: 322px;
padding: .375rem 1.75rem .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
vertical-align: middle;
background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
border: none;
border-radius: 4px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
height: calc(3.0em + 0.55rem + 2px);
}
.booking-area .bk-btn {
border-radius: 4px;
background-color: rgb(14, 38, 79);
box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4);
padding: 17px 30px;
width: 176px;
float: left;
color: #fff;
position: relative;
border: none;
text-align: center;
text-transform: uppercase;
font-weight: 500;
}
.booking-area .contact-field i{
  position: absolute;
  right: 10px;
  top: 22px;
    background: #fff;
padding: 0 5px;
}
.booking-area h2{
    color: #fff; 
}
.booking-area h5{
    color: #fff; 
    text-transform: uppercase;
}
.booking-area p{
    color: #fff; 
}
.booking-content-box a{
    color: #fff; 
    border-bottom: 5px solid #fff;
    margin-top: 15px;
    display: inline-block;
    padding-bottom: 5px; 
}

.booking-contact-box {
    background: #fff;
    padding: 60px;
}
.booking-contact-box .nav.nav-tabs li {
    width: 50%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.booking-contact-box .nav.nav-tabs li a {
    color: #777777;
    font-size: 20px;
    border: none;
    text-align: center;
    font-weight: 600;
    padding: 14px;
    display: inline-block;
    width: 100%;
}

.booking-contact-box .nav.nav-tabs li.active a,.booking-contact-box .nav.nav-tabs li a.active {
    border: none;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: medium;
    border-bottom: 2px solid #ff7350;
    color: #000;
}


.wpding .footer-widget{

}

.wpding .footer-widget  .widget_categories ul li a,.wpding .footer-widget  .widget_archive ul li a  {
    width: 100%;
    text-align: left;
    line-height: 28px;
    margin-bottom: -26px;
    position: relative;
    z-index: 2;
	float: left;
	color: #62605c;
	
}
.wpding .footer-widget li:hover{
	color: #faa292;
}
.wpding .footer-widget ul li:last-child,.wpding .footer-widget .children li:last-child{
		border-bottom:none;
		}
.wpding .footer-widget .widget_categories .children li:last-child,.wpding .footer-widget .widget_archive .children li:last-child{
			padding-bottom: 0;
margin-bottom: -8px;
		}
.wpding .footer-widget .widget_categories ul li:last-child,.wpding .footer-widget .widget_archive ul li:last-child{
			margin-bottom:30px;
		}

.wpding .footer-widget .widget_pages ul li
{
	padding-bottom: 0px;
border-bottom: 1px solid #ffffff4d;
padding-top: 0px;
width: 100%;
text-align:right;
color: #62605c;
min-height: 42px;

	}
.wpding .footer-widget .widget_pages li .children {
  border-top: 1px solid #ffffff4d;
margin-top: 0;
padding-bottom: 0px;
display: inline-block;
width: 100%;
}
.wpding .footer-widget .widget_pages ul li a   {
width: 100%;
text-align: left;
margin-bottom: 0;
position: relative;
z-index: 2;
display: inline-block;
color: #62605c;
line-height: 40px;
min-height: 40px;
}
.wpding .footer-widget .tag-cloud-link {
    color: #fff;
    border: 2px solid #ffffff4d;
}
.wpding .footer-widget .widget ul li,.wpding .footer-widget .widget ul li a,.wpding .footer-widget p{
    color: #fff;
}
.wpding .footer-widget .widget ul li:hover,.wpding .footer-widget .widget ul li a:hover{
    color:#bacdff;
}
.wpding .footer-widget .widgettitle {
    text-align: left;
    color: #fff;
    width: 100%;
    display: inline-block;
}
.wpding .footer-widget .widget_recent_comments ul li,.wpding .footer-widget .widget_rss ul li,.wpding .footer-widget .widget_meta ul li {
    padding-bottom: 0px;
    border-bottom: 1px solid #ffffff4d;
    padding-top: 0px;
    width: 100%;
    text-align: right;
    color: #fff;
    min-height: 42px;
    line-height: 28px;
}


.comment-list .pingback, .comment-list .trackback {
    border-top: 1px solid #d1d1d1;
        border-top-color: rgb(209, 209, 209);
    border-top-color: rgb(209, 209, 209);
    padding: 1.75em 0;
    margin-bottom: 0;
}

.wpding .footer-widget .widget_recent_comments ul li, .wpding .footer-widget .widget_rss ul li, .wpding .footer-widget .widget_meta ul li {
    padding-bottom: 10px;
    border-bottom: 1px solid #ffffff4d;
    padding-top: 0px;
    width: 100%;
    text-align: left;
    color: #fff;
    margin-bottom: 10px;
}

.footer-bg .wpding .footer-widget .widget_archive:nth-child(1) {
    margin-top: 100px;
}
.footer-bg .wpding .widget_nav_menu {
    margin-bottom: 100px !important;
}
.footer-top.wpding .menu .sub-menu {
    display: block;
    position: relative;
    margin-top: 0;
    border: none;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: medium;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: medium;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: medium;
    border-top: 1px solid #ffffff4d;
    box-shadow: none;
    padding-left: 10px;
    float: left;
    line-height: 28px;
    min-width: 100%;
    background: none;
}
.footer-top.wpding .menu .sub-menu li {
float: none;
line-height: 40px;
border-bottom: 1px solid #ffffff4d;
display: inherit;
}
.footer-top.wpding .menu .sub-menu li:last-child {
    border: none;
}
.footer-widget .menu-item-has-children {
   border-bottom: 1px solid #ffffff4d;
line-height: 40px;
display: inline-block;
width: 100%;
}
.footer-bg .wpding .widget {
    margin-bottom: 30px;
}
.wpding .footer-widget .widget_categories ul li,.wpding .footer-widget .widget_archive ul li
{
	padding-bottom: 5px;
border-bottom: 1px solid #ffffff4d;
padding-top: 5px;
float: left;
width: 100%;
text-align:right;
color: #fff;
	}
.wpding .footer-widget .widget li .children {
    border-top: 1px solid #ffffff4d;
    margin-top: 10px;
    padding-bottom: 0px;
    display: inline-block;
    width: 100%;
}
.sidebar-widget .widget li:last-child, .sidebar-widget .widget .children li:last-child, .sidebar-widget .widget .children .children li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: -8px;
}
.sidebar-widget .widget_nav_menu .sub-menu li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.footer-top.wpding {
    background: #010c26;
}